export default {
  state: {
    latitude: 0,
    longitude: 0,
  },
  mutations: {
    CLEAR_GEOLOCATION: state => {
      state.latitude = 0;
      state.longitude = 0;
    },
    SET_GEOLOCATION: (state, { latitude, longitude }) => {
      state.latitude = latitude;
      state.longitude = longitude;
    },
  },
  actions: {
    clearGeolocation({ commit }) {
      commit('CLEAR_GEOLOCATION');
    },
    setGeolocation({ commit }, payload) {
      commit('SET_GEOLOCATION', payload);
    },
    getGeoAction() {
      return new Promise((resolve, reject) => {
        let isResolvedOrRejected = false;
    
        const timeoutId = setTimeout(() => {
          if (!isResolvedOrRejected) {
            isResolvedOrRejected = true;
            reject({ status: 'timeout', message: 'Geolocation Timeout'});
          }
        }, 1000 * 5.5); // 5.5秒鐘讀取不到geo，會進入失敗
    
        navigator.geolocation.getCurrentPosition(
          (position) => {
            if (!isResolvedOrRejected) {
              isResolvedOrRejected = true;
              clearTimeout(timeoutId);
              resolve({
                lon: position.coords.longitude,
                lat: position.coords.latitude,
              });
            }
          },
          (error) => {
            if (!isResolvedOrRejected) {
              isResolvedOrRejected = true;
              clearTimeout(timeoutId);
              reject({ status: 'error', message: error.message });
            }
          },
          {
            timeout: 1000 * 5, // 5秒鐘讀取不到geo，會進入失敗cb
            maximumAge: 1000 * 60 * 5 // 5分鐘內重複存取，會採用暫存資料
          }
        );
      });
    }
  },
};
