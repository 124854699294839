import { cloneDeep } from 'lodash-es';

import {
  calculateItemPrice,
  isEveryRequiredOptionSelected,
  getItemSelectedAttributeIds,
} from "@/utils/menu/menuItems";

// 子類別區塊，共拆成五種 UI 類型
// type: 'single' => 子類別可選擇1個
// type: 'multiple' => 子類別可選擇2個以上（－N＋），且所有項目都沒有可選份量/附加選項(可重複)
// type: 'singleChoice' => 同 multiple，但同品項只能選一個(不可重複)
// type: 'multipleWithAttributes' => 子類別可選擇2個以上，任一項目有需要選擇的份量/附加選項(可重複)
// type: 'multipleWithAttributesNoRepeat' => 子類別可選擇2個以上，任一項目有需要選擇的份量/附加選項(不可重複)

export const getSetDetailType = (detail) => {
  let isRepeat = true;
  if (detail.is_repeat !== undefined) {
    isRepeat = detail.is_repeat;
  }
  const { items, limit_qty } = detail;
  if (limit_qty === 1) {
    return 'single';
  }
  const detailItemsContainOptions = items.some(i => {
    const hasMultipleBasePrice = i.base_price.length > 1;
    const hasBasePrice = i.base_price.length >= 1;

    const hasOptions = i.option.length >= 1 && i.option.some(o => {
      return o.attribute.length > 0;
    });
    return hasMultipleBasePrice || (hasBasePrice && hasOptions);
  });

  if (detailItemsContainOptions) {
    if (isRepeat) return 'multipleWithAttributes';
    return 'multipleWithAttributesNoRepeat';
  }
  if (isRepeat) return 'multiple';
  return 'singleChoice';
}

// 判斷套餐是否必選項目都已選擇
export const isEverySetRequiredOptionSelected = (set) => {
  // 套餐本身附加選項是否必選都選取
  const isOptionPass = set.options && set.options.every(o => {
    if (!o.required) return true;
    if (o.is_multiple) {
      return o.selectedAttributeIds.length > 0;
    } else {
      return Boolean(o.selectedAttributeId);
    }
  });
  // 套餐子類別內每個餐點的附加選項是否必選都選取，總共分為四種判斷：
  // type: single => 判斷所有 item 是否必選選項都已完成 （用 detail.items）
  // type: multiple || singleChoice => 判斷 detail 總共選取品項是否達到 limit_qty
  // type: multipleWithAttributes => 判斷所有 item 是否必選選項都已完成 （用 detail.selectedItems）
  // type: multipleWithAttributesNoRepeat => 判斷所有 item 是否必選選項都已完成 （用 detail.items）
  const isDetailPass = set.detail.every(detail => {
    // type: multiple || singleChoice => 判斷 detail 總共選取品項是否達到 limit_qty
    if (detail.type === "multiple" || detail.type === "singleChoice") {
      const itemsCount = detail.items.reduce((accumulator, current) => {
        accumulator += current.qty;
        return accumulator;
      }, 0);

      if (detail.is_required) return itemsCount === detail.limit_qty;
      if (itemsCount > 0) return itemsCount <= detail.limit_qty;
      return true;
    }
    // type: multipleWithAttributes => 判斷所有 item 是否必選選項都已完成 （用 detail.selectedItems）
    if (detail.type === "multipleWithAttributes") {
      let reachCount = 0;
      detail.selectedItems.filter(i => i.id).forEach(i => {
        if (isEveryRequiredOptionSelected(i)) reachCount += 1
      });
      if (detail.is_required) return Boolean(reachCount >= detail.limit_qty);
      return Boolean(reachCount <= detail.limit_qty);
    }
    // type: multipleWithAttributesNoRepeat => 判斷所有 item 是否必選選項都已完成 （用 detail.items）
    if (detail.type === "multipleWithAttributesNoRepeat") {
      let reachCount = 0;
      detail.items.forEach(i => {
        if (detail.selectedItemIds.includes(i.id)) {
          if (isEveryRequiredOptionSelected(i)) reachCount += 1
        }
      });
      if (detail.is_required) return Boolean(reachCount >= detail.limit_qty);
      return Boolean(reachCount <= detail.limit_qty);
    }
    // type: single => 判斷 detail 所有 item 是否必選選項都已完成 （用 detail.items）
    if (detail.type === "single") {
      let reachCount = 0
      detail.items.forEach(i => {
        if (i.id === detail.selectedItemId && isEveryRequiredOptionSelected(i)) {
          reachCount += 1
        }
      });

      if (detail.is_required) return Boolean(reachCount >= detail.limit_qty);

      const hasSelected = detail.selectedItemId > 0;
      if (hasSelected) return Boolean(reachCount >= detail.limit_qty);
      return true;
    }
    return false;
  });
  // 這邊還要多判斷子類別是否有選取，因為資料上可能會有子類別裡面選項全部都已預設選好，但使用者 UI 上還沒做選擇
  const isDetailSelected = set.detail.every(detail => {
    if (detail.type === "single") {
      if (detail.is_required) return detail.selectedItemId;
      return true;
    }
    if (detail.type === "multiple" || detail.type === "singleChoice") {
      const itemsCount = detail.items.reduce((accumulator, current) => {
        accumulator += current.qty;
        return accumulator;
      }, 0);

      if (detail.is_required) return itemsCount === detail.limit_qty;
      if (itemsCount > 0) return itemsCount <= detail.limit_qty;
      return true;
    }
    if (detail.type === "multipleWithAttributes") {
      if (detail.is_required) return detail.selectedItems.filter(i => i.id).every(i => {
        return isEveryRequiredOptionSelected(i)
      });

      const hasSelected = detail.selectedItems.some(i => i.id);
      const selectedItems = detail.selectedItems.filter(i => i.id)

      if (hasSelected) return selectedItems.every(i => isEveryRequiredOptionSelected(i));
      return true;
    }
    if (detail.type === "multipleWithAttributesNoRepeat") {
      if (detail.is_required) return detail.selectedItemIds.length === detail.limit_qty;
      return true;
    }
    return false;
  })
  return isDetailPass && isDetailSelected && isOptionPass;
}

// 計算套餐預設價錢(由於子類別品項不會預選，所以基礎價格加上本身預選附加選項價格即可)
export const calculateSetDefaultPrice = (set) => {
  const baseprice = set.base_prices[0].price;
  // 附加選項
  const optionsPrice = set.options.reduce((accumulator, current) => {
    current.attributes.forEach(a => {
      if (a.selected) accumulator += a.price;
    });
    return accumulator;
  }, 0);

  return (baseprice + optionsPrice) * set.qty;
}

// 計算套餐價錢
export const calculateSetPrice = (set) => {
  const baseprice = set.base_prices[0].price;
  // 子類別 price
  const detailPrice = set.detail.reduce((accumulator, current) => {
    if (current.type === "single") {
      current.items.forEach(i => {
        if (i.id === current.selectedItemId) {
          const itemPrice = calculateItemPrice(i);
          accumulator += itemPrice;
        }
      });
    } else if (current.type === "multiple" || current.type === "singleChoice") {
      if (current.has_extra_price) {
        current.items.forEach(i => {
          const itemPrice = calculateItemPrice(i);
          accumulator += itemPrice;
        });
      }
    } else if (current.type === "multipleWithAttributes") {
      current.selectedItems.filter(i => i.id).forEach(i => {
        if (i.base_prices && i.options) {
          const itemPrice = calculateItemPrice(i);
          accumulator += itemPrice;
        }
      });
    } else if (current.type === "multipleWithAttributesNoRepeat") {
      current.items.forEach(item => {
        if (current.selectedItemIds.includes(item.id)) {
          const itemPrice = calculateItemPrice(item);
          accumulator += itemPrice;
        }
      });
    }
    return accumulator;
  }, 0);
  // 附加選項
  const optionsPrice = set.options.reduce((accumulator, current) => {
    // 多選
    if (current.is_multiple) {
      current.attributes.forEach(a => {
        if (current.selectedAttributeIds.includes(a.id)) {
          accumulator += a.price;
        }
      });
    } else {
      // 單選必填
      if (current.required) {
        current.attributes.forEach(a => {
          if (a.id === current.selectedAttributeId) {
            accumulator += a.price;
          }
        });
      } else {
        // 單選選填寫
        current.attributes.forEach(a => {
          if (current.selectedAttributeIds.includes(a.id)) {
            accumulator += a.price;
          }
        });
      }
    }
    return accumulator;
  }, 0);

  return (baseprice + detailPrice + optionsPrice) * set.qty;
}

// 必選子類別品項只有一個的，做預選
export const preSelectDetailItems = (detail) => {
  const cloneDetail = cloneDeep(detail);
  if (cloneDetail.items.length === 1 && cloneDetail.is_required) {
    const theOnlyItem = cloneDetail.items[0];
    if (cloneDetail.type === "multipleWithAttributes") {
      cloneDetail.selectedItems = cloneDetail.selectedItems.map(
        (item) => {
          return cloneDeep(theOnlyItem);
        }
      );
    } else if (cloneDetail.type === "single") {
      cloneDetail.selectedItemId = theOnlyItem.id;
    } else if (cloneDetail.type === "multipleWithAttributesNoRepeat") {
      cloneDetail.selectedItemIds.push(theOnlyItem.id);
    }
  }
  return cloneDetail;
}

export const handleSubmitDetailItem = ({
  detailInSet,
  selectedItem,
}) => {
  const cloneDetail = cloneDeep(detailInSet);
  if (cloneDetail.id === selectedItem.detailId) {
    if (selectedItem.index) {
      const targetIndex = selectedItem.index - 1;
      const targetItem = cloneDeep(selectedItem);
      cloneDetail.selectedItems = cloneDetail.selectedItems.map((item, index) => {
        if (index === targetIndex) return targetItem;
        return cloneDeep(item);
      })
    } else {
      cloneDetail.items = cloneDetail.items.map((i) => {
        if (i.id === selectedItem.id)
          return cloneDeep(selectedItem);
        return cloneDeep(i);
      });
    }
  }
  return cloneDetail;
}

// - N + UI 旁的字串
export const getMultipleTypePriceString = (item) => {
  if (
    item.base_prices &&
    item.base_prices[0] &&
    item.base_prices[0].price > 0
  ) {
    if (item.qty === 1) return ``;
    return `每項 $${item.base_prices[0].price}`;
  }
  return ``;
}

// - N + UI 旁的+錢字串
export const getMultipleTypeAdditionalPriceString = (item) => {
  if (!item.base_prices) return "";
  if (!item.base_prices[0]) return "";
  if (item.base_prices[0].price <= 0) return "";
  if (item.qty <= 0) return "";
  const p = item.base_prices[0].price;
  return `+ $${p * item.qty}`;
}

// 是否顯示編輯附加選項
export const isShowOptionButton = (item) => {
  const notOk = item.options.every((o) => o.attributes.length < 1);
  if (item.base_prices.length <= 1 && notOk) return false;
  return true;
}

// 處理 - N + UI item 點擊後會轉換 UI，qty +1，
export const isAbleToAdd = (detail) => {
  const maxQty = detail.limit_qty;
  const currentQty =
        detail.items &&
        detail.items.reduce((accumulator, current) => {
          return accumulator + current.qty;
        }, 0);

  const remain = maxQty - currentQty;

  return remain > 0;
}

// 處理 - N + UI item 點擊後是否超過 max qty
export const isOverMaxQty = (detail) => {
  const maxQty = detail.limit_qty;
  const currentQty =
        detail.items &&
        detail.items.reduce((accumulator, current) => {
          if (!current.qty) current.qty = 0;
          return accumulator + current.qty;
        }, 0);

  return maxQty - currentQty < 0;
}

// 計算 - N + 能選取最多的數字
export const calculateItemMaxQty = (detail, item) => {
  const maxQty = detail.limit_qty;
  const currentQty =
        detail.items &&
        detail.items.reduce((accumulator, current) => {
          return accumulator + current.qty;
        }, 0);

  const remain = maxQty - currentQty;

  if (remain <= 0) {
    return item.qty;
  }
  return remain + item.qty;
}

// 取得打 「新增套餐」 所需要的 detail 資料格式
export const getSetDetailApiFormat = setDetail => {
  if (setDetail.type === "multiple" || setDetail.type === "singleChoice") {
    const items = setDetail.items.filter(i => i.qty > 0).map(item => {
      return {
        id: item.id,
        qty: item.qty,
        base_price_id: item.selectedBasePriceId,
        attribute_ids: [],
      }
    });

    return {
      id: setDetail.id,
      items,
    }
  } else if (setDetail.type === "single") {
    const selectedItem = setDetail.items.find(i => i.id === setDetail.selectedItemId);

    if (!selectedItem) return;

    return {
      id: setDetail.id,
      items: [
        {
          id: selectedItem.id,
          qty: 1,
          base_price_id: selectedItem.selectedBasePriceId,
          attribute_ids: getItemSelectedAttributeIds(selectedItem),
        }
      ],
    }
  } else if (setDetail.type === "multipleWithAttributes") {
    const items = setDetail.selectedItems.filter(i => i.id).map(item => {
      return {
        id: item.id,
        qty: item.qty,
        base_price_id: item.selectedBasePriceId,
        attribute_ids: getItemSelectedAttributeIds(item),
      }
    });
    return {
      id: setDetail.id,
      name: setDetail.name,
      items,
    }
  } else if (setDetail.type === "multipleWithAttributesNoRepeat") {
    const items = setDetail.selectedItemIds.map(id => {
      const item = setDetail.items.find(i => i.id === id);
      return {
        id: item.id,
        qty: item.qty,
        base_price_id: item.selectedBasePriceId,
        attribute_ids: getItemSelectedAttributeIds(item),
      }
    });
    return {
      id: setDetail.id,
      name: setDetail.name,
      items,
    }
  }
}

// 取得打 「新增套餐」 API 所要用的 set 資料格式
export const getAddSetApiFormat = (set) => {
  const setApiFormat = {
    id: set.id,
    qty: set.qty,
    comment: set.comment,
    purchaser_name: set.owner,
    details: set.detail.map(getSetDetailApiFormat).filter(e => e),
    attribute_ids: getItemSelectedAttributeIds(set),
  };
  return setApiFormat;
}

// 取得打 「修改套餐」 API 所要用的 set 資料格式
export const getEditSetApiFormat = (set) => {
  console.log('[getEditSetApiFormat-input]: ', set);

  const setApiFormat = {
    id: set.id,
    qty: set.qty,
    comment: set.comment,
    purchaser_name: set.owner,
    attribute_ids: getItemSelectedAttributeIds(set),
    details: set.detail.map(d => {
      let theItems = [];
      if (d.type === "single") {
        theItems = d.items.filter(i => i.id === d.selectedItemId)
      } else if (d.type === "multiple" || d.type === "singleChoice") {
        theItems = d.items.filter(i => i.qty > 0)
      } else if (d.type === "multipleWithAttributes") {
        theItems = d.selectedItems.filter(i => i.id);
      } else if (d.type === "multipleWithAttributesNoRepeat") {
        theItems = d.items.filter(i => d.selectedItemIds.includes(i.id));
      }
      return {
        id: d.id,
        items: theItems.map(i => {
          return {
            id: i.id,
            qty: i.qty,
            comment: i.comment,
            purchaser_name: i.owner,
            base_price_id: i.selectedBasePriceId,
            attribute_ids: getItemSelectedAttributeIds(i),
          }
        })
      }
    }),
  };

  console.log('[getEditSetApiFormat-output]: ', setApiFormat);

  return setApiFormat;
}

// 取得必填未填寫 detailId
export const getUnselectedDetailIds = (set) => {
  const ids = [];
  set.detail.forEach(detail => {
    let itemIndex = [];
    let pass = false;
    // type: multiple || singleChoice => 判斷 detail 總共選取品項是否達到 limit_qty
    if (detail.type === "multiple" || detail.type === "singleChoice") {
      const itemsCount = detail.items.reduce((accumulator, current) => {
        accumulator += current.qty;
        return accumulator;
      }, 0);

      if (detail.is_required) {
        pass = itemsCount === detail.limit_qty;
      } else if (itemsCount > 0) {
        pass = itemsCount <= detail.limit_qty;
      } else {
        pass = true;
      }
    }
    // type: multipleWithAttributes => 判斷所有 item 是否必選選項都已完成 （用 detail.selectedItems）
    if (detail.type === "multipleWithAttributes") {
      let reachCount = 0;
      detail.selectedItems.filter(i => i.id).forEach((i, index) => {
        if (isEveryRequiredOptionSelected(i)) {
          reachCount += 1
        } else {
          // html element 上掛載 id 是 index + 1
          itemIndex.push(index + 1);
        }
      });
      if (detail.is_required) {
        pass = Boolean(reachCount >= detail.limit_qty);
        if (!pass) {
          const nullItemIndex = [];
          detail.selectedItems.forEach((item, index) => {
            if (!item.id) nullItemIndex.push(index + 1);
          });
          itemIndex = [...itemIndex, ...nullItemIndex];
        }
      } else {
        pass = Boolean(reachCount <= detail.limit_qty);
      }
    }
    // type: multipleWithAttributesNoRepeat => 判斷所有 item 是否必選選項都已完成 （用 detail.items）
    if (detail.type === "multipleWithAttributesNoRepeat") {
      let reachCount = 0;
      detail.items.forEach(i => {
        if (detail.selectedItemIds.includes(i.id)) {
          if (isEveryRequiredOptionSelected(i)) reachCount += 1
        }
      });
      if (detail.is_required) {
        pass = Boolean(reachCount >= detail.limit_qty);
      } else {
        pass = Boolean(reachCount <= detail.limit_qty);
      }
    }
    // type: single => 判斷 detail 所有 item 是否必選選項都已完成 （用 detail.items）
    if (detail.type === "single") {
      let reachCount = 0
      detail.items.forEach(i => {
        if (i.id === detail.selectedItemId && isEveryRequiredOptionSelected(i)) {
          reachCount += 1
        }
      });

      if (detail.is_required) {
        pass = Boolean(reachCount >= detail.limit_qty);
      } else {
        const hasSelected = detail.selectedItemId > 0;
        if (hasSelected) {
          pass = Boolean(reachCount >= detail.limit_qty);
        } else {
          pass = true;
        }
      }
    }
    if (!pass) ids.push({
      id: detail.id,
      type: detail.type,
      itemIndex,
    })
  });
  return {
    ids,
    done: ids.length === 0,
  };
}