export const setSS = (key, value) => {
  const data = JSON.stringify(value);
  sessionStorage.setItem(key, data);
};

export const getSS = key => {
  const data = sessionStorage.getItem(key);
  return JSON.parse(data);
};

export const removeSS = key => {
  sessionStorage.removeItem(key);
};

export const removeAllSS = () => {
  sessionStorage.clear();
};
