import domain from './domain';
import axios from '@/utils/http';
import { getToken, appToken } from '@/utils/auth';
import store from '@/store/index';
import getI18nKey from './getLangValue';

// API Mock Data
// import postValidCampaignAndCouponData from '@/mock/marketingMockApiData/postValidCampaignAndCouponData';
// import getDefaultCampaignAndCouponData from '@/mock/marketingMockApiData/getDefaultCampaignAndCouponData';


const marketing = {
  /**
   * [GET] 取得優惠 (結帳折扣活動券、優惠券)
   * 應用情境：只需單純顯示「結帳折扣活動券、優惠券」，使用者無法選取券的時候。(會員、點餐頁面)
   */
  getBenefits(company_id, filters) {
    const token = store.getters.isLoggedIn ? getToken() : appToken;

    return axios.get(`${domain.new}/company/${company_id}/campaign`, {
      params: {
        lang: getI18nKey('api_lang'),
        ...filters,
      },
      headers: {
        Authorization: token,
      },
    });
  },
  /**
   * [POST] 取得合法的優惠 (結帳折扣活動券、優惠券)
   * 應用情境：使用者可以選取券，並需根據疊加優惠規則，取得合法的券。(結帳頁面)
   * @param {Number} order_menu_id 點餐單序號
   * @param {String} valid_key 驗證用Key (前端自行保存，每次 Request 都要帶給後端)
   * @param {Object Array} select_campaigns 選取優惠
   * @param {String} select_campaigns.sn 優惠序號
   * @param {Number} select_campaigns.param 優惠類型 (1:結帳活動 2:優惠券)
   * @param {String} pickup_time 取餐時間 (e.g. "2022-08-02 14:45")
   * @param {Number} pickup 取餐方式 (1:外送 2:外帶 3:內用)
   * @param {Number} shipping_fee 運費
   *
   * @returns {Object}
   */
  postValidCampaignAndCoupon(data) {
    const token = store.getters.isLoggedIn ? getToken() : appToken;
    const { order_menu_id, ...paramsObj } = data;

    return axios.post(`${domain.new}/order_menu/${order_menu_id}/campaign/valid`, paramsObj, {
      params: {
        lang: getI18nKey('api_lang'),
      },
      headers: { Authorization: token }
    });
  },
  /**
   * [GET] 符合條件的主打優惠 (預設優惠)
   * @param {Number} order_menu_id 點餐單序號
   * @param {Number} product_delivery 取餐方式 (1:外送 2:外帶 3:內用)
   * @param {String} pickup_time 取餐時間 (e.g. "2022-08-02 14:45")
   *
   * @returns {Object} 主要拿 campaigns 資料就好 (Array)
   */
  getDefaultCampaignAndCoupon(data) {
    const token = store.getters.isLoggedIn ? getToken() : appToken;
    const { order_menu_id, ...paramsObj } = data;

    return axios.get(`${domain.new}/order_menu/${order_menu_id}/default_campaign_coupon?lang=` + getI18nKey('api_lang'), {
      params: paramsObj,
      headers: {
        Authorization: token,
      },
    });
  },
  // 取得 VoucherList 折扣碼券
  getVoucherList(voucherCode) {
    return axios.post(`${domain.new}/voucher`, { code: voucherCode }, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: `${getToken()}`,
      },
      transformResponse: axios.defaults.transformResponse.concat((data) => {
        data = { ...data, byPass400Default: true };
        return data;
      })
    });
  },

};

export default marketing;
