import sha1 from 'sha1';
import md5 from 'md5';

import domain from './domain';
import axios from '@/utils/http';
import { getToken, appToken } from '@/utils/auth';
import getI18nKey from './getLangValue';

// API Mock Data
// import postPointExchangeCouponListData from "@/mock/marketingMockApiData/postPointExchangeCouponListData";

export default {
  /**
   * 1. 取得使用者總點數
   * 2. 品牌旗下各分店的點數
   * 3. 即將到期的點數
   * 使用範圍：會員頁面
   */
  getMemberPointsTotal() {
    return axios.get(`${domain.new}/member/point/total`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: getToken(),
      },
    });
  },
  /**
   * 取得點數使用的歷程紀錄
   * 使用範圍：會員頁面
   */
  getMemberPointsLog(data) {
    const { limit, page, store_id } = data;

    return axios.get(`${domain.new}/member/point/log`, {
      headers: {
        Authorization: getToken(),
      },
      params: {
        limit,
        page,
        store_id,
        lang: getI18nKey('api_lang')
      },
    });
  },
  /**
   * 使用點數兌換優惠券
   * 使用範圍：會員頁面
   */
  async exchangeCoupon(storeId, exchangeId) {
    const sid = storeId.toString();
    const eid = exchangeId.toString();
    const key = '87:20:F7:5F:8F:9';

    const csrfToken = md5(sha1(sid + eid + key));

    const data = {
      store_id: storeId,
      exchanges_id: exchangeId,
      csrf: csrfToken,
    };

    const res = await axios.post(`${domain.new}/v2/points/coupon/exchange`, data, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: getToken(),
      },
    });

    return res.data.response || [];
  },
  /**
   * 1. 取得可兌換的優惠券
   * 2. store_id === 0 --> 獲取到品牌資料 (非必填，後端也會自動判斷回傳對應資料)
   * 使用範圍：會員頁面
   */
  async getPointToCouponItems(store_id) {
    const res = await axios.get(`${domain.new}/v2/points/coupon/list/?store=${store_id}`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: getToken(),
      },
    });

    return res.data.response || [];
  },
  /**
   * 1. 取得使用者總點數
   * 2. 即將到期的點數
   * 3. 適用店家
   * 使用範圍：會員頁面、結帳頁面
   */
  getMemberPoints(store_id) {
    return axios.get(`${domain.new}/member/point`, {
      headers: {
        Authorization: getToken(),
      },
      params: {
        store_id,
        lang: getI18nKey('api_lang')
      },
    });
  },
  /**
   * 1. 取得可兌換的贈品
   * 2. store_id === 0 --> 獲取到品牌資料 (非必填，後端也會自動判斷回傳對應資料)
   * 使用範圍：會員頁面、結帳頁面
   */
  getExchangeListByCompanyAndStore({company_id, store_id}) {
    const isCompany = Number(store_id) === 0;
    const url = isCompany ? `${domain.new}/v1/member/2/${company_id}/${store_id}/exchanges/list`
                          : `${domain.new}/v1/member/2/${company_id}/${store_id}/exchanges/list?types=store_only`

    return axios.get(url, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
  /**
   * [POST] 取得 可用點數兌換的優惠券列表資料
   * @param {Number} order_menu_id 點餐單序號
   * @param {Number} shipping_fee 運費
   * @param {Object Array} select_campaigns 選取優惠
   * @param {String} select_campaigns.sn 優惠序號
   * @param {Number} select_campaigns.param 優惠類型 (1:結帳活動 2:優惠券)
   * @param {String} pickup_time 取餐時間 (e.g. "2022-08-02 14:45")
   * @param {Number} pickup 取餐方式 (1:外送 2:外帶 3:內用)
   *
   * @returns {Object}
   */
  postPointExchangeCouponList(data) {
    const { company_id, store_id, ...requestBody } = data;
    const requestUrl = `${domain.new}/company/${company_id}/store/${store_id}/exchange/list`;
    const requestHeaders = { params: { lang: getI18nKey('api_lang') }, headers: { Authorization: getToken() }};

    return axios.post(requestUrl, requestBody, requestHeaders);
  },
  /**
   * 使用點數兌換優惠券
   * 使用範圍：結帳頁面
   */
  postPointExchangeCoupon(obj) {
    const { company_id, store_id, ...data } = obj;

    return axios.post(`${domain.new}/company/${company_id}/store/${store_id}/exchange/redeem_coupon`, data, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: getToken(),
      },
    });
  },
}
