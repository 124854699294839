import { add as _add, subtract as _subtract, reduce as _reduce } from 'lodash-es';
import i18n from "@/plugin/i18n";

const getters = {
  autoReloadDetail: state => state.app.autoReloadDetail,

  // CONFIG
  config: state => state.config,
  currentLineConfig: (state, getters) => {
    // 格式參考 state.config.Line
    let LineConfig = state.config.Line;
    if (getters.companyId === 10) {
      // 單店模式 companyId is Number(10)
      let { storeSlug } = getters;
      LineConfig = state.config.branches[storeSlug].LINE;
    }
    return LineConfig;
  },

  logo: state => state.config.logo,

  companyId: state => state.config.id,
  companyName: state => state.config.name,
  companySlug: state => state.config.slug,

  businessName: state => state.config.businessName,

  storeId: state => state.config.currentStore.id,
  storeName: state => state.config.currentStore.name,
  storeSlug: state => state.config.currentStore.slug,

  primaryColor: state => state.config.primaryColor,
  secondaryColor: state => state.config.secondaryColor,

  isCompanyLevel: state => state.config.isCompanyLevel,
  isOnlineOrder: state => state.config.isOnlineOrder,
  earnPointsType: state => state.config.earnPointsType,
  isCompanyWalletEnable: state => state.config.isCompanyWalletEnable,

  backUrl: state => state.config.backUrl,

  // 品牌跟店家都會有 google api key
  // 在分店列表使用的是 「品牌」的 key
  // 各店家結帳頁裡面使用的是 「店家」的 key
  companyGoogleApiKey: state => state.config.googleApiKey,
  storeGoogleApiKey: state => state.config.currentStore.googleApiKey,

  // MENU
  menu: state => state.menu.categories,
  menuVersion: state => state.menu.version,
  // ORDER MENU
  orderMenuList: state => state.orderMenu.list,
  orderMenuIng: state => state.orderMenu.ing,
  isShareEnabled: state => state.orderMenu.ing.is_share,
  totalQuantity: state => state.orderMenu.ing.total_qty,
  totalPrice: state => state.orderMenu.ing.total_price,
  orderMenuServiceCharge: state => state.orderMenu.service_charge,

  // 訂單紀錄
  orderList: state => state.orderMenu.orderList,
  currentOrderHistory: state => state.orderMenu.currentOrderHistory,

  /** User */
  isLoggedIn: state => Boolean(state.user.login_token),
  user: state => state.user,
  userAccount: state => state.user.account,
  userEmail: state => state.user.email,
  lineProfile: state => state.user.lineProfile,
  userAddress: state => state.user.address,
  userCommonAddress: state => state.user.commonAddress,
  mobileBarcode: state => state.user.mobileBarcode,
  guiNumber: state => state.user.guiNumber,

  /** Table Settings(掃碼) */
  tables: state => state.branch.ing.tables,
  selectedTable: state => state.tableSettings.selectedTable,

  /** Modal 顯示管理 */
  isItemModalOpen: state => state.modal.opened.has("item"),
  isSetModalOpen: state => state.modal.opened.has("set"),
  isCartModalOpen: state => state.modal.opened.has("cart"),
  isStoreInfoModalOpen: state => state.modal.opened.has("storeInfo"),
  isShareSettingModalOpen: state => state.modal.opened.has("shareSetting"),
  isTakeMethodModalOpen: state => state.modal.opened.has("takeMethod"),
  isOrderSettingModalOpen: state => state.modal.opened.has("orderSetting"),
  isOrderRuleModalOpen: state => state.modal.opened.has("orderRule"),

  /** Modal 資料區 */
  itemModalData: state => state.modal.selected.item,
  setModalData: state => state.modal.selected.set,
  shareSettingModalData: state => state.modal.selected.shareSetting,
  shareSettingMode: state => state.modal.mode.shareSetting,

  // BRANCH
  branch: state => state.branch.ing,
  announcement: state => state.branch.ing.announcement,
  branchList: state => state.branch.list,
  /** @type {Boolean} 是否開啟服務費設定 */
  isServiceCharge: state => state.branch.ing.isServiceChargeEnable,
  /** @type {Boolean} 是否開啟儲值金 */
  isStoreWalletEnable: state => state.branch.ing.isStoreWalletEnable,
  /** @type {Boolean} 是否為掃碼後結帳 */
  isPostCheckout: state => state.branch.ing.isPostCheckout,
  pickup: state => state.branch.ing.pickup,
  /** @type {Boolean} 是否開啟亞洲萬里通結帳 */
  isAsiaMilesEnable: state => state.branch.ing.isAsiaMilesEnable,

  // postCheckout 細部資料
  postCheckoutOrderSN: state => state.postCheckout.orderSN,
  postCheckoutOrderId: state => state.postCheckout.orderId,
  showPostCheckoutMenuSend: state => state.postCheckout.showMenuSend,
  hasPostCheckoutOrderId: (state, getters) => {
    return getters.postCheckoutOrderId !== 0;
  },
  deliveryLimit: state => {

    let discount = 0;
    let thePrice = 0;
    const { total_price, total_qty } = state.orderMenu.ing;
    const { delivery } = state.branch.ing.pickup;

    const emptyConditionObject = {
      type: "",
      valueGap: 0,
      status: 0,
      text: "",
      statusText: "",
      fullText: ""
    };

    const marketings = state.marketing.selectedCampaignsAndCoupons;

    if (marketings.length > 0) {
      marketings.forEach((marketing) => {
        if (!marketing.isShippingFee) {
          discount = _add(discount, marketing.discount)
        }
      });
    }

    // 沒條件限制
    if (!delivery || delivery.length < 1) {
      return emptyConditionObject;
    }
    const limit = state.deliveryConditions.match;

    if (!limit) {
      return {
        ...emptyConditionObject,
        statusText: i18n.t('Lang.Page.checkout.__step1.__CheckoutStep1.out_of_delivery_range')[1],
        fullText: i18n.t('Lang.Page.checkout.__step1.__CheckoutStep1.out_of_delivery_range')[0]
      };
    }

    const { is_enable, type } = limit;
    thePrice = _subtract(total_price, discount);
    // 沒條件限制
    if (!is_enable) {
      return {
        ...emptyConditionObject,
        status: 1,
        statusText: i18n.t('Lang.Page.shopping_cart.delivery_conditions_reached'),
        fullText: i18n.t('Lang.Page.shopping_cart.delivery_conditions_reached')
      };
    }

    const value = state.checkout.delivery_insufficient_value;
    
    console.log({'件/元': type});
    if (type === "quantity") {
      const pass = !value;
      const statusText = pass ? i18n.t('Lang.Page.shopping_cart.delivery_conditions_reached') : i18n.t('Lang.Page.shopping_cart.delivery_conditions_not_reached');
      const valueGap = value;
      const pcs = i18n.t('Lang.Page.checkout.__step1.__CheckoutStep1.pcs');
      const text = pass ? "" : i18n.t('Lang.Page.shopping_cart.valueGap_message', {valueGap, type: pcs});
      return {
        type: "qty",
        valueGap,
        status: pass ? 1 : 0,
        text,
        statusText,
        fullText: pass ? i18n.t('Lang.Page.shopping_cart.delivery_conditions_reached') : `${text}`
      };
    }
    if (type === "amount") {
      const pass = !value;
      const statusText = pass ? i18n.t('Lang.Page.shopping_cart.delivery_conditions_reached') : i18n.t('Lang.Page.shopping_cart.delivery_conditions_not_reached');
      const valueGap = value;
      const price = i18n.t('Lang.Common.price');
      const text = pass ? "" : i18n.t('Lang.Page.shopping_cart.valueGap_message', {valueGap, type: price});

      return {
        type: "price",
        valueGap,
        status: pass ? 1 : 0,
        text,
        statusText,
        fullText: pass ? i18n.t('Lang.Page.shopping_cart.delivery_conditions_reached') : `${text}`
      };
    }
  },

  deliveryConditions: state => state.deliveryConditions,

  // CHECKOUT
  checkout: state => state.checkout.checkout,
  order_amount_payable: state => state.checkout.order_amount_payable,
  shipping_fee_data: state => state.checkout.shipping_fee_data,
  delivery_insufficient_value: state => state.checkout.delivery_insufficient_value,

  totalCheckoutPrice: (state, getters) => {
    const marketings = state.marketing.selectedCampaignsAndCoupons;
    let discount = 0;
    if (marketings.length > 0) {
      discount = _reduce(marketings.map(i => i.discount), (sum, n) => sum + n, 0)
    }

    const { total_price } = getters.orderMenuIng;
    const shippingFee = getters.checkout.shippingFee || 0;
    return total_price - discount + shippingFee;
  },

  // marketing Module
  campaignList: state => state.marketing.campaignList,
  couponList: state => state.marketing.couponList,
  validCampaignList: state => state.marketing.validCampaignList,
  validCouponList: state => state.marketing.validCouponList,
  couponModalIsVisible: state => state.marketing.couponModal,
  campaignModalIsVisible: state => state.marketing.campaignModal,
  selectedCampaigns: state => state.marketing.selectedCampaigns,
  modalSelectedCampaigns: state => state.marketing.modalSelectedCampaigns,
  selectedCoupons: state => state.marketing.selectedCoupons,
  modalSelectedCoupons: state => state.marketing.modalSelectedCoupons,
  selectedCampaignsAndCoupons: state => state.marketing.selectedCampaignsAndCoupons,
  defaultCampaignsAndCoupons: state => state.marketing.defaultCampaignsAndCoupons,
  isAchieveMaximumValue: state => state.marketing.isAchieveMaximumValue,
  validCampaignsIsEmpty: state => state.marketing.validCampaignsIsEmpty,
  validApiIsReady: state => state.marketing.validApiIsReady,

  // pointExchange Module
  pointExchange: state => state.pointExchange,
  isPointCouponEnable: state => state.pointExchange.isPointCouponEnable,
  points: state => state.pointExchange.points,
  pointsData: state => state.pointExchange.pointsData,
  costPoints: state => state.pointExchange.costPoints,
  exchangeCouponList: state => state.pointExchange.exchangeCouponList,
  memberExchangeCouponList: state => state.pointExchange.memberExchangeCouponList,
  exchangeFreebieList: state => state.pointExchange.exchangeFreebieList,
  selectedExchangeFreebie: state => state.pointExchange.selectedExchangeFreebie,
  exchangeFreebieModal: state => state.pointExchange.exchangeFreebieModal,

  // APP
  /** @type {Boolean} */
  showApiError: state => state.app.showApiError,

  /** @type {Boolean} */
  showLoading: state => state.app.showLoading,

  /** @type {Number} */
  loadingCounter: state => state.app.loadingCounter,
  
  /** @type {Number} */
  apiLoadingStatus: state => state.app.apiLoadingStatus,

  /** @type {Boolean} */
  isFollow: state => state.app.isFollow,

  /** @type {Boolean} */
  isLIFF: state => state.app.isLIFF,
  lineApiReadyTimer: state => state.app.lineApiTimer,

  /** @type {Boolean} */
  isWemo: state => state.app.isWemo,

  /** @type {Boolean} */
  isJko: state => state.app.isJko,

  /** @type {Boolean} */
  isReserve: state => state.app.isReserve,

  /** @type {Boolean} */
  isQrOrder: state => state.app.isQrOrder,

  /** @type {Boolean} */
  isImenuAvailable: state => state.branch.isImenuAvailable,

  /** @type {Boolean} */
  isGeoEnabled: state =>
    (state.geolocation.latitude !== 0 || state.geolocation.longitude !== 0),

  latitude: state => state.geolocation.latitude,
  longitude: state => state.geolocation.longitude,

  isCreditCardModalVisible: state => state.creditCards.isModalVisible,
  savedCards: state => state.creditCards.savedCards,
  selectedCard: state => state.creditCards.selectedCard,

  isAddressModalVisible: state => state.addressModal.isModalVisible,

  // takeMethod
  takeMethodInUse: state => state.orderSettings.takeMethodInUse,
  addressInUse: state => state.orderSettings.addressInUse,
  pickUpDateInUse: state => state.orderSettings.pickUp.date,
  pickUpTimeInUse: state => state.orderSettings.pickUp.time,
  isPickUpDateInUseToday: state => state.orderSettings.isToday,

  pickUpDateAndTime: state => state.pickUpDateAndTime,

  // Reserve
  reserveCompanyData: state => state.reserve.companyData,
  reserveStoreData: state => state.reserve.storeData,
  reserveInfoData: state => state.reserve.reserveInfoData,
  reserveOrderData: state => state.reserve.reserveOrderData,
  reserveOrderDetail: state => state.reserve.reserveOrderDetail,

  // JKO
  jkoView: state => state.jko.jkoView,
  jkoStoreId: state => state.jko.jkoStoreId,
  jkoOriginPath: state => state.jko.jkoOriginPath,

  // Wallet
  walletModal: state => state.wallet.walletModal,
  walletInvoiceSettingModal: state => state.wallet.walletInvoiceSettingModal,
  walletInfoData: state => state.wallet.walletInfoData,
  useWalletMoney: state => state.wallet.useWalletMoney,
  inputWalletMoney: state => state.wallet.inputWalletMoney,
  shippingFeeRes: state => state.shippingFeeRes,

  // waiting
  waiting: state => state.waiting,
};

export default getters;
