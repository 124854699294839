import vuexStore from '@/store/index';
import { Base64 } from 'js-base64';

const checkDidSetFollow = () => {
  // 確認是否開啟跟單模式 （通常為 false)
  return Boolean(vuexStore.getters.orderMenuIng.id);
}

// 取得訂單id，線上直接拿物件 / 本地透過 base64轉譯後取得
const generateOrderId = (followToken) => {
  return window.__followid__ || Base64.decode(followToken);
}

const getOrderMenuDetail = async (orderId) => {
  // 透過 fetch 撈取訂單資料
  const res = await vuexStore.dispatch('fetchOrderMenuDetail', {
    order_menu_id: orderId,
    // 紀錄當前為 跟單模式
    is_follow: true,
  });
  const orderMenu = res.data.response;
  return orderMenu;
}

const followProcess = async (to, from, next) => {
  const { followToken } = to.params;
  let didSetFollow = checkDidSetFollow();
  // 如果已經開啟跟單 直接返回
  if (didSetFollow) {
    return next();
  }

  // 紀錄當前為 跟單流程
  vuexStore.dispatch('setIsFollow', true);
  const orderId = generateOrderId(followToken);
  const orderMenu = await getOrderMenuDetail(orderId);

  // 將訂單資料拆分出這些變數
  const {
    company_id,
    store_id,
    company_slug,
    store_slug
  } = orderMenu;


  // set Config （品牌）
  await vuexStore.dispatch('fetchConfig', {
    company: company_slug,
    store: store_slug,
  });
  // set Branch （分店/店家）
  await vuexStore.dispatch('fetchBranchInfo', {
    company_id,
    store_id,
    // 從 imenu 導進來的（團主）
    from: 'IMENU',
  });

  const currentBranch = vuexStore.getters.config.branches[store_slug];

  // set store 更新資料到當前店家
  vuexStore.dispatch('updateCurrentStore', {
    id: currentBranch.id,
    name: currentBranch.name,
    slug: store_slug,
    qrcode: currentBranch.qrcode,
    google_api_key: currentBranch.google_api_key,
  });

  // 當 團主關閉跟團
  // 當 跟團有效期過期
  // 當 團主送單了 （後端判斷）
  if (orderMenu.status !== 'active' || !orderMenu.is_share) {
    return next({ name: 'timeout' });
  }

  return next();
}



export default followProcess;