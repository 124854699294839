var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.configIsReady)?_c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"app-wrapper"},[_c('el-container',[(
          (!_vm.isInLiffEnv || _vm.isQrOrder) &&
          (_vm.branch.isImenuAvailable || _vm.branch.isQrOrderEnable) &&
          !_vm.isWemo &&
          !_vm.isJko
        )?_c('AppNavbar'):_vm._e(),_c('div',{staticClass:"app-main",class:{
          follow: _vm.isFollow,
          noMarginTop: (_vm.isLIFF && !_vm.isQrOrder) || _vm.isWemo || _vm.isJko,
        }},[_c('AppMain')],1)],1)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }