import API from '@/api/postCheckout';

export default {
  state: {
    // 後結帳時，可能沒有拿到orderId，但是必定會拿到orderSN
    // orderId 代表結帳單的id，掃碼主要流程就是把「點餐單」 加到 「結帳單」
    // orderSN 代表內用單號，用在新增點餐單當作參數，以及顯示時使用
    // orderSN 不是 orderId 的另一組代號
    orderId: 0,
    orderSN: '',
    showMenuSend: false,
  },
  mutations: {
    RESET_ORDER_SN(state) {
      state.orderSN = '';
    },
    SET_ORDER_SN(state, payload) {
      state.orderSN = payload;
    },
    RESET_ORDER_ID(state) {
      state.orderId = 0;
    },
    SET_ORDER_ID(state, payload) {
      state.orderId = payload;
    },
    SET_SHOW_MENU_SEND(state, isShow) {
      state.showMenuSend = isShow;
    }
  },
  actions: {
    resetOrderSN({ commit }) {
      commit('RESET_ORDER_SN');
    },
    setOrderSN({ commit }, orderSN) {
      commit('SET_ORDER_SN', orderSN);
    },
    resetOrderId({ commit }) {
      commit('RESET_ORDER_ID');
    },
    setOrderId({ commit }, orderId) {
      commit('SET_ORDER_ID', orderId);
    },
    async checkPostCheckoutTable({ commit }, { storeId, storeTableId, orderMenuId }) {
      let response = await API.checkPostCheckoutTable({ storeId, storeTableId, orderMenuId });
      const data = response.data.response;
      return data;
    },
    async getPostCheckoutDetail({ commit }, { storeId, orderId }) {
      let response = await API.getPostCheckoutDetail({ storeId, orderId });
      const data = response.data.response;
      return data;
    },
    async sendPostCheckoutAction({ commit }, payload) {
      let response = await API.sendPostCheckout(payload);
      const data = response.data.response;
      return data;
    },
    setShowMenuSend({ commit }, isShow) {
      commit('SET_SHOW_MENU_SEND', isShow);
    }
  },
};
