import API from '@/api/marketing';
import { cloneDeep } from 'lodash-es';
import moment from 'moment';


/** 檢查 疊加優惠最大值 */
const checkAchieveMaximumValue = (state) => {
  return state.selectedCampaignsAndCoupons?.length === 5;
}
/** 檢查 活動券是否沒有任何的 validCampaignList 同時 selectedCampaigns 沒有任何資料 */
const checkCampaignIsEmpty = (state) => {
  const validList = state.validCampaignList;
  const selectedList = state.selectedCampaigns;
  return validList.length === 0 && selectedList.length === 0;
}

/** 處理取餐方式的文案 */
const displayTakeMethodText = (data) => {
  if (data.length === 3) return "不限";
  const takeMethodTextMap = new Map([
    [1, '外送'],
    [2, '外帶/自取'],
    [3, '內用'],
  ])

  return data.map(i => takeMethodTextMap.get(i)).join('、');
}
/** 處理折扣商品的文案 */
const displayDiscountedProductText = (data) => {
  if (!data.menu_item) {
    if (data.menu_item_condition === 0) return '不限';
    return '此分店未設定';
  }

  // only name
  if (!data.is_menu_item_group) {
    return data.menu_item.map(e => e.name).join('、');
  }

  // has group_name (A + B)
  if (data.is_menu_item_group) {
    const getGroups = productGroupBy(data.menu_item, 'group_name');
    return productDisplayStr(getGroups);
  }
}
/** 處理折扣商品的文案 - A+B優惠券的折扣商品(分組) */
function productGroupBy(arrObj, target) {
  return arrObj.reduce((previousValue, currentValue) => {
    const key = currentValue[target];
    if (!previousValue[key]) {
      previousValue[key] = [];
    }
    previousValue[key].push(currentValue.name);

    return previousValue;
  }, {})
}
/** 處理折扣商品的文案 - A+B優惠券的折扣商品(顯示) */
function productDisplayStr(obj) {
  let resultStr = ""
  for (const [key, val] of Object.entries(obj)) {
    resultStr += `<br/>${key}: ${val.join('、')}`;
  }

  return resultStr.slice(5);
}
/** 處理使用方式(兌換方式)的文案 */
const displayRedeemMethodText = (data) => {
  return data ? '線上訂餐/店面折抵' : '線上訂餐';
}
/** 處理券的有效期限 */
const availableTimeString = (startDate, endDate) => {
  const start = moment(startDate).format('YYYY-MM-DD HH:mm');
  const end = moment(endDate).format('YYYY-MM-DD HH:mm');
  return `${start} - ${end}`;
}


/** 將「折扣活動券」or 「優惠券」資料轉成前端的格式 */
const campaignsAndCouponsDataAdapter = (data) => {
  let startDate = new Date(data.start_at * 1000);
  let endDate = new Date(data.end_at * 1000);

  return {
    aptStore: data.apt_store,
    title: data.title,
    description: data.description,
    remark: data.remark,
    category: data.type,
    storeId: data.store_id,
    storeName: data.store_name,
    takeMethods: data.available_pickup,
    startDate,
    endDate,
    startTimestamp: data.start_at,
    endTimestamp: data.end_at,
    isManualRedeem: data.is_manual_redeem,
    // 是否已兌換
    isRedeemed: data.is_redeemed,
    redeemedDate: data.redeemed_at && new Date(data.redeemed_at * 1000),
    sn: data.sn,
    isExpired: data.is_expired,
    /**
     * @param {Boolean} is_shipping_fee 運費折抵
     * 如果為 true -> 此券的 discount 是用來折抵運費
     * 如果為 false -> 此券的 discount 是用來折抵餐點費用
     * 只能擇一折抵!!
     */
    isShippingFee: data.is_shipping_fee,
    // 可否做優惠疊加
    isStackable: data.is_stackable,
    // 是否有效
    isValid: data.is_valid,
    // 折扣額
    discount: data.discount,
    // 是否開啟主打(預設)優惠
    isAutoApply: data.is_auto_apply,
    // 權重排序
    sortBy: data.sort_by,
    conditionDescription: data.condition_description,
    qrcode: data.qrcode,
    takeMethodString: displayTakeMethodText(data.available_pickup),
    conditionItemsString: displayDiscountedProductText(data.condition),
    redeemTypeString: displayRedeemMethodText(data.is_manual_redeem),
    availableTimeString: availableTimeString(startDate, endDate),
  }
};

/**
 * 提取出合法的「折扣活動券」and「優惠券」
 * @param {Array} data API 的 valid_campaigns (合法的活動優惠)
 * @param {Array} uiCampaigns 所有的活動券 (已轉成前端格式)
 * @param {Array} uiCoupons 所有的優惠券 (已轉成前端格式)
 */
const validCampaignsDataAdapter = (data, uiCampaigns, uiCoupons) => {
  const uiValidCampaigns = [];
  const uiValidCoupons = [];

  data.forEach((apiValidItem) => {
    const isCampaign = apiValidItem.type === 1;

    const uiValidData = isCampaign ? uiValidCampaigns : uiValidCoupons;
    const uiData = isCampaign ? uiCampaigns : uiCoupons;

    if (uiData.length !== 0) {
      const isValidData = uiData.filter((uiItem) => {
        if (uiItem.sn === apiValidItem.sn) {
          // 重新寫入折扣金額
          uiItem.discount = apiValidItem.discount;
          return uiItem;
        }
      })
      uiValidData.push(...isValidData);
    }
  })

  return { uiValidCampaigns, uiValidCoupons };
};

/**
 * 將「已選擇」資料轉成前端的格式
 * @param {Array} data API 的 select_campaigns (合法的活動優惠)
 * @param {Array} uiCampaigns 所有的活動券 (已轉成前端格式)
 * @param {Array} uiCoupons 所有的優惠券 (已轉成前端格式)
 */
const selectCampaignsDataAdapter = (apiData, uiCampaigns, uiCoupons) => {
  const uiSelectCampaignsAndCoupons = [];

  apiData.forEach((apiSelectedItem) => {
    const isCampaign = apiSelectedItem.type === 1;
    const originData = isCampaign ? uiCampaigns : uiCoupons;

    if (originData.length !== 0) {
      const targetData = originData.find(i => i.sn === apiSelectedItem.sn);

      // 重新寫入最新的 折扣額
      targetData.discount = apiSelectedItem.discount;
      uiSelectCampaignsAndCoupons.push(targetData);
    }
  })

  return uiSelectCampaignsAndCoupons;
};

/** 處理 取得「結帳活動折扣券」與「優惠券」的 Response */
const handlePostValidCampaignAndCouponResponse = (data, state) => {
  const {
    campaigns: apiCampaigns,
    coupons: apiCoupons,
    valid_campaigns: apiValidCampaigns,
    select_campaigns: apiSelectCampaignsAndCoupons
  } = data;

  const dataIsUpdate = data.valid_key !== state.validKey;
  const originCoupons = state.couponList;
  const originCampaigns = state.campaignList;

  // 整理「折扣活動券」資料 -> User所有的折扣活動券資料
  const uiCampaigns = dataIsUpdate 
    ? apiCampaigns.map(campaign => campaignsAndCouponsDataAdapter(campaign)) 
    : originCampaigns;
  // 整理「優惠券」資料 -> User所有的優惠券資料
  const uiCoupons = dataIsUpdate 
    ? apiCoupons.map(coupon => campaignsAndCouponsDataAdapter(coupon)) 
    : originCoupons;
  // 整理「合法的」資料 -> 從 uiCampaigns、uiCoupons 提取出合法的「折扣活動券」「優惠券」
  const { uiValidCampaigns, uiValidCoupons } = validCampaignsDataAdapter(apiValidCampaigns, uiCampaigns, uiCoupons);
  // 整理「已選擇」資料 -> 重新取得當前選擇的活動優惠券與最新的折扣金額
  const uiSelectCampaignsAndCoupons = selectCampaignsDataAdapter(apiSelectCampaignsAndCoupons, uiCampaigns, uiCoupons);

  return {
    uiCampaigns: uiCampaigns ?? [],
    uiCoupons: uiCoupons ?? [],
    uiValidCampaigns: uiValidCampaigns ?? [],
    uiValidCoupons: uiValidCoupons ?? [],
    uiSelectCampaignsAndCoupons: uiSelectCampaignsAndCoupons ?? []
  }
}

const marketing = {
  state: {
    // 第一次的Response會從後端拿到，保存起來每次Request都要帶回後端 (後端做Cache用的判斷Key)
    validKey: "",
    // 所有的優惠券
    couponList: [],
    // 所有的活動券
    campaignList: [],
    // 合法的優惠券
    validCouponList: [],
    // 合法的活動券
    validCampaignList: [],
    // 結帳頁面 - 折扣活動Modal顯示判斷 
    campaignModal: false,
    // 結帳頁面 - 優惠券Modal顯示判斷
    couponModal: false, 
    // 結帳頁面 - 折扣活動Modal內已被點選的資料
    modalSelectedCampaigns: [],
    // 結帳頁面 - 優惠券Modal內已被點選的資料
    modalSelectedCoupons: [],
    // 結帳頁面 - 已選擇的活動券
    selectedCampaigns: [],
    // 結帳頁面 - 已選擇的優惠券
    selectedCoupons: [],
    // 結帳頁面 - 已選擇的活動券與優惠券 (統整起來打API讓後端做疊加優惠的驗證，順序不能亂!)
    selectedCampaignsAndCoupons: [],
    // 結帳頁面 - 主打優惠
    defaultCampaignsAndCoupons: [],
    // 判斷是否大於疊加優惠的最大值5 (活動+優惠=5, 優惠券僅能1)
    isAchieveMaximumValue: false,
    // 判斷當前是否沒有任何「合法的」活動券，並且也沒有選擇任何活動券。
    validCampaignsIsEmpty: false,
    // 計算運份費等優惠 api 完成後才開始計算
    validApiIsReady: false,
  },
  mutations: {
    RESET_SELECTED_CAMPAIGNS_AND_COUPONS_DATA: (state) => {
      state.modalSelectedCampaigns = [];
      state.modalSelectedCoupons = [];
      state.selectedCampaigns = [];
      state.selectedCoupons = [];
      state.selectedCampaignsAndCoupons = [];
    },
    SET_CAMPAIGNS: (state, payload) => {
      state.campaignList = [...payload];
    },
    SET_COUPONS: (state, payload) => {
      state.couponList = [...payload];
    },
    SET_VALID_CAMPAIGNS: (state, payload) => {
      state.validCampaignList = [...payload];
    },
    SET_VALID_COUPONS: (state, payload) => {
      state.validCouponList = [...payload];
    },
    SET_SELECTED_CAMPAIGNS_AND_COUPONS: (state, payload) => {
      const campaignsAndCoupons = payload;
      const campaigns = [];
      const coupons = [];

      payload.forEach((item) => {
        item.category === 1
          ? campaigns.push(item)
          : coupons.push(item)
      })

      state.modalSelectedCampaigns = [...campaigns];
      state.selectedCampaigns = [...campaigns];
      state.modalSelectedCoupons = [...coupons];
      state.selectedCoupons = [...coupons];
      state.selectedCampaignsAndCoupons = [...campaignsAndCoupons];
      state.validApiIsReady = true;
      // 更新 疊加優惠最大值
      state.isAchieveMaximumValue = checkAchieveMaximumValue(state);
    },
    SET_VALID_KEY: (state, payload) => {
      state.validKey = payload;
    },
    SET_VALID_CAMPAIGNS_IS_EMPTY: (state, payload) => {
      state.validCampaignsIsEmpty = payload;
    },
    RESET_VALID_KEY: (state) => {
      state.validKey = "";
    },
    OPEN_CAMPAIGN_MODAL: (state) => {
      state.campaignModal = true;
    },
    CLOSE_CAMPAIGN_MODAL: (state) => {
      state.campaignModal = false;
    },
    OPEN_COUPON_MODAL: (state) => {
      state.couponModal = true;
    },
    CLOSE_COUPON_MODAL: (state) => {
      state.couponModal = false;
    },
    HANDLE_SELECTED_CAMPAIGNS_COUPONS_UI_DATA: (state, payload) => {
      const { key, action, data } = payload;

      switch (true) {
        case action === 'select':
          state[key] = data;
          break;
        case action === 'cancel':
          state[key] = [];
          break;
        case action === 'delete':
          const idx = state[key].findIndex((i) => i.sn === data.sn);
          state[key].splice(idx, 1);
          break;
        case action === 'submit':
          state[key].push(...data);
          break;
        case action === 'clear':
          state[key] = [];
          break;
      }
      // 更新 疊加優惠最大值
      state.isAchieveMaximumValue = checkAchieveMaximumValue(state);
    },
    SET_DEFAULT_CAMPAIGNS_AND_COUPONS: (state, payload) => {
      state.defaultCampaignsAndCoupons = [...payload];
    },
  },
  actions: {
    // [API] 取得「結帳活動折扣券」與「優惠券」(會員頁面、菜單頁面)
    async GET_CAMPAIGNS_AND_COUPONS({ commit, getters }, payload) {
      let response = await API.getBenefits(getters.companyId, payload);
      let data = response.data.response;
      const cloneData = cloneDeep(data);
      const apiCampaigns = cloneData.campaigns;
      const apiCoupons = cloneData.coupons;

      // 整理「折扣活動券」資料 -> User所有的折扣活動券資料
      const uiCampaigns = apiCampaigns.map(campaign => campaignsAndCouponsDataAdapter(campaign));
      // 整理「優惠券」資料 -> User所有的優惠券資料
      const uiCoupons = apiCoupons.map(coupon => campaignsAndCouponsDataAdapter(coupon));

      commit('SET_CAMPAIGNS', uiCampaigns);
      commit('SET_COUPONS', uiCoupons);
      return { uiCampaigns, uiCoupons };
    },
    // [API] 取得合法的「結帳活動折扣券」與「優惠券」(結帳頁面)
    async POST_VALID_CAMPAIGNS_AND_COUPONS({ commit, state }, payload) {
      const apiRequestObj = { ...payload, valid_key: state.validKey };
      const response = await API.postValidCampaignAndCoupon(apiRequestObj);
      const data = response.data.response;

      // 轉成前端需要的資料格式
      const { uiCampaigns, uiCoupons, uiValidCampaigns, uiValidCoupons, uiSelectCampaignsAndCoupons }
        = handlePostValidCampaignAndCouponResponse(data, state);

      commit('SET_VALID_KEY', data.valid_key);
      commit('SET_CAMPAIGNS', uiCampaigns);
      commit('SET_COUPONS', uiCoupons);
      commit('SET_VALID_CAMPAIGNS', uiValidCampaigns);
      commit('SET_VALID_COUPONS', uiValidCoupons);
      commit('SET_SELECTED_CAMPAIGNS_AND_COUPONS', uiSelectCampaignsAndCoupons);
      commit('SET_VALID_CAMPAIGNS_IS_EMPTY', checkCampaignIsEmpty(state))
      
      return { uiCampaigns, uiCoupons, uiValidCampaigns, uiValidCoupons, uiSelectCampaignsAndCoupons };
    },
    // [API] 取得「主打優惠」(結帳頁面)
    async GET_DEFAULT_CAMPAIGNS_AND_COUPONS({ commit }, payload) {
      const apiResponse = await API.getDefaultCampaignAndCoupon(payload);
      const data = apiResponse.data.response;
      const campaigns = data.campaigns;

      commit('SET_DEFAULT_CAMPAIGNS_AND_COUPONS', campaigns);
      return campaigns;
    },
    // 統一處理活動與優惠券選取的資料
    HANDLE_SELECTED_CAMPAIGNS_COUPONS_UI_DATA({ commit }, payload) {
      commit("HANDLE_SELECTED_CAMPAIGNS_COUPONS_UI_DATA", payload);
    },
    RESET_SELECTED_CAMPAIGNS_AND_COUPONS_DATA({ commit }) {
      commit('RESET_SELECTED_CAMPAIGNS_AND_COUPONS_DATA')
    },
    SET_VALID_KEY({ commit }, payload) {
      commit("SET_VALID_KEY", payload);
    },
    RESET_VALID_KEY({ commit }) {
      commit("RESET_VALID_KEY");
    },
    OPEN_CAMPAIGN_MODAL({ commit }) {
      commit("OPEN_CAMPAIGN_MODAL");
    },
    CLOSE_CAMPAIGN_MODAL({ commit }) {
      commit("CLOSE_CAMPAIGN_MODAL");
    },
    OPEN_COUPON_MODAL({ commit }) {
      commit("OPEN_COUPON_MODAL");
    },
    CLOSE_COUPON_MODAL({ commit }) {
      commit("CLOSE_COUPON_MODAL");
    },
    SET_COUPONS({ commit }, payload) {
      commit('SET_COUPONS', payload);
    }
  },
};

export default marketing;
