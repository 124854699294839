// Transpiler for older browsers
import 'core-js/stable';
// optional but required for transforming generator fns.
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import Clipboard from 'v-clipboard';
import Scrollspy from 'vue2-scrollspy';
import VueCurrencyFilter from 'vue-currency-filter';
import infiniteScroll from 'vue-infinite-scroll'

// Element UI
import {
  Dialog,
  Autocomplete,
  Menu, MenuItem,
  Input, InputNumber,
  Radio, RadioGroup, RadioButton,
  Checkbox, CheckboxGroup,
  Switch,
  Select,
  Option,
  Button,
  DatePicker,
  TimePicker,
  Popover,
  Form, FormItem,
  Tabs, TabPane,
  Tag,
  Icon,
  Row,
  Col,
  Card,
  Collapse, CollapseItem,
  Container,
  Header,
  Main,
  Loading, MessageBox, Notification, Message,
  carousel,
} from 'element-ui';
// switch
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import ElLocale from 'element-ui/lib/locale'
import VueCarousel from 'vue-carousel';
// elementUI 語系包
import elementUIi18n from './lang/elementUIi18n';
Vue.prototype.$elementUIi18n = elementUIi18n;

// CSS
import 'normalize.css/normalize.css'; // CSS resets
import './styles/index.scss'; // Global CSS styles

// vue directive
import { imgLazyLoadingDirective } from '@/utils/vueDirective';

// vue sentry
// import { useSentry } from "@/plugin/sentry";

import i18n from "@/plugin/i18n";

// 使用語系設定
let local_lang = (liff.getLanguage().toUpperCase().indexOf('ZH') != -1) ? 'zh-tw' : 'en';
let localStorageKeepLang = localStorage.getItem('language');
i18n.locale = localStorageKeepLang || local_lang;
localStorage.setItem('language', i18n.locale);


import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import api from '@/api';

import './icons';

// 預設 element-ui 語系
ElLocale.use(elementUIi18n[i18n.locale]);

// useSentry(Vue, router);
Vue.config.productionTip = true;
Vue.use(Dialog);
Vue.use(Autocomplete);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Switch);
Vue.use(Select);
Vue.use(Option);
Vue.use(Button);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Popover);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Tag);
Vue.use(Icon);
Vue.use(Row);
Vue.use(Col);
Vue.use(Card);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Container);
Vue.use(Header);
Vue.use(Main);
Vue.use(carousel);


Vue.use(Loading.directive);

Vue.component(CollapseTransition.name, CollapseTransition);
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;

Vue.use(VueCarousel);
Vue.prototype.$api = api;
Vue.prototype.$I18n = i18n;
Vue.prototype.$ElLocale = ElLocale;

Vue.prototype.$level = (routeName) => {
  if (store.getters.isCompanyLevel) {
    return `${routeName}.company`;
  }
  return `${routeName}.store`;
};

Vue.directive('img-lazy-loading', imgLazyLoadingDirective(0, "500px"));

Vue.use(infiniteScroll);
Vue.use(Scrollspy);
Vue.use(Clipboard);
Vue.use(VueCurrencyFilter, {
  symbol: '$',
  thousandsSeparator: ',',
  fractionCount: 0,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: false,
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
