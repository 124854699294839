import router from '@/router';
import store from '@/store/index';
import { getStorage, setStorage, removerStorage } from "@/utils/public/localStorage";
export default {
  state: {
    isModalVisible: false,
    keepAddress: ' ',
    keepPurchaseTitle: ' ',
  },
  mutations: {
    OPEN_ADDRESS_MODAL: state => {
      state.isModalVisible = true;
      router.push({ query: { addressModal: true } });
    },
    CLOSE_ADDRESS_MODAL: (state, status) => {
      state.isModalVisible = false;
      router.push({ query: {} });

      if (status == 'restoreStorage') {
        const { address, purchase_title } = getStorage('keepOriginUserAddressData', true);
        state.keepAddress = address;
        state.keepPurchaseTitle = purchase_title;
      }
    },
    KEEP_ADDRESS_DATA: (state, value) => {
      if (value.address !== undefined) state.keepAddress = value.address;
      if (value.purchase_title !== undefined) state.keepPurchaseTitle = value.purchase_title;
    }
  },
  actions: {
    openAddressModal({ commit }) {
      commit('OPEN_ADDRESS_MODAL');
    },
    closeAddressModal({ commit }, status) {
      commit('CLOSE_ADDRESS_MODAL', status);
    },
    keepAddressData({ commit }, value) {
      commit('KEEP_ADDRESS_DATA', value);
    }
  },
};
