import {
  LOGGED_IN_WITH_LINE
} from './mutationTypes'
import user from '@/api/user';
import router from '@/router/index'
import { MessageBox } from "element-ui";
import Cookies from "js-cookie";
import i18n from "@/plugin/i18n";
import * as Sentry from "@sentry/vue";
import { upDateUserLang } from '@/utils/upDateUserLang'

const initialState = {
  isLineLoggedIn: false,
};

let lock = false;

const lineModule = {
  state: {
    ...initialState,
  },
  mutations: {
    [LOGGED_IN_WITH_LINE](state, payload) {
      state.isLineLoggedIn = true;
    },
  },
  actions: {
    async thirdLoginWithLine(store, {
      storeId = null,
      profile,
      accessToken,
      disableAutoRedirect,
      redirectCookiePath,
    }) {
      if (lock && store.getters.isLIFF) return;
      lock = true;

      if (redirectCookiePath) {
        // LIFF 在 router 那邊自動登入（或註冊或驗證）後，應該要導回原本的路徑
        // 由於 LIFF 自動登入（或註冊或驗證）並沒有經過 Login.vue 所以這邊一樣要記住導向路徑 Cookie
        const inTenMinutes = new Date(new Date().getTime() + 10 * 60 * 1000);

        let redirectCookieKey = `redirect_path_${store.getters.companySlug}`;

        if (!store.getters.isCompanyLevel) {
          redirectCookieKey = `redirect_path_${store.getters.companySlug}_${store.getters.storeSlug}`;
        }

        Cookies.set(redirectCookieKey, redirectCookiePath, { expires: inTenMinutes });
      }

      const $level = (routeName) => {
        if (store.getters.isCompanyLevel) {
          return `${routeName}.company`;
        }
        return `${routeName}.store`;
      };
      try {
        const response = await user.thirdPartyLogin({
          source: 'LINE',
          company_id: store.getters.companyId,
          store_id: storeId || store.getters.storeId,
          platform_uuid: profile.userId,
          platform_token: accessToken,
        });

        const userData = response.data.response;

        store.dispatch('loggedInWithLine', {
          userData,
          profile
        });
        await store.commit(LOGGED_IN_WITH_LINE);

        // 如果 response 的 lang 未設定
        if (!userData.lang) {
          // 執行 更新 user 資料
          // originalUserInfo 傳遞 response 過去 做資料更新 並且 在 originalUserInfo 中加入 lang 的設定
          await upDateUserLang(userData);
        } else {
          let lang = (userData.lang == 'zh_TW') ? 'zh-tw' : userData.lang;
          i18n.locale = lang;
        }

        // 這邊如果是 LIFF 進來自動登入，不該自動導向
        // 詳細說明請見 src/router/index.js
        if (disableAutoRedirect) return;

        store.dispatch('redirectToSpecifyRouteAfterLogin', { name: $level('index') });
      } catch (error) {
        console.error(error);
        if (error && error.data && error.data.code === 102) {
          MessageBox.confirm(error.data.message, i18n.t('Lang.Page.login.__ForgetPassword.you_have_not_yet_passed_the_mobile_verification_process'), {
            confirmButtonText: i18n.t('Lang.Page.login.__ForgetPassword.send_verification_pin'),
            cancelButtonText: i18n.t('Lang.Category.button.cancel'),
            type: "warning"
          })
            .then(() => {
              store.dispatch('redirectTo', {
                name: $level('captcha'),
                params: {
                  ...router.currentRoute.params,
                  account: error.data.response.phone,
                  previousAccount: error.data.response.phone,
                  thirdUserId: profile.userId,
                  type: 'LINE',
                },
              })
            })
            .catch(() => {
              // LIFF 直接關掉視窗
              if (store.getters.isLIFF) {
                window.liff.closeWindow();
                return;
              }
              store.dispatch('redirectTo', {
                name: $level('login'),
                params: {
                  ...router.currentRoute.params,
                },
              })
            });
        } else if (error && error.data && error.data.code === 103) {
          MessageBox.confirm(error.data.message, i18n.t('Lang.Page.login.__ForgetPassword.You_have_not_joined_as_a_member'), {
            confirmButtonText: i18n.t('Lang.Page.login.__ForgetPassword.go_to_registration'),
            cancelButtonText: i18n.t('Lang.Category.button.cancel'),
            type: "warning"
          })
            .then(() => {
              store.dispatch('redirectTo', {
                name: $level('thirdSignup'),
                params: {
                  ...router.currentRoute.params,
                  type: 'LINE',
                  profile,
                  token: accessToken,
                },
              })
            })
            .catch(() => {
              // LIFF 直接關掉視窗
              if (store.getters.isLIFF) {
                window.liff.closeWindow();
                return;
              }
              store.dispatch('redirectTo', {
                name: $level('login'),
                params: {
                  ...router.currentRoute.params,
                },
              })
            });
        }
      }
    },
    async qrOrderThirdLoginWithLine(store, {
      profile,
      accessToken,
      disableAutoRedirect,
      redirectCookiePath,
    }) {
      if (lock && store.getters.isLIFF) return;
      lock = true;

      if (redirectCookiePath) {
        // LIFF 在 router 那邊自動登入（或註冊或驗證）後，應該要導回原本的路徑
        // 由於 LIFF 自動登入（或註冊或驗證）並沒有經過 Login.vue 所以這邊一樣要記住導向路徑 Cookie
        const inTenMinutes = new Date(new Date().getTime() + 10 * 60 * 1000);

        let redirectCookieKey = `redirect_path_${store.getters.companySlug}`;

        if (!store.getters.isCompanyLevel) {
          redirectCookieKey = `redirect_path_${store.getters.companySlug}_${store.getters.storeSlug}`;
        }

        Cookies.set(redirectCookieKey, redirectCookiePath, { expires: inTenMinutes });
      }

      const $level = (routeName) => {
        if (store.getters.isCompanyLevel) {
          return `${routeName}.company`;
        }
        return `${routeName}.store`;
      };
      try {
        const response = await user.thirdPartyLogin({
          source: 'LINE',
          company_id: store.getters.companyId,
          store_id: store.getters.storeId,
          platform_uuid: profile.userId,
          platform_token: accessToken,
        });

        const userData = response.data.response;

        store.dispatch('loggedInWithLine', {
          userData,
          profile
        });

        await store.commit(LOGGED_IN_WITH_LINE);

        // 如果 response 的 lang 未設定
        if (!userData.lang) {
          // 執行 更新 user 資料
          // originalUserInfo 傳遞 response 過去 做資料更新 並且 在 originalUserInfo 中加入 lang 的設定
          await upDateUserLang(userData);
        } else {
          let lang = (userData.lang == 'zh_TW') ? 'zh-tw' : userData.lang;
          i18n.locale = lang;
        }

        // 這邊如果是 LIFF 進來自動登入，不該自動導向
        // 詳細說明請見 src/router/index.js
        if (disableAutoRedirect) return;

        store.dispatch('redirectToSpecifyRouteAfterLogin', {
          name: $level('index'),
          query: {
            table: router.currentRoute.query.table,
          },
          params: {
            ...router.currentRoute.params,
            store: router.currentRoute.query.store_slug
          },
        });
      } catch (error) {
        console.error(error);
        if (error && error.data && error.data.code === 102) {
          MessageBox.confirm(error.data.message, i18n.t('Lang.Page.login.__ForgetPassword.you_have_not_yet_passed_the_mobile_verification_process'), {
            confirmButtonText: i18n.t('Lang.Page.login.__ForgetPassword.send_verification_pin'),
            cancelButtonText: i18n.t('Lang.Category.button.cancel'),
            type: "warning"
          })
            .then(() => {
              store.dispatch('redirectTo', {
                name: $level('captcha'),
                params: {
                  ...router.currentRoute.params,
                  store: router.currentRoute.query.store_slug,
                  account: error.data.response.phone,
                  previousAccount: error.data.response.phone,
                  thirdUserId: profile.userId,
                  type: 'LINE',
                },
                query: {
                  table: router.currentRoute.query.table,
                },
              })
            })
            .catch(() => {
              // LIFF 直接關掉視窗
              if (store.getters.isLIFF) {
                window.liff.closeWindow();
                return;
              }
              store.dispatch('redirectTo', {
                name: $level('login'),
                params: {
                  ...router.currentRoute.params,
                  store: router.currentRoute.query.store_slug,
                },
                query: {
                  table: router.currentRoute.query.table,
                },
              })
            });
        } else if (error && error.data && error.data.code === 103) {
          MessageBox.confirm(error.data.message, i18n.t('Lang.Page.login.__ForgetPassword.You_have_not_joined_as_a_member'), {
            confirmButtonText: i18n.t('Lang.Page.login.__ForgetPassword.go_to_registration'),
            cancelButtonText: i18n.t('Lang.Category.button.cancel'),
            type: "warning"
          })
            .then(() => {
              store.dispatch('redirectTo', {
                name: $level('thirdSignup'),
                params: {
                  ...router.currentRoute.params,
                  store: router.currentRoute.query.store_slug,
                  type: 'LINE',
                  profile,
                  token: accessToken,
                },
                query: {
                  table: router.currentRoute.query.table,
                },
              })
            })
            .catch(() => {
              // LIFF 直接關掉視窗
              if (store.getters.isLIFF) {
                window.liff.closeWindow();
                return;
              }
              store.dispatch('redirectTo', {
                name: $level('login'),
                params: {
                  ...router.currentRoute.params,
                  store: router.currentRoute.query.store_slug,
                },
                query: {
                  table: router.currentRoute.query.table,
                },
              })
            });
        } else {
          MessageBox({
            title: i18n.t('Lang.Category.message_box.error_message'),
            message: "(line)"+i18n.t('Lang.Category.message_box.server_wrong_please_try_later'),
          })
            .then(() => {
              // this.$router.go(-1)
            })
            .catch(() => {});
        }
      }
    }
  },
};

export default lineModule;
