const waiting = {
  state: {
    waitingRes: null,
    waitingSearchRes: null,
    waitingCheckRes: null,
    step: '',
    isBusinessHours: null,
  },
  mutations: {
    UPDATE_WAITING_RES: (state, value) => {
      state.waitingRes = value;
    },
    UPDATE_WAITING_STEP: (state, value) => {
      state.step = value;
    },
    UPDATE_WAITING_IS_BUSINESS_HOURS: (state, value) => {
      state.isBusinessHours = value;
    },
    UPDATE_WAITING_SEACHER_RES: (state, value) => {
      state.waitingSearchRes = value;
    },
    UPDATE_WAITING_CHECK_RES: (state, {res, updateStatus}) => {
      state.waitingCheckRes = res;
      if (updateStatus) return state.step = res.status;
      if (state.step === 'success' && res.status === 'waiting') return;
      state.step = res.status;
    },
  },
  actions: {
    SET_WAITING_RES({ commit }, value) {
      commit("UPDATE_WAITING_RES", value);
    },
    SET_WAITING_STEP({ commit }, value) {
      commit("UPDATE_WAITING_STEP", value);
    },
    SET_WAITING_IS_BUSINESS_HOURS({ commit }, value) {
      commit("UPDATE_WAITING_IS_BUSINESS_HOURS", value);
    },
    SET_WAITING_SEACHER_RES({ commit }, value) {
      commit("UPDATE_WAITING_SEACHER_RES", value);
    },
    SET_WAITING_CHECK_RES({ commit }, {res, updateStatus}) {
      commit("UPDATE_WAITING_CHECK_RES", {res, updateStatus});
    },
  }
}

export default waiting;