export function setStorage(key, value, toStr) {
  if (toStr) {
    value = JSON.stringify(value);
  }
  if (value === undefined) return
  localStorage.setItem(key, value);
}

export function getStorage(key, toJson) {
  let getData = localStorage.getItem(key)
  return (toJson) ? JSON.parse(getData) : getData;
}

export function removerStorage(key) {
  localStorage.removeItem(key);
}