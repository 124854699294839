/**
 * NOTE 這隻檔案的用途
 * 點數兌換相關的資料邏輯
 * 
 * 1. 判斷 是否開啟「集點發券」的設定值 (有開啟才能呈現點數相關的功能)
 * 2. API GET 會員點數
 * 3. API GET 可兌換優惠券 / 可兌換贈品
 * 4. API POST 點數兌換優惠券
 * 5. UI 將可兌換的優惠券轉成 UI 呈現用的資料
 * 6. UI 選擇要兌換的贈品 / 兌換贈品的點數花費 / 重置贈品資料
 */

import apiPoint from '@/api/point';
import apiBranch from "@/api/branch";
import moment from 'moment';

function takeMethodString(data) {
  if (data.length === 3) return "不限";
  const takeMethodTextMap = new Map([
    [1, '外送'],
    [2, '外帶/自取'],
    [3, '內用'],
  ])

  return data.map(i => takeMethodTextMap.get(i)).join('、');
}
function conditionItemsString(condition) {
  if (!condition.menu_item) {
    if (condition.menu_item_condition === 0) return '不限';
    return '此分店未設定';
  }
  return condition.menu_item.map(e => e.name).join('、');
}
function redeemTypeString(isManualRedeem) {
  let typeString = '線上訂餐';
  if (isManualRedeem) {
    return `${typeString}/店面折抵`;
  } else {
    return typeString;
  }
}

// 點數兌換優惠券的資料轉型與定義 (結帳頁面)
function createAvailableTimeStringCheckout(data) {
  const { start_at, end_at } = data;
  const start = moment.unix(start_at).format('YYYY-MM-DD HH:mm');
  const end = moment.unix(end_at).format('YYYY-MM-DD HH:mm');

  return `${start} - ${end}`;
}
function generateMarketingCheckoutObj(data) {
  return {
    /** @type {Number} 店家id */
    storeId: data.store_id,

    /** @type {String} 適用分店 (店家券) */
    storeName: data.store_name,

    /** @type {Array} 適用分店 (品牌券) */
    aptStore: data.apt_store,

    /** @type {Number} 兌換id */
    exchangesId: data.exchanges_id,

    /** @type {String} 優惠券名稱 */
    title: data.title,

    /** @type {String} 優惠券說明 */
    description: data.description,

    /** @type {Object} 使用條件 */
    condition: data.condition,

    /** @type {String} 門檻描述 */
    conditionDescription: data.condition_description,

    /** 折扣商品 */
    conditionItemsString: conditionItemsString(data.condition),

    /**
     * @type {Number} 取餐方式
     * 1=外送
     * 2=外帶
     * 3=內用
     */
    takeMethod: data.available_pickup,
    takeMethodString: takeMethodString(data.available_pickup),

    /** @type {String} 規則備註 */
    remark: data.remark,

    /**
     * type: Number
     * 1: 可兌換
     * 2: 會員點數不足
     * 3: 兌換庫存不足
     * 4: 不符使用規則
     * */
    exchangeStatus: data.exchange_situation,

    /** @type {Boolean} 是否過期 */
    isExpired: data.is_expired,

    /** @type {Number} 庫存量 */
    stock: data.stock,

    /** @type {Number} 兌換所需點數 */
    costPoint: data.point,

    /** @type {String}1 開始時間（timestamp） */
    startTimestamp: data.start_at,

    /** @type {String} 結束時間（timestamp） */
    endTimestamp: data.end_at,

    availableTimeString: createAvailableTimeStringCheckout(data),

    /** @type {Boolean} 是否可手動核銷(應與c端無關) */
    isManualRedeem: data.is_manual_redeem,

    /** @type {String} 使用方式 */
    redeemTypeString: redeemTypeString(data.isManualRedeem),

    /** @type {Boolean} 條件是否符合規則 */
    isValid: data.is_valid,
  }
}

// 點數兌換優惠券的資料轉型與定義 (會員頁面)
function createAvailableTimeStringMember(data) {
  let { kind, kind_value, start_at, end_at } = data;
  if (kind === 2) return `領後${kind_value}日`;
  return `${start_at} - ${end_at}`;
}
function generateMarketingMemberObj(data) {
  return {
    /** @type {Number} 適用分店id（為0則為品牌) */
    storeId: data.store_id,

    /** @type {String} 適用分店 (店家券) */
    storeName: data.store_name,

    /** @type {Array} 適用分店 (品牌券) */
    aptStore: data.apt_store,

    /** @type {Number} 兌換id */
    exchanges_id: data.exchanges_id,

    /** @type {String} 名稱 */
    title: data.title,

    /** @type {String} 說明 */
    description: data.description,

    /** @type {Object} 使用條件 */
    condition: data.condition,

    /** @type {String} 門檻 */
    conditionDescription: data.condition_description,

    /** 折扣商品 */
    conditionItemsString: conditionItemsString(data.condition),

    /**
     * @type {Number} 取餐方式
     * 1=外送
     * 2=外帶
     * 3=內用
     */
    takeMethod: data.available_pickup,
    takeMethodString: takeMethodString(data.available_pickup),

    /** @type {Object} 獲得獎勵 */
    offer: data.offer,

    /** @type {Number} 折抵上限金額 */
    offerMaxValue: data.offer_max_value,

    /** @type {String} 規則備註 */
    remark: data.remark,

    /** @type {Number} 兌換狀態 */
    /**
     * 1: 可兌換;
     * 2: 點數不足;
     * 3: 已兌換完畢
     * */
    exchangeStatus: data.exchange_status,

    /** @type {Number} 庫存量 */
    stock: data.stock,

    /** @type {Number} 庫存量 */
    costPoint: data.point,

    /** @type {Boolean} 是否可重覆計算 */
    repeat: data.repeat,

    /** @type {String}1 開始時間（timestamp） */
    startTimestamp: data.start_at,

    /** @type {String} 結束時間（timestamp） */
    endTimestamp: data.end_at,

    availableTimeString: createAvailableTimeStringMember(data),

    kind: data.kind,

    kind_value: data.kind_value,

    /** @type {Boolean} checked-是否可以手動核銷 */
    isManualRedeem: data.is_manual_redeem,

    /** @type {String} 使用方式 */
    redeemTypeString: redeemTypeString(data.isManualRedeem),
  }
}


const pointExchange = {
  state: {
    isPointCouponEnable: false, // 集點發券
    points: 0,
    pointsData: {},
    costPoints: 0,
    exchangeCouponList: [],
    memberExchangeCouponList: [],
    exchangeFreebieList: [],
    selectedExchangeFreebie: [],
    exchangeFreebieModal: false,
  },
  mutations: {
    SET_POINT_COUPON_ENABLE: (state, payload) => {
      state.isPointCouponEnable = payload;
    },
    SET_MEMBER_POINTS: (state, payload) => {
      state.points = payload.total;
      state.pointsData = payload;
    },
    SET_POINTS_EXCHANGE_COUPON_DATA: (state, payload) => {
      state.exchangeCouponList = [...payload];
    },
    SET_POINTS_EXCHANGE_FREEBIE_DATA: (state, payload) => {
      state.exchangeFreebieList = payload.map((el, index) => {
        return {
          id: el.id,
          name: el.name,
          point: el.point,
          endDate: el.end_date,
          imgUrl: el.image,
          description: el.description,
          availableQuantity: el.current_qty,
          selectedCount: 0,
          itemIndex: index,
          pointTypeId: el.point_type_id,
        };
      });
    },
    SET_MEMBER_POINTS_EXCHANGE_COUPON_DATA: (state, payload) => {
      state.memberExchangeCouponList = [...payload];
    },
    SELECTED_EXCHANGE_FREEBIE: (state, payload) => {
      state.selectedExchangeFreebie = [...payload];
    },
    SELECTED_EXCHANGE_FREEBIE_POINTS: (state, payload) => {
      state.costPoints = payload;
    },
    RESET_EXCHANGE_FREEBIE: (state) => {
      state.selectedExchangeFreebie = [];
    },
    RESET_EXCHANGE_FREEBIE_POINTS: (state) => {
      state.costPoints = 0;
    },
    OPEN_EXCHANGE_FREEBIE_MODAL: (state) => {
      state.exchangeFreebieModal = true;
    },
    CLOSE_EXCHANGE_FREEBIE_MODAL: (state) => {
      state.exchangeFreebieModal = false;
    },
  },
  actions: {
    // [API] 取得「集點發券」設定值 (小開關 - 集點發券 - 點數模組)
    async GET_POINT_COUPON_ENABLE({ commit }, payload) {
      const res = await apiBranch.fetchBranchInfo(payload);
      const { is_point_coupon_point_enable: isPointCouponEnable } = res.data.response.list[0];
      commit("SET_POINT_COUPON_ENABLE", isPointCouponEnable);
    },
    // [API] 取得會員點數 （品牌/店家點數都適用)
    async GET_MEMBER_POINTS({ commit }, payload) {
      const res = await apiPoint.getMemberPoints(payload);
      commit("SET_MEMBER_POINTS", res.data.response);
    },
    // [API - 結帳頁面] 取得可兌換優惠券
    async GET_POINTS_EXCHANGE_COUPON_DATA({ commit }, payload) {
      const response = await apiPoint.postPointExchangeCouponList(payload);
      const data = response.data.response;
      const saveData = data.map(obj => generateMarketingCheckoutObj(obj))
      commit("SET_POINTS_EXCHANGE_COUPON_DATA", saveData);
    },
    // [API - 結帳頁面] 點數兌換優惠券
    async POST_POINTS_EXCHANGE_COUPON({ commit }, payload) {
      return await apiPoint.postPointExchangeCoupon(payload);
    },
    // [API - 會員頁面、結帳頁面] 取得可兌換贈品
    async GET_POINTS_EXCHANGE_FREEBIE_DATA({ commit }, payload) {
      const res = await apiPoint.getExchangeListByCompanyAndStore(payload);
      commit("SET_POINTS_EXCHANGE_FREEBIE_DATA", res.data.response);
    },
    // [API - 會員頁面] 取得可兌換優惠券
    async GET_MEMBER_POINTS_EXCHANGE_COUPON_DATA({ commit }, payload) {
      const dataArr = await apiPoint.getPointToCouponItems(payload);
      const data = dataArr.map(obj => generateMarketingMemberObj(obj))
      commit("SET_MEMBER_POINTS_EXCHANGE_COUPON_DATA", data);
    },
    // [Data - 結帳頁面] 選擇 要兌換的贈品
    SELECTED_EXCHANGE_FREEBIE({ commit }, payload) {
      commit("SELECTED_EXCHANGE_FREEBIE", payload);
    },
    // [Data - 結帳頁面] 選擇 要兌換贈品所花費的點數
    SELECTED_EXCHANGE_FREEBIE_POINTS({ commit }, payload) {
      commit("SELECTED_EXCHANGE_FREEBIE_POINTS", payload);
    },
    // [Data - 結帳頁面] 重置 要兌換的贈品
    RESET_EXCHANGE_FREEBIE({ commit }) {
      commit("RESET_EXCHANGE_FREEBIE");
    },
    // [Data - 結帳頁面] 重置 要兌換贈品所花費的點數
    RESET_EXCHANGE_FREEBIE_POINTS({ commit }) {
      commit("RESET_EXCHANGE_FREEBIE_POINTS");
    },
    // [UI - 結帳頁面] 開啟 兌換贈品的Modal
    OPEN_EXCHANGE_FREEBIE_MODAL({ commit }) {
      commit("OPEN_EXCHANGE_FREEBIE_MODAL");
    },
    // [UI - 結帳頁面] 關閉 兌換贈品的Modal
    CLOSE_EXCHANGE_FREEBIE_MODAL({ commit }) {
      commit("CLOSE_EXCHANGE_FREEBIE_MODAL");
    },
  },
};

export default pointExchange;
