import {
  OPENED_MODAL,
  CLOSED_MODAL,
  CLOSED_ALL_MODAL,
  SET_SHARE_MODE,
} from './mutationTypes'

const initialState = {
  opened: new Set(),
  selected: {
    item: {},
    set: {},
    cart: {},
    storeInfo: {},
    shareSetting: {},
    takeMethod: {},
    orderSetting: {},
  },
  mode: {
    shareSetting: "edit"
  }
};

const modalModule = {
  state: {
    ...initialState,
  },
  mutations: {
    [OPENED_MODAL](state, { name, data }) {
      state.opened = new Set([...state.opened, name]);
      state.selected[name] = data;
    },
    [CLOSED_MODAL](state, name) {
      state.opened = new Set([...state.opened].filter(x => x !== name));
      state.selected[name] = {};
    },
    [CLOSED_ALL_MODAL](state, name) {
      state.opened = new Set();
      state.selected = {
        item: {},
        set: {},
        cart: {},
        storeInfo: {},
        shareSetting: {},
        takeMethod: {},
        orderSetting: {},
      };
    },
    [SET_SHARE_MODE](state, mode) {
      state.mode.shareSetting = mode;
    },
  },
  actions: {
    openModal({ commit }, payload) {
      commit(OPENED_MODAL, payload);
    },
    closeModal({ commit }, name) {
      commit(CLOSED_MODAL, name);
    },
    closeAllModals({ commit }) {
      commit(CLOSED_ALL_MODAL);
    },
    setShareMode({ commit }, mode) {
      commit(SET_SHARE_MODE, mode);
    },
  },
};

export default modalModule;
