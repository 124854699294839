/**
 * 引入 共用函數
 */
import vuexStore from '@/store/index';
import Cookies from 'js-cookie';
import { getToken } from "@/utils/auth";
import { initLiff } from "@/utils/line";

const setConfig = async (companyParam) => {
  // 處理 Config
  // 判斷是否為 線上的運行環境
  if (process.env.NODE_ENV === "production") {
    const config = window.__imenu_config__;
    const company = window.__imenu_company_slug;
    // 設定 config 資料
    vuexStore.dispatch('setConfig', {
      ...config,
      company,
    });
  } else {
    // 判斷若 vuex 資料中無 config.id
    if (!vuexStore.getters.config.id) {
      // 透過 fetch 去撈資料回來
      await vuexStore.dispatch('fetchConfig', {
        company: companyParam
      });
    }
  }
}

const storeTableInCookie = (tableQuery) => {
  // 從 vuex 取出資料 id
  const { id } = vuexStore.getters.config;
  // 如果有桌號，就存入 Cookie
  if (tableQuery) {
    Cookies.set(`qr_table_company_${id}`, tableQuery);
  }
}


/**
 * 將路由的店家參數 設定進vuex，
 * @param storeParam
 * @param storeSlugQuery
 * @returns {{serviceDeny: Boolean, routeName: String}} Deny using the service, if so route to specify page.
 */
const setCurrentBranch = async (storeParam, storeSlugQuery) => {
  const currentBranch = vuexStore.getters.config.branches[storeParam] || vuexStore.getters.config.branches[storeSlugQuery];
  // 如果完全沒有店家資料，將 serviceDeny 設為 false
  if (!currentBranch) return { serviceDeny: false };
  // storeId 不一樣代表換店家了
  // currentBranch.id 是使用者點擊店家，因跳轉路由而改變 id
  // 由於 SPA 不會重新 load 資料，所以是透過 路由重新擷取資料 ---> currentBranch.id
  if (vuexStore.getters.storeId !== currentBranch.id) {
    const branch = await vuexStore.dispatch('fetchBranchInfo', {
      company_id: vuexStore.getters.companyId,
      store_id: currentBranch.id,
      // 標示是從 qrorder 環境來的
      from: 'QRORDER'
    });
    // ! fetchBranchInfo from=QRORDER api會過濾掉未開啟掃碼的分店
    // ! 目前先用 branch會拿到undefined 再去導頁
    if (!branch) {
      return { serviceDeny: true, routeName: "no-enable" };
    }

    vuexStore.dispatch('updateCurrentStore', {
      id: currentBranch.id,
      name: currentBranch.name,
      slug: storeParam || storeSlugQuery,
      // 相較於 imenu 多保存一個 qrcode
      qrcode: currentBranch.qrcode,
      google_api_key: currentBranch.google_api_key,
    });
  }
  return { serviceDeny: false };
}
/**
 * 初次載入時，若Cookie中的User Token存在，則換成information存入Vuex
 * @returns {undefined}
 */
const setLogIn = async () => {
  let { isLoggedIn } = vuexStore.getters;
  // 若已登入則跳出
  if (isLoggedIn) return;
  // 取得 Cookie 的 Token ，成功取得後執行 取得使用者資料
  getToken() && await vuexStore.dispatch('getUserDetail');
}

/**
 * 若Cookie中的桌號存在，將Cookie內的桌號存進Vuex
 */
const restoreTableFromCookie = () => {
  // 取出 vuex 桌號資料與 config 資料
  const { tables, config } = vuexStore.getters;
  // 取出 Cookies 的 table id
  const tableId = Cookies.get(`qr_table_company_${config.id}`);

  // has table id
  if (tableId) {
    // cookies table id 和 vuex tables 比對
    const theTable = tables.find(table => table.id === Number(tableId));
    // 比對成功 將 cookie 桌號保存進 vuex
    if (theTable) {
      vuexStore.dispatch('setTable', theTable);
    }
  }
}

const checkIsComanySlugValid = (companyParam) => {
  const isConfigValid = window.__imenu_config__ && window.__imenu_config__.id;
  const isCompanySlugValid = window.__imenu_company_slug;
  let result = Boolean(isConfigValid && isCompanySlugValid);

  // 運行環境是否為線上
  if (process.env.NODE_ENV !== 'production') {
    result = Boolean(companyParam);
  }
  return result;
}


const qrOrderProcess = async (to, from, next) => {
  // 從後端傳遞來的 路由的 params
  const { company: companyParam, store: storeParam } = to.params;
  // 有別於 imenu 多了 table 桌號
  const { table: tableQuery, store_slug: storeSlugQuery } = to.query;

  // 是否為大麥的商店
  const isComanySlugValid = checkIsComanySlugValid(companyParam);
  // 若不是 則導向 404
  if (!isComanySlugValid) return next({ name: '404' });
  // 保存資料 表示當前路由流程跑的是 QrOrder
  vuexStore.dispatch('setIsQrOrder', true);

  // 判斷是否為 liff 有無登入 / 初始化
  const isLIFF = await initLiff();
  // 保存liff 的狀態資料
  vuexStore.dispatch('setIsLIFF', isLIFF);
  // 保存 config 資料
  await setConfig(companyParam);
  // 保存桌號資料到 Cookie
  storeTableInCookie(tableQuery);

  let { serviceDeny, routeName } = await setCurrentBranch(storeParam, storeSlugQuery);
  // 如果商家為未啟用掃條碼狀態，則會導向該 no-enable 頁面
  if (serviceDeny) {
    return next(routeName);
  }
  // 內用後結：略過登入
  // 其他：可登入
  // branch.isPostCheckout
  // isPostCheckout ？ 內用後結 ： 內用先結
  let isPostCheckout = vuexStore.getters.isPostCheckout;
  // 倘若為內用先結，則會執行這個 登入的邏輯
  !isPostCheckout && await setLogIn(isLIFF);

  // 內用後結，結束後會跳轉到一個集合頁面 （唯獨內用後結有這個需求）
  if (window.__back_url__) {
    vuexStore.dispatch('setBackUrl', window.__back_url__);
  }

  restoreTableFromCookie();

  return next();
}



export default qrOrderProcess;