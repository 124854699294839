import Vue from 'vue';
import i18n from "@/plugin/i18n";
Vue.prototype.$I18n = i18n;
import languageOptions from "@/lang/langMapping"
import store from '@/store/index';

const getLangValue = (get_key) => {
  // get_key 對應要取得 @/lang/langMapping 中設定的 key
  let lang = '';
  languageOptions.map(key => {
    let now_i18n_locale = store.getters.user.lang || localStorage.getItem('language') || i18n.locale;
    if (key.value == now_i18n_locale) lang = key[get_key]
  })
  return lang
}




export default getLangValue;
