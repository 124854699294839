import Vue from "vue";
import Router from "vue-router";

import iMenuProcess from "@/router/modules/processes/iMenu";
import qrOrderProcess from "@/router/modules/processes/qrOrder";
import followProcess from "@/router/modules/processes/follow";
import sopowerProcess from "@/router/modules/processes/sopower";
import reserveProcess from "@/router/modules/processes/reserve";

import { errorRoutesSet, notMainProcessRoutesSet } from "@/router/modules/beforeEnteringMainProcess";
import { useRoutes } from "@/router/modules/useRoutes";

Vue.use(Router);

// Fix vue router NavigationDuplicated error
const originalPush = Router.prototype.push;
Router.prototype.push = function(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

const checkIsQrOrder = () => {
  let isQrOrder = window.__is_qr_order__;
  if (process.env.NODE_ENV !== "production") {
    isQrOrder = Boolean(process.env.VUE_APP_IS_QR_ORDER);
  }
  return isQrOrder;
};

const checkIsReserve = () => {
  let isReserve = window.__is_reserve__;
  if (process.env.NODE_ENV !== "production") {
    isReserve = Boolean(process.env.VUE_APP_IS_RESERVE);
  }
  return isReserve;
};

const checkIsFollow = followToken => {
  return Boolean(window.__followid__ || followToken);
};

const router = new Router({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (to.path === from.path) return savedPosition;
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: useRoutes(checkIsReserve()),
});

router.beforeEach(async (to, from, next) => {
  if (errorRoutesSet.has(from.name)) return;
  if (errorRoutesSet.has(to.name) || notMainProcessRoutesSet.has(to.name)) return next();

  // LIFF APP URL 是用 ?redirect 參數去控制他要走的頁面
  // redirect 參數是從 richmenu 那邊設定的
  // 於是在這邊進行 redirect 導向，應該導向 LIFF APP URL 設定的路由名稱
  let shouldRedirectPage = to.query.redirect || to.query.modal;
  if (shouldRedirectPage) {
    let redirectPage = to.query.modal ? "member" : to.query.redirect;
    if (to.meta.level === "store") {
      return next({
        name: `${redirectPage}.store`,
        params: { ...to.params }
      });
    }

    return next({
      name: `${redirectPage}.company`,
      params: { ...to.params }
    });
  }

  /**
   * 共4類流程：跟單、掃碼、iMenu、新電
   * PS: 跟單 雖然是iMenu的功能，但路由的流程將其獨立出來
   */
  const { followToken } = to.params;
  const isSopower =
    to.name === "sopower.company" || to.name === "sopower.store";
  const isQrOrder = checkIsQrOrder();
  const isReserve = checkIsReserve();
  const isFollow = checkIsFollow(followToken);
  const isImenu = !isQrOrder && !isFollow && !isReserve;

  // 進入 新電 路由流程
  if (isSopower) {
    return await sopowerProcess(to, from, next);
  }
  // imenu 路由流程
  if (isImenu) await iMenuProcess(to, from, next);
  // QRorder 路由流程
  if (isQrOrder) await qrOrderProcess(to, from, next);
  // follow 路由流程 --> 與 imenu & qrcode 最大差異是 流程：產品資料 > 商家資料 (品牌＆分店)
  if (isFollow) await followProcess(to, from, next);
  // reserve 路由流程
  if (isReserve) await reserveProcess(to, from, next);
  // Don't Write Any Code Below. We predict Router finished in process.
});

export default router;
