
import isAddressValid from "@/utils/address";

export function setTakeMethodLS({ companyId, takeMethodString }) {
  let key = `takeMethod:${companyId}`;
  localStorage.setItem(key, takeMethodString);
}

export function getTakeMethodLS(companyId) {
  let key = `takeMethod:${companyId}`;
  // LS回傳值會是 String or null
  // LS key
  let takeMethodKeyInLS = localStorage.getItem(key);
  return takeMethodKeyInLS
}

export function setAddressLS({ companyId, address }) {
  let key = `address:${companyId}`;
  localStorage.setItem(key, address);
}

export function getAddressLS({ companyId }) {
  let key = `address:${companyId}`;
  let address = localStorage.getItem(key);

  if (!isAddressValid(address)) {
    localStorage.removeItem(key);
    return {
      addressEmpty: true,
      address: ""
    }
  }
  return {
    addressEmpty: false,
    address
  }
}
