const errorRoutesSet = new Set([
  '404',
  'no-enable',
  'error',
  'timeout',
  'notice',
  'shutdown',
  'jko_maintenance'
])

const notMainProcessRoutesSet = new Set([
  'preview',
  'preview_pos',
  'jko_order_detail',
  'jko_before_authorize'
])

export {
  errorRoutesSet,
  notMainProcessRoutesSet
}
