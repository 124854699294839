// VISA: 4
// mastercard: 51-55
// UnionPay: 62
// JCB: 3528-3589
// Maestro: 5018, 5020, 5038, 6304, 6759, 6761, 6762, 6763
// American Express: 34, 37

function isVisa(first4Num) {
  return first4Num[0] === '4';
}
function isMastercard(first4Num) {
  const first2Num = parseInt(first4Num.slice(0, 2), 10);

  return first2Num >= 51 && first2Num <= 55;
}
function isMaestro(first4Num) {
  const res = first4Num === '5018'
    || first4Num === '5020'
    || first4Num === '5038'
    || first4Num === '6304'
    || first4Num === '6759'
    || first4Num === '6761'
    || first4Num === '6762'
    || first4Num === '6763';

  return res;
}
function isUnionPay(first4Num) {
  const first2Num = first4Num.slice(0, 2);

  return first2Num === '62';
}
function isJCB(first4Num) {
  const num = parseInt(first4Num, 10);

  return num >= 3528 && num <= 3589;
}
function isAXP(first4Num) {
  const num = parseInt(first4Num.slice(0, 2), 10);

  return num === 34 || num === 37;
}

function getCreditCardCompany(first6Num) {
  const first4Num = first6Num.slice(0, 4);

  if (isVisa(first4Num)) return 'visa';
  if (isMaestro(first4Num)) return 'maestro';
  if (isMastercard(first4Num)) return 'mastercard';
  if (isUnionPay(first4Num)) return 'unionpay';
  if (isJCB(first4Num)) return 'jcb';
  if (isAXP(first4Num)) return 'axp';

  return 'visa';
}

function getCreditCardString(first6Num, last4Num) {
  const first4Num = first6Num.slice(0, 4);
  const second2Num = first6Num.slice(4);

  const company = getCreditCardCompany(first6Num);

  switch (company) {
    case 'axp':
      return `${first4Num}-${second2Num}****-*${last4Num}`;
    default:
      return `${first4Num}-${second2Num}**-****-${last4Num}`;
  }
}

function getCardIconName(company) {
  switch (company) {
    case 'visa':
      return 'ic_creditcard_visa';
    case 'maestro':
      return 'ic_creditcard_maestro';
    case 'mastercard':
      return 'ic_creditcard_mastercard';
    case 'unionpay':
      return 'ic_creditcard_unionpay';
    case 'axp':
      return 'ic_creditcard_amex';
    default:
      return 'ic_creditcard_default';
  }
}


export default {
  getCreditCardString,
  getCreditCardCompany,
  getCardIconName,
};
