import checkout from '@/api/checkout';
import marketing from '@/api/marketing';
import user from '@/api/user';
import postCheckout from '@/api/postCheckout';
import point from '@/api/point';
import wallet from '@/api/wallet';
import waiting from '@/api/waiting';


export default {
  checkout,
  marketing,
  user,
  postCheckout,
  point,
  wallet,
  waiting
};
