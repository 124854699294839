import {
  FETCHED_CONFIG,
  UPDATED_CURRENT_STORE,
  SET_BACK_URL
} from './mutationTypes'
import config from '@/api/config';
import updateTheme from '@/utils/theme';

const appConfig = {
  state: {
    id: 0,
    name: '',
    businessName: '',
    slug: '',
    favicon: '',
    logo: 'logo.png',
    primaryColor: '',
    secondaryColor: '',
    Line: {
      channelId: '',
      channelSecret: '',
      callbackURL: '',
    },
    branches: {},
    qrcode: '',
    currentStore: {
      id: 0,
      name: '',
      slug: '',
      qrcode: '',
      googleApiKey: '',
    },
    isCompanyLevel: true,
    googleApiKey: '',
    isOnlineOrder: true,
    earnPointsType: 0,
    backUrl: '',
    is_birthday_require: null,
    is_asia_miles_enable: false,
  },
  mutations: {
    [FETCHED_CONFIG]: (state, payload) => {
      state.id = payload.id;
      state.name = payload.name;
      state.businessName = payload.business_name;
      state.slug = payload.company;
      state.favicon = payload.favicon;
      state.logo = payload.logo;
      state.primaryColor = payload.colors.primary;
      state.secondaryColor = payload.colors.secondary;
      state.Line = { ...payload.LINE };
      state.branches = { ...payload.branches };
      state.qrcode = payload.qrcode;
      state.isCompanyLevel = payload.isCompanyLevel;
      state.googleApiKey = payload.google_api_key;
      state.isOnlineOrder = payload.is_online_order;
      state.earnPointsType = payload.earn_points_type;
      state.isCompanyWalletEnable = payload.is_wallet_enable;
      state.isLineNotice = payload.is_line_notice; // [暫時的]Line OA 公告用的顯示判斷
      state.is_birthday_require = payload.is_birthday_require;
      state.is_asia_miles_enable = payload.is_asia_miles_enable;
    },
    [UPDATED_CURRENT_STORE]: (state, payload) => {
      state.currentStore.id = payload.id;
      state.currentStore.name = payload.name;
      state.currentStore.slug = payload.slug;
      state.currentStore.qrcode = payload.qrcode;
      state.currentStore.googleApiKey = payload.google_api_key;
    },
    [SET_BACK_URL]: (state, payload) => {
      state.backUrl = payload;
    },
  },
  actions: {
    async fetchConfig({ commit, getters }, {
      company, store
    }) {
      try {
        const response = await config.fetch({
          company, store
        });
        const res = response.data.response[company];

        const { colors } = res;

        if (colors.primary) {
          updateTheme(colors.primary, colors.secondary);
        }

        if (getters.isQrOrder) {
          res.isCompanyLevel = false;
        }

        commit(FETCHED_CONFIG, {
          ...res,
          company,
        });

        return res;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    setConfig({ commit, getters }, payload) {
      const { colors } = payload;
      if (colors.primary) {
        updateTheme(colors.primary, colors.secondary);
      }
      if (getters.isQrOrder) {
        payload.isCompanyLevel = false;
      }
      commit(FETCHED_CONFIG, payload);
    },
    updateCurrentStore({ commit }, payload) {
      commit(UPDATED_CURRENT_STORE, payload);
    },
    setBackUrl({ commit, getters }, payload) {
      commit(SET_BACK_URL, payload);
    }
  },
};

export default appConfig;
