// import { Base64 } from 'js-base64';
import Cookies from 'js-cookie';
import sha1 from 'sha1';
import md5 from 'md5';

import store from '@/store';

const key = '87:20:F7:5F:8F:9';

// export const appToken = Base64.encode(sha1(key));
export const appToken = "NzJhMDZiMzE0NWU0NDlkMGY0ZDMzYTJiMTE5OTYzMGQ0YmU1M2M1ZA==";

export const generateApiToken = (string) => {
  const data = string + key;
  return md5(sha1(data));
};

export function getToken() {
  let TokenKey = `imenu_lt_${store.getters.companyId}`;
  if (!store.getters.isCompanyLevel) {
    TokenKey = `imenu_lt_${store.getters.companyId}_${store.getters.storeId}`;
  }
  return Cookies.get(TokenKey);
}

export function setToken(token, expiresTime = 3) {
  let TokenKey = `imenu_lt_${store.getters.companyId}`;
  if (!store.getters.isCompanyLevel) {
    TokenKey = `imenu_lt_${store.getters.companyId}_${store.getters.storeId}`;
  }
  return Cookies.set(TokenKey, token, { expires: expiresTime });
}

export function removeToken() {
  let TokenKey = `imenu_lt_${store.getters.companyId}`;
  if (!store.getters.isCompanyLevel) {
    TokenKey = `imenu_lt_${store.getters.companyId}_${store.getters.storeId}`;
  }
  return Cookies.remove(TokenKey);
}
