// 在 purchaser_list 塞入 isEdited hasEditedItem isShowCart 紀錄當前品項有沒有被異動
export const addIsEditedToPurchaserList = (purchaserList, isEdited, isShowCart) => {
  const formatPurchaserList = purchaserList.map((list) => {
    let newList = JSON.parse(JSON.stringify(list));

    // 用來記錄有沒有品項被異動
    newList.hasEditedItem = isEdited;
    newList.isShowCart = isShowCart

    if (Array.isArray(newList.item_list.items)) {
      newList.item_list.items = newList.item_list.items.map((item) => {
        return { ...item, isEdited };
      });
    }

    if (Array.isArray(newList.item_list.sets)) {
      newList.item_list.sets = newList.item_list.sets.map((set) => {
        return { ...set, isEdited };
      });
    }
    return newList; // 返回修改後的 newList
  });

  return formatPurchaserList;
}
// 更新 isEdited hasEditedItem isShowCart
export const updateIsEditedToPurchaserList = (purchaserList, ids, isShowCart = false) => {
  const formatPurchaserList = purchaserList.map((list) => {
    let newList = JSON.parse(JSON.stringify(list));

    newList.hasEditedItem = true;
    newList.isShowCart = isShowCart

    const items = newList.item_list.items;
    const sets = newList.item_list.sets;

    items.forEach((item) => {
      if (ids.includes(item.order_menu_detail_id)) {
        item.isEdited = true;
      }
    })

    sets.forEach((set) => {
      if (ids.includes(set.order_menu_detail_id)) {
        set.isEdited = true;
      }
    })

    return newList


  });
  return formatPurchaserList;
}

export const handleDeletedItems = (context, deletedIds, isShowCart, updateMethod) => {
  const purchaserList = context.purchaser_list;
  const formatPurchaserList = updateIsEditedToPurchaserList(
    purchaserList,
    deletedIds,
    isShowCart
  );
  updateMethod(formatPurchaserList);

}