import API from "@/api/wallet";


const walletInfoDataAdapter = (data) => {
  return {
    email: data.email,
    carrierId: data.default_carrier_id,
    citizenId: data.default_citizen_id,
    bIdentifier: data.default_b_identifier,
    donateNo: data.default_donate_no,
    invoiceType: data.invoice?.type,
    invoiceValue: data.invoice?.value,
    deposit: data.deposit,
    expiredAt: data.expired_at,
    expiredTotal: data.expired_total,
    total: data.total,
    rewardPoint: data.reward_point,
  };
}

const wallet = {
  state: {
    walletModal: false,
    walletInvoiceSettingModal: false,
    walletInfoData: null,
    useWalletMoney: 0, // 要使用的儲值金
    inputWalletMoney: 0, // 輸入框的儲值金
  },
  mutations: {
    HANDLE_MODAL: (state, payload) => {
      const { key, state: modalState } = payload;
      state[key] = modalState;
    },
    SAVE_WALLET_INFO_DATA: (state, payload) => {
      state.walletInfoData = walletInfoDataAdapter(payload);
    },
    SET_USE_WALLET_MONEY: (state, payload) => {
      state.useWalletMoney = payload
    },
    SET_INPUT_WALLET_MONEY: (state, payload) => {
      state.inputWalletMoney = payload
    },
  },
  actions: {
    /**
     * @param {string} type Modal 類型
     * @param {boolean} state 
     */
    HANDLE_MODAL({ commit }, payload) {
      commit("HANDLE_MODAL", payload);
    },
    /**
     * @param {string} type
     * @param {object} data 
     */
    SAVE_WALLET_INFO_DATA({ commit }, payload) {
      commit("SAVE_WALLET_INFO_DATA", payload);
    },
    SET_USE_WALLET_MONEY({ commit }, payload) {
      commit("SET_USE_WALLET_MONEY", payload);
    },
    SET_INPUT_WALLET_MONEY({ commit }, payload) {
      commit("SET_INPUT_WALLET_MONEY", payload);
    },
  }
};

export default wallet;
