import {
  CALL_API_CHECKOUT_SUCCESS,
} from './mutationTypes'
import API from '@/api/checkout';
import { Checkout } from '@/models/Checkout';

const checkout = {
  state: {
    checkout: new Checkout(),
    order_amount_payable: 0,
    result: {},
    shipping_fee_data: {},
    delivery_insufficient_value: 0,
  },
  mutations: {
    SET_CHECKOUT: (state, newCheckout) => {
      state.checkout = newCheckout;
    },
    RESET_CHECKOUT: (state) => {
      state.checkout = new Checkout();
    },
    [CALL_API_CHECKOUT_SUCCESS]: (state, result) => {
      state.result = { ...result };
    },
    UPDATE_ORDER_AMOUNT_PAYABLE: (state, amount) => {
      state.order_amount_payable = amount;
    },
    UPDATE_SHIPPING_FEE: (state, Res) => {
      state.shipping_fee_data = Res;
    },
    RESET_SHIPPING_FEE: (state) => {
      state.shipping_fee_data = {};
    },
    UPDATE_INSUFFICIENT_VALUE: (state, value) => {
      state.delivery_insufficient_value = value;
    }
  },
  actions: {
    setCheckout({ commit }, newCheckout) {
      commit('SET_CHECKOUT', newCheckout);
    },
    resetCheckout({ commit }) {
      commit('RESET_CHECKOUT');
    },
    // POST /store/{store_id}/order_menu/{order_menu_id}/checkout 結帳
    async callApiCheckoutAction({ commit }, {
      store_id,
      order_menu_id,
      data,
    }) {
      const response = await API.callApiCheckout({
        store_id,
        order_menu_id,
        data,
      });
      return response;
    },
    updateOrderAmountPayable({commit}, amount) {
      commit('UPDATE_ORDER_AMOUNT_PAYABLE', amount);
    },
    async callApiShippingFee({commit}, data) {
      commit('UPDATE_INSUFFICIENT_VALUE', 0);
      try {
        const { data: Res } = await API.shippingFee(data);
        commit('UPDATE_SHIPPING_FEE', Res.response);
        return Res.response;
      } catch (error) {
        let { code, detail } = error.data;
        if (code == 40108) {
          let splitDetailText = detail.split('(')[1];
          let getText = splitDetailText.split(')')[0];
          getText = getText.split('還差')[1];
          let getValue = getText.includes('元') ? getText.split('元')[0] : getText.split('件')[0];
          commit('UPDATE_INSUFFICIENT_VALUE', getValue);
        }
      }
    },
    reSetShippingFeeData({commit}) {
      commit('RESET_SHIPPING_FEE');
    }

  },
};

export default checkout;
