import { FETCHED_MENU, RESET_MENU } from "./mutationTypes";

import menu from "@/api/menu";

import { getSetDetailType } from "@/utils/menu/menuSet";

// POS 環境 (Output: Boolean)
const isPOS = () => {
  const url = window.location.href;
  const regex = /preview.pos/g;
  return regex.test(url);
};

// 隱藏過濾
const isNotHidden = (i) => !i.hidden;

// iMenu / POS 預覽的 hidden 判斷 (Output: Boolean)
const isPreviewNotHidden = (i) => {
  if (isPOS() && !i.pos_hidden) {
    return true;
  } else {
    return !i.hidden;
  }
};
// iMenu / POS 預覽的 category_hidden 判斷 (Output: Boolean)
const isPreviewNotCategoryHidden = (i) => {
  if (isPOS() && !i.pos_hidden) {
    return true;
  } else {
    return !i.category_hidden;
  }
};
// iMenu / POS 預覽的附加選項 hidden 判斷 (Output: Boolean)
const isPOSPreviewNotHidden = (i) => {
  if (isPOS()) {
    return true;
  } else {
    return !i.hidden;
  }
};

// 餐點份量 b = base_price
const ItemBasePrice = (b) => ({
  id: b.id,
  name: b.title,
  price: b.price,
  selected: b.selected,
  stock: b.stock,
  isSold: b.sold_status === 2,
  isTodaySold: b.sold_status === 9,
});
// 餐點附加選項 a = attribute
const ItemAttributes = (a) => ({
  id: a.id,
  name: a.title,
  price: a.extra_price,
  selected: a.selected,
  isSold: a.sold_status === 2,
  isTodaySold: a.sold_status === 9,
});
// 餐點附加選項 o = option
const ItemOptions = (o) => {
  const attributes = o.attribute.filter(isNotHidden).map(ItemAttributes);
  let selectedAttributeId = 0;
  let selectedAttributeIds = [];
  if (o.is_multiple) {
    attributes.forEach((a) => {
      if (a.selected && !a.isSold && !a.isTodaySold)
        selectedAttributeIds.push(a.id);
    });
  } else {
    const selectedAttribute = attributes.find((a) => a.selected);
    if (
      selectedAttribute &&
      selectedAttribute.id &&
      !selectedAttribute.isSold &&
      !selectedAttribute.isTodaySoldf
    ) {
      // 單選必填
      if (o.required) {
        selectedAttributeId = selectedAttribute.id;
      } else {
        // 單選非必填
        selectedAttributeIds.push(selectedAttribute.id);
      }
    }
  }

  return {
    id: o.unit_id,
    name: o.unit_title,
    required: o.required,
    is_multiple: o.is_multiple,
    attributes,
    selectedAttributeId,
    selectedAttributeIds,
  };
};
// 餐點資料 i = item
const MenuItemData = (i) => {
  // 份量
  const base_prices = i.base_price.map(ItemBasePrice);
  const selectedBasePrice = i.base_price.find((b) => b.selected);

  let selectedBasePriceId = 0;
  if (
    selectedBasePrice &&
    selectedBasePrice.sold_status !== 2 &&
    selectedBasePrice.sold_status !== 9
  ) {
    selectedBasePriceId = selectedBasePrice.id;
  }
  // 附加選項
  const options = i.option.filter(isNotHidden).map(ItemOptions);

  // 顯示備註預設為true
  let isShowComment = true;
  if (i.is_show_comment !== undefined) {
    isShowComment = i.is_show_comment;
  }

  return {
    id: i.id,
    name: i.title,
    description: i.description,
    img_url: i.img_url,
    tags: i.tags,
    tax_type: i.tax_type,
    is_participated_point: i.is_participated_point,
    base_prices,
    options,
    qty: 1,
    comment: "",
    owner: "",
    is_set: false,
    selectedBasePriceId,
    isSold: i.sold_status === 2,
    isTodaySold: i.sold_status === 9,
    isShowComment,
  };
};
// 套餐份量 b = base_price
const SetBasePrice = (b) => ({
  price: b.price,
  points: b.points,
});
// 套餐子類別 d = detail
const SetDetail = (d) => {
  let items = d.items.filter(isNotHidden).map(MenuItemData);
  const type = getSetDetailType(d);
  let selectedItems = [];

  // 子類別可選擇1個，任一項目有需要選擇的份量/附加選項
  // 暴力法攤平，用個預設 Array 來綁定 UI model
  if (type === "multipleWithAttributes") {
    for (let i = 0; i < d.limit_qty; i++) {
      selectedItems[i] = { id: 0 };
    }
  }

  // 迎合 UI 需求，「子類別可選擇2個以上（－N＋），且所有項目都沒有可選份量/附加選項」
  // 此類型內品項必須沒有預設 qty，因為 UI 預設 0
  if (type === "multiple" || type === "singleChoice") {
    items = items.map((i) => {
      return {
        ...i,
        qty: 0,
      };
    });
  }

  let isRequired = true;

  if (d.is_required !== undefined) {
    isRequired = d.is_required;
  }

  let isRepeat = true;

  if (d.is_repeat !== undefined) {
    isRepeat = d.is_repeat;
  }

  return {
    type,
    items,
    id: d.id,
    name: d.name,
    limit_qty: d.limit_qty,
    is_required: isRequired,
    is_repeat: isRepeat,
    has_extra_price: d.has_extra_price,
    benchmark_price: d.benchmark_price,
    selectedItemId: 0,
    selectedItemIds: [],
    selectedItems,
  };
};
// 套餐餐點資料 s = set
const MenuSetData = (s) => {
  // 份量
  const base_prices = s.base_price.map(SetBasePrice) || [];
  // 附加選項
  const options = s.option.filter(isNotHidden).map(ItemOptions) || [];
  // 子類別
  const detail = s.detail.map(SetDetail) || [];

  // 顯示備註預設為true
  let isShowComment = true;
  if (s.is_show_comment !== undefined) {
    isShowComment = s.is_show_comment;
  }

  return {
    id: s.id,
    name: s.title,
    description: s.description,
    img_url: s.img_url,
    tags: s.tags,
    tax_type: s.tax_type,
    is_participated_point: s.is_participated_point,
    base_prices,
    options,
    detail,
    qty: 1,
    comment: "",
    owner: "",
    is_set: true,
    stock: s.stock,
    isSold: s.sold_status === 2,
    isTodaySold: s.sold_status === 9,
    isShowComment,
  };
};

const initialState = {
  categories: [],
  version: "",
};

// 預覽 餐點附加選項 o = option
const previewItemOptions = (o) => {
  const attributes = o.attribute
    .filter(isPOSPreviewNotHidden)
    .map(ItemAttributes);
  let selectedAttributeId = 0;
  let selectedAttributeIds = [];
  if (o.is_multiple) {
    attributes.forEach((a) => {
      if (a.selected && !a.isSold && !a.isTodaySold)
        selectedAttributeIds.push(a.id);
    });
  } else {
    const selectedAttribute = attributes.find((a) => a.selected);
    if (
      selectedAttribute &&
      selectedAttribute.id &&
      !selectedAttribute.isSold &&
      !selectedAttribute.isTodaySold
    ) {
      // 單選必填
      if (o.required) {
        selectedAttributeId = selectedAttribute.id;
      } else {
        // 單選非必填
        selectedAttributeIds.push(selectedAttribute.id);
      }
    }
  }

  return {
    id: o.unit_id,
    name: o.unit_title,
    required: o.required,
    is_multiple: o.is_multiple,
    attributes,
    selectedAttributeId,
    selectedAttributeIds,
  };
};
// 預覽 餐點資料 i = item
const previewMenuItemData = (i) => {
  // 份量
  const base_prices = i.base_price.map(ItemBasePrice);
  const selectedBasePrice = i.base_price.find((b) => b.selected);

  let selectedBasePriceId = 0;
  if (
    selectedBasePrice &&
    selectedBasePrice.sold_status !== 2 &&
    selectedBasePrice.sold_status !== 9
  ) {
    selectedBasePriceId = selectedBasePrice.id;
  }
  // 附加選項
  const options = i.option
    .filter(isPOSPreviewNotHidden)
    .map(previewItemOptions);

  // 顯示備註預設為true
  let isShowComment = true;
  if (i.is_show_comment !== undefined) {
    isShowComment = i.is_show_comment;
  }

  return {
    id: i.id,
    name: i.title,
    description: i.description,
    img_url: i.img_url,
    tags: i.tags,
    tax_type: i.tax_type,
    is_participated_point: i.is_participated_point,
    base_prices,
    options,
    qty: 1,
    comment: "",
    owner: "",
    is_set: false,
    selectedBasePriceId,
    isSold: i.sold_status === 2,
    isTodaySold: i.sold_status === 9,
    isShowComment,
  };
};
// 預覽 套餐子類別 d = detail
const previewSetDetail = (d) => {
  let items = d.items.filter(isPreviewNotHidden).map(previewMenuItemData);
  const type = getSetDetailType(d);
  let selectedItems = [];

  // 子類別可選擇1個，任一項目有需要選擇的份量/附加選項
  // 暴力法攤平，用個預設 Array 來綁定 UI model
  if (type === "multipleWithAttributes") {
    for (let i = 0; i < d.limit_qty; i++) {
      selectedItems[i] = { id: 0 };
    }
  }

  // 迎合 UI 需求，「子類別可選擇2個以上（－N＋），且所有項目都沒有可選份量/附加選項」
  // 此類型內品項必須沒有預設 qty，因為 UI 預設 0
  if (type === "multiple" || type === "singleChoice") {
    items = items.map((i) => {
      return {
        ...i,
        qty: 0,
      };
    });
  }

  let isRequired = true;

  if (d.is_required !== undefined) {
    isRequired = d.is_required;
  }

  let isRepeat = true;

  if (d.is_repeat !== undefined) {
    isRepeat = d.is_repeat;
  }

  return {
    type,
    items,
    id: d.id,
    name: d.name,
    limit_qty: d.limit_qty,
    is_required: isRequired,
    is_repeat: isRepeat,
    has_extra_price: d.has_extra_price,
    benchmark_price: d.benchmark_price,
    selectedItemId: 0,
    selectedItemIds: [],
    selectedItems,
  };
};
// 預覽 套餐餐點資料 s = set
const previewMenuSetData = (s) => {
  // 份量
  const base_prices = s.base_price.map(SetBasePrice) || [];
  // 附加選項
  const options =
    s.option.filter(isPOSPreviewNotHidden).map(previewItemOptions) || [];
  // 子類別
  const detail = s.detail.map(previewSetDetail) || [];

  // 顯示備註預設為true
  let isShowComment = true;
  if (s.is_show_comment !== undefined) {
    isShowComment = s.is_show_comment;
  }

  return {
    id: s.id,
    name: s.title,
    description: s.description,
    img_url: s.img_url,
    tags: s.tags,
    tax_type: s.tax_type,
    is_participated_point: s.is_participated_point,
    base_prices,
    options,
    detail,
    qty: 1,
    comment: "",
    owner: "",
    is_set: true,
    stock: s.stock,
    isSold: s.sold_status === 2,
    isTodaySold: s.sold_status === 9,
    isShowComment,
  };
};

const menuModule = {
  state: {
    ...initialState,
  },
  mutations: {
    [FETCHED_MENU](state, { categories, version }) {
      state.categories = categories;
      state.version = version;
    },
    [RESET_MENU](state) {
      state.categories = [];
      state.version = "";
    },
  },
  actions: {
    resetMenu({ commit }) {
      commit(RESET_MENU);
    },
    async fetchMenu({ commit, getters }, storeId) {
      const date =
        getters.pickUpDateInUse && getters.pickUpDateInUse.date_index;
      const time = getters.pickUpTimeInUse && getters.pickUpTimeInUse.index;
      let checkTime = `${date} ${time}`;

      if (!date || !time) checkTime = null;

      if (checkTime && checkTime.includes("盡快")) {
        const now = new Date();
        let hours = now.getHours();
        let minutes = now.getMinutes();
        // 個位數補 0
        hours = `0${hours}`.slice(-2);
        minutes = `0${minutes}`.slice(-2);
        checkTime = `${date} ${hours}:${minutes}`;
      }
      const response = await menu.fetchMenu({
        storeId,
        isQrOrder: getters.isQrOrder,
        pickup_type: getters.takeMethodInUse.key,
        prefer_datetime: checkTime,
      });
      if (!response.data.response || response.data.response.length < 1) {
        return commit(RESET_MENU);
      }
      const usefulMenu = response.data.response.data.filter(
        (e) => !e.category_hidden
      );
      const version = response.data.response.version;
      const categories = usefulMenu.map((category) => {
        // 一般餐點資料
        const items = category.items.filter(isNotHidden).map(MenuItemData);
        // 套餐餐點資料
        const sets = category.sets.filter(isNotHidden).map(MenuSetData);
        return {
          name: category.category_name,
          description: category.category_description,
          sets,
          items,
          id: category.id,
        };
      });

      commit(FETCHED_MENU, {
        categories,
        version,
      });
    },
    async fetchPreviewMenu({ commit, getters }, { companyId, storeId }) {
      const response = await menu.fetchPreviewMenu({
        companyId,
        storeId,
      });
      if (!response.data.response || response.data.response.length < 1) {
        return commit(RESET_MENU);
      }
      const usefulMenu = response.data.response.data.filter(
        isPreviewNotCategoryHidden
      );
      const version = response.data.response.version;
      const categories = usefulMenu.map((category) => {
        // 一般餐點資料
        const items = category.items
          .filter(isPreviewNotHidden)
          .map(previewMenuItemData);
        // 套餐餐點資料
        const sets = category.sets
          .filter(isPreviewNotHidden)
          .map(previewMenuSetData);
        return {
          name: category.category_name,
          description: category.category_description,
          sets,
          items,
        };
      });

      commit(FETCHED_MENU, {
        categories,
        version,
      });
    },
  },
};
export default menuModule;
