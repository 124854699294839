import API from '@/api/jko';
import vuexStore from '@/store/index';
import { MessageBox } from 'element-ui';
import { setToken } from "@/utils/auth";
import Cookies from 'js-cookie';
import { setSS } from "@/utils/sessionStorage";
import i18n from "@/plugin/i18n";
// import * as Sentry from "@sentry/vue";

// export const sentryCaptureMessage = (msg) => {
//   Sentry.captureMessage(msg);
// }

// /** 重新整理、點擊或直接寫入連結、關閉頁面就移除 JKO Cookie */
// export const beforeUnloadRemoveToken = () => {
//   window.onbeforeunload = () => {
//     removeToken();
//   };
// }

/** 前往 JKO 維護頁面的 URL */
const getJkoMaintenanceUrl = () => {
  return process.env.VUE_APP_IMENU_BASE_URL + '/jko/maintenance';
}

/** 判斷是否為JKO */
export const checkIsJko = () => {
  return Cookies.get("IS_JKO");
}

/** JKO 在 API Response 的 Error Handle (200、400以外的都丟去維護頁面) */
export const jkoErrorHandle = (status, response, message) => {
  switch (status) {
    case 400:
      Cookies.remove(`order_${vuexStore.getters.storeId}`);
      const box = document.querySelector("#announceUpdatedAt");
      box && box.remove();

      MessageBox({
        title: i18n.t('Lang.System_error.utils.JKO.something_went_wrong'),
        message,
        showCancelButton: false,
        confirmButtonText: i18n.t('Lang.Category.button.submit'),
      }).then(() => {
        window.location.reload();
      }).catch(() => {
        window.location.reload();
      });
      break;
    default:
      window.location.href = getJkoMaintenanceUrl();
  }
};

/** 拿 auth_code 換取 login token */
async function getThirdPartyLoginToken(platform, auth_code, store_id) {
  const obj = {
    platform,
    auth_code,
    store_id
  }

  try {
    const res = await API.getLoginToken(obj);
    return res.data.response.login_token;
  } catch (error) {
    window.location.href = getJkoMaintenanceUrl();
  }
}

// ------------------------------ JKO SDK API ------------------------------

/** setNavTitle */
export function useSetNavTitle(title = i18n.t('Lang.System_error.utils.JKO.takeout_delivery')) {
  const data = { title };

  jkos.setNavTitle(data);
}

/** setNavLeftButton */
export function useSetNavLeftButton() {
  const data = {
    type: "2", // 所有頁面都以「x」呈現
    confirm: {
      title: i18n.t('Lang.System_error.utils.JKO.leave_ask_title'),
      content: i18n.t('Lang.System_error.utils.JKO.leave_ask_message')
    }
  }

  jkos.setNavLeftButton(data);
}

/** 拿 Client ID 換取 auth_code */
export async function getJkoAuthCode() {
  const { client_id: clientId, access_scopes: scopes } = window.__imenu_config__.jko;

  await jkos.getAuthCode({
    clientId,
    scopes,
    onSuccess,
    onError,
    onRejectAuth
  }, handleJkoSdkError);
}


// -------------------- Handle JKO SDK API Callback --------------------

async function onSuccess(authCode) {
  // 清除 JKO 授權相關畫面
  vuexStore.dispatch('CLEAR_JKO_VIEW');

  // 取得並保存 JKO login_token
  const storeId = vuexStore.getters.jkoStoreId;
  const loginToken = await getThirdPartyLoginToken('jko', authCode, storeId);
  const inThirtyMinutes = new Date(new Date().getTime() + 30 * 60 * 1000);
  setToken(loginToken, inThirtyMinutes);

  // 用來判斷是否完成授權流程
  setSS('JKO_AUTHORIZED', true)

  // 回到原本 JKO 進來的 Url Path
  const originUrl = process.env.VUE_APP_IMENU_BASE_URL + vuexStore.getters.jkoOriginPath;
  window.location.href = originUrl;
}

function onError(e) {
  window.location.href = getJkoMaintenanceUrl();
}

function onRejectAuth(e) {
  vuexStore.dispatch('SET_JKO_VIEW', 'AuthorizeAgain');
}

function handleJkoSdkError(e) {
  if (e.error === 0) return; // 操作成功，沒有出任何錯誤
  window.location.href = getJkoMaintenanceUrl();
}
