import axios from 'axios';
import domain from './domain';
import http from '@/utils/http';
import { appToken } from '@/utils/auth';
import getI18nKey from './getLangValue';

export default {
  fetchMenu({
    storeId,
    isQrOrder,
    pickup_type,
    prefer_datetime,
  }) {
    const t = Date.now()
    const from = isQrOrder ? "QRORDER" : "IMENU";
    let url = `${domain.new}/store/${storeId}/menu?from=${from}&t=${t}`;
    if (pickup_type) {
      url = `${domain.new}/store/${storeId}/menu?pickup_type=${pickup_type}&from=${from}&t=${t}`;
    }
    if (pickup_type && prefer_datetime) {
      url = `${domain.new}/store/${storeId}/menu?pickup_type=${pickup_type}&prefer_datetime=${prefer_datetime}&from=${from}&t=${t}`;
    }
    return http.get(url, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
  fetchPreviewMenu({
    companyId,
    storeId,
  }) {
    const url = `${domain.admin}/api/company/${companyId}/store/${storeId}/menu/preview`
    return axios.get(url, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
}
