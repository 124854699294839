// 計算品項預設總價錢（從菜單資料來的預設價錢，含預選選項價錢）
// i = item
export const calculateItemDefaultPrice = i => {
  const base = i.base_prices.reduce((accumulator, current) => {
    if (current.selected) return accumulator + current.price;
    return accumulator;
  }, 0);

  const optionsPrice = i.options.reduce((accumulator, current) => {
    current.attributes.forEach(a => {
      if (a.selected) accumulator += a.price;
    });
    return accumulator;
  }, 0);

  return (base + optionsPrice) * i.qty;
}

// 計算品項總價錢 i = item
export const calculateItemPrice = i => {
  const base = i.base_prices && i.base_prices.reduce((accumulator, current) => {
    if (current.id === i.selectedBasePriceId) return current.price;
    return accumulator;
  }, 0);

  const optionsPrice = i.options && i.options.reduce((accumulator, current) => {
    // 多選
    if (current.is_multiple) {
      current.attributes.forEach(a => {
        if (current.selectedAttributeIds.includes(a.id)) {
          accumulator += a.price;
        }
      });
    } else {
      // 單選必填
      if (current.required) {
        current.attributes.forEach(a => {
          if (a.id === current.selectedAttributeId) {
            accumulator += a.price;
          }
        });
      } else {
        // 單選選填寫
        current.attributes.forEach(a => {
          if (current.selectedAttributeIds.includes(a.id)) {
            accumulator += a.price;
          }
        });
      }
    }
    return accumulator;
  }, 0);

  return (base + optionsPrice) * i.qty;
}

// 計算品項剩餘未選取選項數量
// i = item
export const calculateItemRemainRequiredOptionCount = i => {
  const count = i.options.reduce((accumulator, current) => {
    if (current.required) {
      if (current.attributes.every(a => !a.selected)) {
        accumulator += 1;
      }
    }
    return accumulator;
  }, 0);
  return count;
}

// 判斷是否所有必填選項都已經選取(item)
export const isEveryRequiredOptionSelected = (item) => {
  const passBaseprice = Boolean(item.selectedBasePriceId);
  const passOption = item.options && item.options.every(o => {
    if (!o.required) return true;
    if (o.is_multiple) {
      return o.selectedAttributeIds.length > 0;
    } else {
      return Boolean(o.selectedAttributeId);
    }
  });
  return passBaseprice && passOption;
};

// 取得品項所有選取的附加選項 id
export const getItemSelectedAttributeIds = (item) => {
  let ids = [];

  if (!item.options) return [];

  item.options.forEach(option => {
    if (option.is_multiple) {
      ids.push(...option.selectedAttributeIds);
    } else {
      if (!option.required) {
        ids.push(...option.selectedAttributeIds);
      } else {
        ids.push(option.selectedAttributeId);
      }
    }
  });
  return ids.filter(id => id > 0);
}

export const getItemSelectedBasePriceString = (item, nameOnly) => {
  if (!item.base_prices) return;
  const b = item.base_prices.find(b => b.id === item.selectedBasePriceId);
  if (!b) return ``;
  if (nameOnly) return `${b.name}`;
  if (b.price === 0) return ``;
  if (b.price < 0) return `-$${-(b.price)}`;
  return `+$${b.price}`;
}

export const getItemSelectedAtrributesString = (item) => {
  const result = [];
  const ids = getItemSelectedAttributeIds(item);

  if (item.options) {
    item.options.forEach(option => {
      option.attributes.forEach(attribute => {
        if (ids.includes(attribute.id)) {
          if (attribute.price === 0) {
            result.push(`${attribute.name}`)
          } else {
            result.push(`${attribute.name} +$${attribute.price}`)
          }
        }
      });
    });
  }

  return result.join("\n\n");
}

// 取得打 「新增餐點」 API 所要用的 item 資料格式
export const getAddItemApiFormat = (item) => {
  const itemApiFormat = {
    id: item.id,
    qty: item.qty,
    comment: item.comment,
    purchaser_name: item.owner,
    base_price_id: item.selectedBasePriceId,
    attribute_ids: getItemSelectedAttributeIds(item),
  };
  return itemApiFormat;
}

// 取得打 「修改餐點」 API 用的 item 資料格式
export const getEditItemApiFormat = (item) => {
  const itemApiFormat = {
    id: item.id,
    qty: item.qty,
    comment: item.comment,
    purchaser_name: item.owner,
    base_price_id: item.selectedBasePriceId,
    attribute_ids: getItemSelectedAttributeIds(item),
  };
  return itemApiFormat;
}

// 判斷 品項/份量/附加選項 是否售完
// *被揪不阻擋今日售完
// *2023-3-30 跟團 移除今日售完不阻擋
export const isSoldOut = (target, isPickUpDateToday) => {
  return (
    target.isSold
    || (target.isTodaySold && isPickUpDateToday)
  );
}

export const showBasePrice = (item) => {
  return item.base_prices && item.base_prices.length > 1;
}
