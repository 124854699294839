import generateShareRouter from '@/router/modules/shareRouters/reserveShareRouter';

const reserveStoreRouter = {
  path: '/:company/:store',
  component: () => import('@/views/AppMain/AppMain.vue'),
  children: [
    {
      path: '',
      name: 'index.store',
      redirect: 'reserve',
    },
    {
      path: 'reserve',
      name: 'reserve.store',
      meta: { level: 'store', shouldRedirectAfterLogin: true },
      component: () => import('@/views/Reserve/StoreReservePage.vue'),
    },
    {
      path: 'order',
      name: 'order.store',
      meta: { level: 'store', shouldRedirectAfterLogin: true },
      component: () => import('@/views/Reserve/ReserveOrderPage.vue')
    },
    {
      path: 'order/detail',
      name: 'order.detail.store',
      meta: { level: 'store', shouldRedirectAfterLogin: true },
      component: () => import('@/views/Reserve/ReserveOrderDetailPage.vue')
    },
    {
      path: 'queue',
      name: 'queue.store',
      meta: { level: 'store', shouldRedirectAfterLogin: true },
      component: () => import('@/views/Reserve/Waiting/WaitingQueue.vue')
    },
    {
      path: 'queue/detail',
      name: 'queue.detail.store',
      meta: { level: 'store', shouldRedirectAfterLogin: true },
      component: () => import('@/views/Reserve/Waiting/WaitingQueueDetail.vue')
    },
    {
      path: "/404",
      component: () => import("@/views/errorPage/404.vue"),
      name: "404",
      meta: { level: "pass" }
    },
    ...generateShareRouter('store'),
  ],
};

export default reserveStoreRouter;
