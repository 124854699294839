const ORIGINAL_PRIMARY = '#409EFF';
const ORIGINAL_SECONDARY = '#e6a23c';
const { version } = require('element-ui/package.json');

const getColorCluster = (hex) => {
  const tintColor = (color, tint) => {
    let red = parseInt(color.slice(0, 2), 16);
    let green = parseInt(color.slice(2, 4), 16);
    let blue = parseInt(color.slice(4, 6), 16);
    if (tint === 0) { // when primary color is in its rgb space
      return [red, green, blue].join(',');
    }
    red += Math.round(tint * (255 - red));
    green += Math.round(tint * (255 - green));
    blue += Math.round(tint * (255 - blue));
    red = red.toString(16);
    green = green.toString(16);
    blue = blue.toString(16);
    return `#${red}${green}${blue}`;
  };
  const shadeColor = (color, shade) => {
    let red = parseInt(color.slice(0, 2), 16);
    let green = parseInt(color.slice(2, 4), 16);
    let blue = parseInt(color.slice(4, 6), 16);
    red = Math.round((1 - shade) * red);
    green = Math.round((1 - shade) * green);
    blue = Math.round((1 - shade) * blue);
    red = red.toString(16);
    green = green.toString(16);
    blue = blue.toString(16);
    return `#${red}${green}${blue}`;
  };

  const color = hex.replace('#', '');
  const clusters = [color];

  for (let i = 0; i <= 9; i += 1) {
    clusters.push(tintColor(color, Number((i / 10).toFixed(2))));
  }
  clusters.push(shadeColor(color, 0.1));
  return clusters;
};

const replaceColorStringWithClusters = (ogCluster, newCluster, text) => {
  let str = text;

  for (let i = 0; i < ogCluster.length; i += 1) {
    const oldColor = ogCluster[i];
    const newColor = newCluster[i];

    const re = new RegExp(oldColor, 'gi');

    str = str.replace(re, newColor);
  }

  return str;
};

function getCSSString(url, callback) {
  const xhr = new XMLHttpRequest();
  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4 && xhr.status === 200) {
      const chalk = xhr.responseText.replace(/@font-face{[^}]+}/, '');
      callback(chalk);
    }
  };
  xhr.open('GET', url);
  xhr.send();
}

const updateTheme = (primary, secondary) => {
  console.warn('[UPDATE THEME]');
  // 配合菜單頁scroll spy元件 + 自訂主題色解法
  const customActiveCSS = document.querySelector("#customActiveCSS");
  const re = new RegExp('#fd8c5c', 'gi');
  const text = customActiveCSS.innerText.replace(re, `${primary} !important`);
  customActiveCSS.innerText = text;

  const styleEl = document.createElement('style');
  styleEl.setAttribute('id', 'custom-active-style');
  styleEl.innerText = text;
  document.head.appendChild(styleEl);

  const primaryCluster = getColorCluster(primary);
  const ogPrimaryCluster = getColorCluster(ORIGINAL_PRIMARY);
  const secondaryCluster = getColorCluster(secondary);
  const ogSecondaryCluster = getColorCluster(ORIGINAL_SECONDARY);

  const updateStyleString = (chalk) => {
    let newChalk;

    newChalk = replaceColorStringWithClusters(ogPrimaryCluster, primaryCluster, chalk);
    newChalk = replaceColorStringWithClusters(ogSecondaryCluster, secondaryCluster, newChalk);

    const styleTag = document.createElement('style');
    styleTag.setAttribute('id', 'chalk-style');
    styleTag.innerText = newChalk;
    document.head.appendChild(styleTag);
  };

  const url = `https://unpkg.com/element-ui@${version}/lib/theme-chalk/index.css`;

  getCSSString(url, updateStyleString);
};


export default updateTheme;
