const isAddressValid = (addr) => {
  if (!addr) return false;

  const isLargeThan7 = addr.length > 7;
  const encodedAddr = encodeURIComponent(addr);

  // 號|号
  const numberRegex = /%E8%99%9F|%E5%8F%B7/i;
  // 縣|市|
  const cityRegex = /%E7%B8%A3|%E5%B8%82/i;

  return (
    numberRegex.test(encodedAddr)
    && cityRegex.test(encodedAddr)
    && isLargeThan7
  );
};

export default isAddressValid;
