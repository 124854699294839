export default function lineLoginRedirect({ channelId, callbackURL }) {
  let URL = "https://access.line.me/oauth2/v2.1/authorize?";
  URL += "response_type=code";
  URL += `&client_id=${channelId}`;
  URL += `&redirect_uri=${encodeURIComponent(callbackURL)}`;
  URL += "&state=imenu";
  URL += "&scope=openid%20profile";
  window.location.href = URL;
}

export const liffInit = () => {
  const liffId = window.__liff_id__;
  return liff.init({ liffId });
};


import store from '@/store/index';
import moment from "moment";
export function initLiff() {
  return new Promise((resolve, reject) => {
    // window.__liff_id__ = "1656837630-QkEMMglJ";
    const liffId = window.__liff_id__;

    if (!liffId) {
      resolve(false);
      return;
    }
    try {
      let startTime = moment().valueOf();
      liff
        .init({ liffId })
        .then(async () => {
          let nowTime = moment().valueOf() - startTime;
          store.dispatch('setLineApiReadyStatus', nowTime);
          const isLoggedIn = liff.isLoggedIn();
          if (isLoggedIn) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(err => {
          console.error(err);
          resolve(false);
        });
    } catch (error) {
      console.error(error);
      resolve(false);
    }
  });
}
