import router from '@/router/index';
import Cookies from 'js-cookie';
import {
  REDIRECTED,
  REPLACEDROUTE,
  REDIRECTED_TO_SPECIFY_ROUTE_AFTER_LOGIN,
} from './mutationTypes'

const initialState = {};
const routingModule = {
  state: {
    ...initialState,
  },
  mutations: {
    [REDIRECTED](state, payload) {
      return router.push(payload);
    },
    [REPLACEDROUTE](state, payload) {
      return router.replace(payload);
    },
    [REDIRECTED_TO_SPECIFY_ROUTE_AFTER_LOGIN](state, payload) {
      return router.push(payload);
    },
  },
  actions: {
    redirectTo({ commit }, payload) {
      commit(REDIRECTED, payload);
    },
    replacePath({ commit }, payload) {
      commit(REPLACEDROUTE, payload);
    },
    redirectToSpecifyRouteAfterLogin({ getters, commit }, payload) {
      // 此 Action 會在登入後（包含第三方登入）去檢查 Cookie 是否有存想要返回的路徑，若有則導向該路徑
      let redirectCookieKey = `redirect_path_${getters.companySlug}`;

      if (!getters.isCompanyLevel) {
        redirectCookieKey = `redirect_path_${getters.companySlug}_${getters.storeSlug}`;
      }

      const routeName = Cookies.get(redirectCookieKey);

      if (routeName) {
        Cookies.remove(redirectCookieKey);
        commit(REDIRECTED_TO_SPECIFY_ROUTE_AFTER_LOGIN, {
          path: routeName,
        });
      } else {
        commit(REDIRECTED_TO_SPECIFY_ROUTE_AFTER_LOGIN, payload);
      }
    },
  },
};

export default routingModule;
