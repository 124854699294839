import generateShareRouter from './shareRouters/shareRouter';

const companyRouter = {
  path: '/:company',
  component: () => import('@/views/AppMain/AppMain.vue'),
  children: [
    {
      path: '',
      name: 'index.company',
      redirect: 'branches',
    },
    {
      path: 'branches',
      name: 'branches.company',
      meta: { level: 'company', shouldRedirectAfterLogin: true, },
      component: () => import('@/views/newBranchList/BranchList.vue')
    },
    {
      path: 'sopower',
      name: 'sopower.company',
      meta: { level: 'company', shouldRedirectAfterLogin: false, },
      component: () => import('@/views/Sopower/Sopower.vue')
    },
    ...generateShareRouter('company'),
  ],
};

export default companyRouter;
