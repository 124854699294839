import domain from './domain';
import http from '@/utils/http';
import getI18nKey from './getLangValue';

export default {
  fetch({
    company, store
  }) {
    let url = `${domain.new}/imenu/${company}/config`;
    if (store) {
      url = `${domain.new}/imenu/${company}/config?store_slug=${store}`;
    }
    return http.get(`${url}`, {
      params: {
        lang: getI18nKey('api_lang')
      }
    });
  }
}
