// 简体中文（zh-CN）
// 英语（en）
// 德语（de）
// 葡萄牙语（pt）
// 西班牙语（es）
// 丹麦语（da）
// 法语（fr）
// 挪威语（nb-NO）
// 繁体中文（zh-TW）
// 意大利语（it）
// 韩语（ko）
// 日语（ja）
// 荷兰语（nl）
// 越南语（vi）
// 俄语（ru-RU）
// 土耳其语（tr-TR）
// 巴西葡萄牙语（pt-br）
// 波斯语（fa）
// 泰语（th）
// 印尼语（id）
// 保加利亚语（bg）
// 波兰语（pl）
// 芬兰语（fi）
// 瑞典语（sv-SE）
// 希腊语（el）
// 斯洛伐克语（sk）
// 加泰罗尼亚语（ca）
// 捷克语（cs-CZ）
// 乌克兰语（ua）
// 土库曼语（tk）
// 泰米尔语（ta）
// 拉脱维亚语（lv）
// 南非荷兰语（af-ZA）
// 爱沙尼亚语（ee）
// 斯洛文尼亚语（sl）
// 阿拉伯语（ar）
// 希伯来语（he）
// 立陶宛语（lt）
// 蒙古语（mn）
// 哈萨克斯坦语（kz）
// 匈牙利语（hu）
// 罗马尼亚语（ro）
// 库尔德语（ku）
// 维吾尔语（ug-CN）
// 高棉语（km）

import zh_tw from 'element-ui/lib/locale/lang/zh-TW';
import en from 'element-ui/lib/locale/lang/en';
import id from 'element-ui/lib/locale/lang/id';

const locales = {
  en,
  'zh-tw': zh_tw,
  id
}

export default locales
