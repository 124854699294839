import domain from './domain';
import http from '@/utils/http';
import { appToken } from '@/utils/auth';
import getI18nKey from './getLangValue';

export default {
  /** 拿 auth_code 換取 login token */
  getLoginToken(obj) {
    const { platform, auth_code, store_id } = obj;
    const data = {
      auth_code,
      store_id
    }

    return http.post(`${domain.new}/platform/${platform}/redirect_login`, data, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      }
    });
  },
  /** 取得跳轉到 JKO 付款資訊的 URL */
  getJkoRedirectPaymentPageUrl(url) {
    return http.get(url, {
      params: {
        lang: getI18nKey('api_lang')
      }
    });
  }
}
