import vuexStore from "@/store/index";
import { setToken, getToken } from "@/utils/auth";
import { initLiff } from "@/utils/line";
import Cookies from "js-cookie";
import { getLiffUserData } from "@/utils/reserve/liff";

const setConfig = async companyParam => {
  if (process.env.NODE_ENV === "production") {
    const config = window.__imenu_config__;
    const company = window.__imenu_company_slug;
    vuexStore.dispatch("setConfig", {
      ...config,
      company
    });
  } else {
    if (!vuexStore.getters.config.id) {
      await vuexStore.dispatch("fetchConfig", {
        company: companyParam
      });
    }
  }
};

const setLogIn = async (isLIFF, to) => {
  let { isLoggedIn } = vuexStore.getters;
  if (isLoggedIn) return;

  if (isLIFF) {
    const profile = await window.liff.getProfile();
    const accessToken = await window.liff.getAccessToken();
    await vuexStore.dispatch('thirdLoginWithLine', {
      profile,
      accessToken,
      disableAutoRedirect: true,
      redirectCookiePath: to.fullPath,
    });
  } else {
    getToken() && await vuexStore.dispatch('getUserDetail');
  }
};

const setCurrentBranch = async (storeParam, storeSlugQuery, next) => {
  if (!storeParam && !storeSlugQuery) return;
  const currentBranch =
    vuexStore.getters.config.branches[storeParam] ||
    vuexStore.getters.config.branches[storeSlugQuery];

  // 找不到對應店家，導回品牌頁面
  if (!currentBranch) return next({
    path: `${vuexStore.getters.config.slug}`,
    replace: true
  });

  // (清空原店家的訂位資料)

  // 取得當前店家的訂位資料
  const branch = await vuexStore.dispatch("GET_RESERVE_STORE_DATA", {
    companyId: vuexStore.getters.companyId,
    storeId: currentBranch.id,
  });

  // 更新當前 config 資料
  vuexStore.dispatch("updateCurrentStore", {
    id: currentBranch.id,
    name: currentBranch.name,
    slug: storeParam || storeSlugQuery,
    qrcode: currentBranch.qrcode,
    google_api_key: currentBranch.google_api_key
  });

  return branch;
};

const checkIsComanySlugValid = companyParam => {
  const isConfigValid = window.__imenu_config__ && window.__imenu_config__.id;
  const isCompanySlugValid = window.__imenu_company_slug;
  let result = Boolean(isConfigValid && isCompanySlugValid);

  if (process.env.NODE_ENV !== "production") {
    result = Boolean(companyParam);
  }
  return result;
};

const reserveProcess = async (to, from, next) => {
  const { company: companyParam, store: storeParam } = to.params;
  const { store_slug: storeSlugQuery } = to.query;

  const isComanySlugValid = checkIsComanySlugValid(companyParam);
  if (!isComanySlugValid) return next({ name: '404' });

  vuexStore.dispatch("setIsReserve", true);

  const isLIFF = await initLiff();
  vuexStore.dispatch("setIsLIFF", isLIFF);

  await setConfig(companyParam);

  const branch = await setCurrentBranch(
    storeParam,
    storeSlugQuery,
    next
  );

  // 當有店家資料，但該店家卻無啟用時 （可能合約到期或是其他狀況）
  // if (branch && !branch.isImenuAvailable) {
  //   // 將該店家的頁面導向 shutdown 頁面
  //   return next({
  //     name: "shutdown",
  //     replace: true
  //   });
  // }

  await setLogIn(isLIFF, to);

  // getLiffUserData(isLIFF);

  // next()沒有參數，將頁面導向預設頁面。
  return next();
};

export default reserveProcess;
