import domain from './domain';
import axios from '@/utils/http';
import { getToken, appToken } from '@/utils/auth';
import store from '@/store/index';
import getI18nKey from './getLangValue';

// Mock Data
// import getWalletPrepaidDepositTransactionRecordMockData from "@/views/Wallet/mock/getWalletPrepaidDepositTransactionRecordMockData.json";
// import getWalletPrepaidDepositOrderListMockData from "@/views/Wallet/mock/getWalletPrepaidDepositOrderListMockData.json";
// import getWalletPrepaidDepositPaymentStatusMockData from "@/views/Wallet/mock/getWalletPrepaidDepositPaymentStatusMockData.json";
// import getWalletPrepaidDepositOrderMockData from "@/views/Wallet/mock/getWalletPrepaidDepositOrderMockData.json";

const wallet = {
  /**
   * [GET] 取得會員儲值金錢包資料
   *
   * - Request -
   * @type {Number} company_id 品牌id
   *
   * - Response -
   * @type {Number} total 儲值金總額
   * @type {Number} expired_total 即將到期總額
   * @type {String} expired_at 即將到期時間 (2022-09-28 14:47:25)
   * @type {String} email 會員Email
   * @type {String} default_carrier_id 會員設定的手機載具預帶
   * @type {String} default_citizen_id 會員設定的自然人憑證預帶
   * @type {String} default_b_identifier 會員設定的統一編號預帶
   * @type {String} default_donate_no 會員設定的愛心捐贈預帶
   * @type {Object} invoice 發票資料
   * @type {String} invoice.type 預設發票類型 (personal：個人、phone：手機、citizen：自然人 、business：公司 、donate：捐贈)
   * @type {String} invoice.value 預設發票對應值
   * @type {Object Array} stores 適用店家列表
   * @type {String} stores.*.id 店家id
   * @type {String} stores.*.name 店家名稱
   * @type {String} stores.*.address 店家地址
   * @type {String} stores.*.lon 經度
   * @type {String} stores.*.lat 緯度
   * @type {Boolean} stores.delivery 是否可外送
   * @type {Boolean} stores.for_here 是否可內用
   * @type {Boolean} stores.take_out 是否可外帶
   */
  getWalletInfo(company_id) {
    return axios.get(`${domain.new}/company/${company_id}/wallet/info`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: getToken(),
      },
    })
  },

  /**
   * [PUT] 更新會員儲值金錢包資料
   *
   * - Request -
   * @type {Number} company_id 品牌id
   * @type {String} email 會員Email
   * @type {Object} invoice 發票
   * @type {String} invoice.type 發票類型 (personal：個人、phone：手機、citizen：自然人 、business：公司 、donate：捐贈)
   * @type {String} invoice.default_carrier_id 手機載具
   * @type {String} invoice.default_citizen_id 自然人憑證
   * @type {String} invoice.default_b_identifier 公司統編
   * @type {String} invoice.default_donate_no 愛心捐贈
   */
  putWalletInfo(data) {
    const { company_id, ...requestObj } = data;
    return axios.put(`${domain.new}/company/${company_id}/wallet/info`, requestObj, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: getToken(),
      },
    })
  },

  /**
   * [GET] 取得品牌儲值金方案
   *
   * - Request -
   * @type {Number} company_id 品牌id
   *
   * - Response -
   * @type {Number} id 方案id
   * @type {String} title 方案名稱
   * @type {Number} deposit 信用卡儲值金額
   * @type {Number} reward_point 加贈點數
   * @type {Number} total 購買後總額
   *
   * - Http Status 400 -
   * 10512 -> 品牌尚未設定儲值金方案
   */
  getWalletPrepaidDepositPlan(company_id) {
    return axios.get(`${domain.new}/company/${company_id}/wallet/prepaid_deposit_plan`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: getToken(),
      },
    })
  },

  /**
   * [GET] 取得儲值金付款公鑰
   *
   * 取得儲值金付款公鑰，用於加密購買方案時的信用卡資料
   *
   * - Request -
   * @type {Number} company_id 品牌id
   *
   * - Response -
   * @type {Array String} response 公鑰
   */
  getWalletPrepaidDepositPaymentKey(company_id) {
    return axios.get(`${domain.new}/company/${company_id}/wallet/prepaid_deposit/payment_key`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: getToken(),
      },
    })
  },

  /**
   * [POST] 購買品牌儲值金方案付款
   *
   * - Request -
   * @type {Number} company_id 品牌id
   * @type {JSON} payment_data 信用卡加密資料
   * @type {Number} payment_data.prepaid_deposit_plan_id 儲值方案id
   * @type {String} payment_data.card_number 信用卡卡號
   * @type {String} payment_data.card_expire_date 信用卡日期
   * @type {String} payment_data.CVV 信用卡安全碼
   *
   * - Response -
   * @type {Number} return_type 結果 (1 -> 導頁3d驗證, 2 -> 導頁結果頁)
   * @type {String} html 網址
   */
  postWalletPrepaidDepositPlan(data) {
    const { company_id, prepaid_deposit_plan_id, payment_data } = data;

    return axios.post(`${domain.new}/company/${company_id}/wallet/prepaid_deposit_plan/${prepaid_deposit_plan_id}`, payment_data, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: getToken(),
      },
    })
  },

  /**
   * [POST] 購買品牌儲值金方案付款金流callback
   *
   * - Request -
   * @type {Boolean} status 授權狀態
   * @type {String} message 訊息回傳
   * @type {Number} savings_id 金流id
   * @type {String} sn 儲值金訂單sn
   *
   * - Response -
   * @type {Boolean} success 狀態 (完成回傳true)
   */
  postWalletPrepaidDepositOrderCallback(data) {
    return axios.post(`${domain.new}/prepaid_deposit_order/callback`, data, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    })
  },

  /**
   * [GET] 取得儲值金付款碼
   *
   * - Request -
   * @type {Number} company_id 品牌id
   *
   * - Response -
   * @type {String} payment_qrcode 付款碼QRCode
   *
   * - Http Status 400 -
   * 10505 -> 缺少儲值金主表資料 (尚未儲值過)
   * 10513 -> 缺少儲值金發票資訊 (未設定email)
   */
  getWalletPrepaidDepositPaymentQrCode(company_id) {
    return axios.get(`${domain.new}/company/${company_id}/wallet/prepaid_deposit/payment_qrcode`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: getToken(),
      },
    })
  },

  /**
   * [GET] 取得儲值金付款狀態
   *
   * - Request -
   * @type {Number} company_id 品牌id
   *
   * - Response -
   * @type {Number} result 付款狀態 (1 -> 未付款, 2 -> 已付款)
   * @type {Object Array} payment_data 付款內容 (未付款狀態 -> [])
   * @type {Boolean} payment_data.status 付款結果 (true -> 成功, false -> 失敗)
   * @type {String} payment_data.message 付款內容
   * @type {String} payment_data.store 付款店家
   * @type {Number} payment_data.total 付款金額
   * @type {String} payment_data.payment_at 付款時間 ("2022-10-04 10:30:00")
   */
  getWalletPrepaidDepositPaymentStatus(company_id) {
    return axios.get(`${domain.new}/company/${company_id}/wallet/prepaid_deposit/payment_status`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: getToken(),
      },
    })
  },

  /**
   * [GET] 取得儲值金儲值紀錄
   *
   * - Request -
   * @type {Number} company_id 品牌id
   * @type {Number} page 頁數 (預設第1頁)
   * @type {Number} limit 一頁幾筆 (預設10筆)
   *
   * - Response -
   * @type {Number} page 當前頁數
   * @type {Number} limit 每頁幾筆
   * @type {Number} total 總筆數
   * @type {Number} total_page 總頁數
   * @type {Object Array} order_records 儲值紀錄
   * @type {Number} id 儲值金訂單id
   * @type {Number} total 儲值總額
   * @type {Number} deposit 儲值金額
   * @type {Number} reward_point 紅利贈點
   * @type {Number} allow_refund_amount 可退金額 (為0不可進行退款，沒有退款按鈕)
   * @type {Number} created_at 儲值時間 ("2022-09-29 14:55:53")
   * @type {Number} expired_at 到期時間 ("2022-12-29 14:55:53")
   */
  getWalletPrepaidDepositOrderList(data) {
    const { company_id, page, limit } = data;
    return axios.get(`${domain.new}/company/${company_id}/wallet/prepaid_deposit_order`, {
      params: {
        lang: getI18nKey('api_lang'),
        page,
        limit
      },
      headers: {
        Authorization: getToken(),
      },
    })
  },

  /**
   * [GET] 取得單筆儲值金訂單退款紀錄
   *
   * - Request -
   * @type {Number} company_id 品牌id
   * @type {Number} prepaid_deposit_order_id 儲值金訂單id
   *
   * - Response -
   * @type {Number} id 儲值金訂單id
   * @type {Number} refund_deposit 本次退款剩餘金額
   * @type {Number} reward_point_used 本次退款已使用點數
   * @type {Number} allow_refund_amount 本次退款可退金額
   * @type {Object Array} refund_records 過往退款紀錄
   * @type {Number} refund_records.refunded_amount 已退金額
   * @type {Number} refund_records.refunded_at 退款時間 ("2022-09-30 18:05:36")
   */
  getWalletPrepaidDepositOrder(data) {
    const { company_id, prepaid_deposit_order_id } = data;
    return axios.get(`${domain.new}/company/${company_id}/wallet/prepaid_deposit_order/${prepaid_deposit_order_id}`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: getToken(),
      },
    })
  },

  /**
   * [POST] 儲值金自行退款
   *
   * - Request -
   * @type {Number} company_id 品牌id
   * @type {Number} prepaid_deposit_order_id 儲值金訂單id
   *
   * - Response -
   * @type {Boolean} success 退款狀態
   *
   * - Http Status 400 -
   * 50106 -> 呼叫大麥金流失敗
   * 50117 -> 儲值金退款失敗 (會有原因)
   */
  postWalletPrepaidDepositOrderRefund(data) {
    const { company_id, prepaid_deposit_order_id } = data;
    return axios.post(`${domain.new}/company/${company_id}/wallet/prepaid_deposit_order/${prepaid_deposit_order_id}/refund`, {}, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: getToken(),
      },
      transformResponse: axios.defaults.transformResponse.concat((data) => {
        data = { ...data, byPass400Default: true };
        return data;
      })
    })
  },

  /**
   * [GET] 取得儲值金交易紀錄
   *
   * - Request -
   * @type {Number} company_id 品牌id
   * @type {Number} page 頁數 (預設第1頁)
   * @type {Number} limit 一頁幾筆 (預設10筆)
   *
   * - Response -
   * @type {Number} page 當前頁數
   * @type {Number} limit 每頁幾筆
   * @type {Number} total 總筆數
   * @type {Number} total_page 總頁數
   * @type {Object Array} transaction_records 交易紀錄
   * @type {String} transaction_records.store 交易店家
   * @type {Number} transaction_records.status 交易狀態 (2: 支付, 8: 退單返還, 9: 作廢返還)
   * @type {Number} transaction_records.amount 交易金額 (有正負值)
   * @type {String} transaction_records.time 交易時間 ("2022-09-30 10:55:17")
   * @type {String} transaction_records.invoice_link 發票連結
   */
  getWalletPrepaidDepositTransactionRecord(data) {
    const { company_id, page, limit } = data;

    return axios.get(`${domain.new}/company/${company_id}/wallet/prepaid_deposit/transaction_record`, {
      params: {
        lang: getI18nKey('api_lang'),
        page,
        limit
      },
      headers: {
        Authorization: getToken(),
      },
    })
  },
};

export default wallet;
