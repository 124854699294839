import generateShareRouter from '@/router/modules/shareRouters/reserveShareRouter';

const reserveCompanyRouter = {
  path: '/:company',
  component: () => import('@/views/AppMain/AppMain.vue'),
  children: [
    {
      path: '',
      name: 'index.company',
      redirect: 'branches',
    },
    {
      path: 'branches',
      name: 'branches.company',
      meta: { level: 'company', shouldRedirectAfterLogin: true },
      component: () => import('@/views/Reserve/CompanyReservePage.vue')
    },
    ...generateShareRouter('company'),
  ],
};

export default reserveCompanyRouter;
