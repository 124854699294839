import { Message } from "element-ui";

export const useMessage = (status, text) => {
  const time = status === "error" ? 4000 : 2000;

  Message({
    message: text,
    type: status,
    duration: time
  });
};
