import {
  RESET_ORDER_MENU,
  RESET_ORDER_LIST,
  FETCHED_ORDER_MENU_LIST,
  FETCHED_NEWEST_ORDER_MENU,
  FETCHED_ORDER_MENU_DETAIL,
  UPDATE_ORDER_MENU_DETAIL_IS_EDITED,
  UPDATE_ORDER_MENU_DELETED_IDS,
  GET_ORDER_MENU_SERVICE_CHARGE,
  DELETED_ORDER_MENU,
  CREATED_ORDER_MENU,
  FETCHED_ORDER_LIST,
  FETCHED_ORDER_BY_ID,
  FETCHED_JKO_ORDER_BY_SN,
  REPURCHASED_ORDER,
} from './mutationTypes'

import { Message } from 'element-ui';
import Cookies from 'js-cookie';
import API from '@/api/orderMenu';
import APIPreCheckout from '@/api/preCheckout';

import {
  getAddItemApiFormat,
  getEditItemApiFormat,
} from '@/utils/menu/menuItems'

import {
  getAddSetApiFormat,
  getEditSetApiFormat,
} from '@/utils/menu/menuSet'

import { addIsEditedToPurchaserList, handleDeletedItems } from '@/utils/menu/formatPurchaserList';

const getInitialIngState = () => {
  return {
    id: 0,
    name: "",
    creator: "",
    is_share: false,
    comment: "",
    item_list: {
      sets: [],
      items: [],
    },
    purchaser_list: [],
    arrived: {},
    company_id: 0,
    company_slug: "",
    deadline: {},
    message: "",
    store_id: 0,
    store_slug: "",
    total_price: 0,
    total_qty: 0,
    deletedIds: [],//存 打checkOrderMenuStatus後，有異動的品項
  }
}

const getInitialState = () => {
  return {
    list: [],
    ing: getInitialIngState(),
    orderList: {
      detail: [],
      top_detail: []
    },
    currentOrderHistory: {
      purchaser: {},
      exchange_list: [],
    },
    service_charge: 0,
  }
}

const orderMenu = {
  state: { ...getInitialState() },
  mutations: {
    [RESET_ORDER_MENU](state) {
      state.list = [];
      state.ing = { ...getInitialIngState() };
    },
    [RESET_ORDER_LIST](state) {
      state.orderList = {
        detail: [],
        top_detail: [],
      };
    },
    [FETCHED_ORDER_MENU_LIST](state, payload) {
      state.list = [...payload];
    },
    [FETCHED_NEWEST_ORDER_MENU](state, payload) {
      state.ing = { ...state.ing, ...payload };
    },
    [FETCHED_ORDER_MENU_DETAIL](state, payload) {
      state.ing = { ...state.ing, ...payload };
    },
    // 更新is_edited狀態
    [UPDATE_ORDER_MENU_DETAIL_IS_EDITED](state, payload) {
      state.ing.purchaser_list = [...payload];
    },
    // 更新deletedIds狀態
    [UPDATE_ORDER_MENU_DELETED_IDS](state, payload) {
      state.ing.deletedIds = [...payload];
    },
    [GET_ORDER_MENU_SERVICE_CHARGE](state, payload) {
      state.service_charge = payload;
    },
    [DELETED_ORDER_MENU](state, id) {
      state.list = state.list.filter(orderMenu => orderMenu.id !== id);
    },
    [CREATED_ORDER_MENU](state, payload) {
      state.ing = { ...payload };
    },
    [FETCHED_ORDER_LIST](state, payload) {
      state.orderList.detail = state.orderList.detail.concat(payload.detail);
      state.orderList.top_detail = state.orderList.top_detail.concat(payload.top_detail);
    },
    [FETCHED_ORDER_BY_ID](state, payload) {
      state.currentOrderHistory = { ...payload };
    },
    [FETCHED_JKO_ORDER_BY_SN](state, payload) {
      state.currentOrderHistory = { ...payload };
    },
    [REPURCHASED_ORDER](state, payload) {
      return;
    },
  },
  actions: {
    resetOrderMenu({ commit }) {
      commit(RESET_ORDER_MENU);
    },
    resetOrderList({ commit }) {
      commit(RESET_ORDER_LIST);
    },
    // GET /order_menu 取得所有點餐單
    async fetchOrderMenuList({ commit }) {
      const response = await API.fetchOrderMenuList();
      commit(FETCHED_ORDER_MENU_LIST, response.data.response);

      return response;
    },
    // GET /store/{store_id}/order_menu/latest
    async fetchNewestOrderMenu({ commit }, store_id) {
      const response = await API.fetchNewestOrderMenu(store_id);
      const purchaserList = response.data.response.purchaser_list ?? [];
      response.data.response.purchaser_list = addIsEditedToPurchaserList(purchaserList, false, false);

      commit(FETCHED_NEWEST_ORDER_MENU, response.data.response);

      return response;
    },
    // GET /store/{store_id}/order_menu/{order_menu_id} 取得單一點餐單 detail
    async fetchOrderMenuDetail({ dispatch, commit, getters }, {
      order_menu_id,
      is_follow = false,
    }) {
      const response = await API.fetchOrderMenuDetail({
        order_menu_id
      });
      // 撈 order detail API 回應有 message 代表點餐單有更新（售完、品項過期上下架等等）
      // 要 show message
      // 然後這邊要做重新抓菜單的動作，該 action 包含版本、菜單、過期品項
      if (response.data.response.message && !is_follow) {
        await dispatch("fetchMenu", getters.storeId);
        await dispatch("fetchBranchInfo", {
          company_id: getters.companyId,
          store_id: getters.storeId,
          from: getters.isQrOrder ? 'QRORDER' : 'IMENU',
          isWemo: getters.isWemo
        });

        Message({
          type: "success",
          message: response.data.response.message,
        });

        commit(FETCHED_ORDER_MENU_DETAIL, response.data.response);
        return response;
      }

      const purchaserList = response.data.response.purchaser_list ?? [];
      response.data.response.purchaser_list = addIsEditedToPurchaserList(purchaserList, false, false);

      commit(FETCHED_ORDER_MENU_DETAIL, response.data.response);
      // 如果有品項異動紀錄就進來更新isEdited
      if (orderMenu.state.ing.deletedIds.length > 0) {
        handleDeletedItems(orderMenu.state.ing, orderMenu.state.ing.deletedIds, false, (formatPurchaserList) => { commit(UPDATE_ORDER_MENU_DETAIL_IS_EDITED, formatPurchaserList) })
      }
      return response;
    },
    updateOrderMenuDetailIsEdited({ commit }, data) {
      commit(UPDATE_ORDER_MENU_DETAIL_IS_EDITED, data);
    },
    // 更新deletedIds狀態
    updateOrderMenuDetailDeletedIds({ commit }, data) {
      commit(UPDATE_ORDER_MENU_DELETED_IDS, data);
    },
    // 取得目前點餐單的 服務費 (限用於 掃碼內用先結)
    async getOrderMenuServiceCharge({ commit }, data) {
      const response = await APIPreCheckout.getPreCheckoutServiceCharge(data);

      commit(GET_ORDER_MENU_SERVICE_CHARGE, response.data.response.service_charge);
    },
    // DELETE /order_menu/{order_menu_id} 刪除點餐單
    async deleteOrderMenu({ commit }, order_menu_id) {
      const response = await API.deleteOrderMenu(order_menu_id);
      commit(DELETED_ORDER_MENU, order_menu_id);

      return response;
    },
    // POST /store/{store_id}/order_menu 建立點餐單
    async createOrderMenu({ commit, getters }, store_id) {
      const response = await API.createOrderMenu(store_id);

      !getters.isLoggedIn && Cookies.set(`order_${store_id}`, response.data.response.id, { expires: 1 });

      const orderId = response.data.response.id;
      const orderMenu = await API.fetchOrderMenuDetail({
        order_menu_id: orderId
      });

      commit(FETCHED_ORDER_MENU_DETAIL, orderMenu.data.response);

      return response;
    },
    //  POST /order_menu/{order_menu_id}/binding
    async bindOrderMenu({ commit }, {
      store_id,
      order_menu_id,
    }) {
      // 條調整為 api 回應後 才執行刪除 cookie
      let response = '';
      await API.bindOrderMenu(order_menu_id).then((res) => {
        response = res;
        Cookies.remove(`order_${store_id}`);
      }).catch((err) => {
        console.error({err});
        Cookies.remove(`order_${store_id}`);
      });

      const orderMenu = await API.fetchOrderMenuDetail({
        order_menu_id
      });

      commit(FETCHED_ORDER_MENU_DETAIL, orderMenu.data.response);
      return response;
    },
    //  PUT /store/{store_id}/order_menu/{order_menu_id}/share 更改揪團設定
    async updateOrderMenuShareSetting({ dispatch, commit }, {
      store_id,
      order_menu_id,
      data,
    }) {
      const response = await API.updateOrderMenuShareSetting({
        store_id,
        order_menu_id,
        data,
      });

      const orderMenu = await dispatch('fetchOrderMenuDetail', {
        order_menu_id,
      });

      return response.data.response;
    },
    // GET /store/{store_id}/order_menu/{order_menu_id}/check 檢查點餐單是否可用
    async checkOrderMenuStatus({ dispatch, commit, getters }, { store_id, order_menu_id, prefer_datetime }) {
      try {
        const response = await API.checkOrderMenuStatus({
          store_id,
          order_menu_id,
          prefer_datetime,
          pickup_type: getters.isQrOrder ? 'for_here' : getters.takeMethodInUse.key,
          from: getters.isQrOrder ? 'QRORDER' : 'IMENU',
        });

        return {
          updated: false,
          status: response.data.response.status,
          deleted_ids: response.data.response.deleted_ids
        };
      } catch (error) {
        console.error(error);
        Cookies.remove(`order_${store_id}`);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    },
    // POST /store/{store_id}/order_menu/{order_menu_id}/item 新增餐點
    async addItemToOrderMenu({ dispatch, commit }, {
      store_id,
      order_menu_id,
      data,
    }) {
      let dataApiFormat = {};

      if (data.is_set) {
        dataApiFormat = getAddSetApiFormat(data);
      } else {
        dataApiFormat = getAddItemApiFormat(data);
      }
      const response = await API.addItemToOrderMenu({
        store_id,
        order_menu_id,
        data: dataApiFormat,
      });

      const orderMenu = await dispatch('fetchOrderMenuDetail', {
        order_menu_id,
      });

      return orderMenu;
    },
    // PUT /store/{store_id}/order_menu/{order_menu_id}/item/{order_menu_detail_id} 修改點餐單
    async editItemInOrderMenu({ dispatch, commit }, {
      store_id,
      order_menu_id,
      order_menu_detail_id,
      data,
    }) {
      let dataApiFormat = {};

      if (data.detail) {
        dataApiFormat = getEditSetApiFormat(data);
      } else {
        dataApiFormat = getEditItemApiFormat(data);
      }

      const response = await API.editItemInOrderMenu({
        store_id,
        order_menu_id,
        order_menu_detail_id,
        data: dataApiFormat,
      });

      const orderMenu = await dispatch('fetchOrderMenuDetail', {
        order_menu_id,
      });

      return orderMenu;
    },
    // PUT /store/{store_id}/order_menu/{order_menu_id}/item/{order_menu_detail_id} +- 移除只改 qty
    async editItemQtyInOrderMenu({ dispatch, commit }, {
      store_id,
      order_menu_id,
      order_menu_detail_id,
      qty,
    }) {
      const response = await API.editItemInOrderMenu({
        store_id,
        order_menu_id,
        order_menu_detail_id,
        data: { qty },
      });

      const orderMenu = await dispatch('fetchOrderMenuDetail', {
        order_menu_id,
      });

      return orderMenu;
    },
    // GET /order 訂單列表
    async fetchOrderList({ commit }, { page: pageQuery }) {
      const response = await API.fetchOrderList(pageQuery);
      const { page, total_page } = response.data.response;

      commit(FETCHED_ORDER_LIST, response.data.response);
      return {
        page,
        total_page
      };
    },
    // GET order 訂單明細
    async fetchOrderById({ commit }, { order_id, from }) {
      const response = await API.fetchOrderById({ order_id, from });
      commit(FETCHED_ORDER_BY_ID, response.data.response);
      return response.data.response;
    },
    // JKO Pay 取得單筆訂單
    async fetchJkoOrderBySn({ commit }, data) {
      const response = await API.fetchJkoOrderBySn(data);
      commit(FETCHED_JKO_ORDER_BY_SN, response.data.response);
      return response.data.response;
    },
    // POST /store/{store_id}/order/{order_id}/repurchase 重新訂購
    async repurchaseOrder({ commit }, {
      storeId,
      orderId,
    }) {
      const response = await API.repurchaseOrder({
        storeId,
        orderId,
      });

      Cookies.remove(`order_${storeId}`);

      commit(REPURCHASED_ORDER, response.data.response);
      return response.data.response;
    },
    // 揪團跟單預覽品項
    async previewOrderContent({ commit }, {
      store_id,
      order_menu_id,
      data,
    }) {
      let dataApiFormat = {};

      if (data.is_set) {
        dataApiFormat = getAddSetApiFormat(data);
      } else {
        dataApiFormat = getAddItemApiFormat(data);
      }
      const response = await API.previewOrderContent({
        store_id,
        order_menu_id,
        data: dataApiFormat,
      });
      return response.data.response;
    },
    async fetchOrderCallNoById({ commit }, order_id) {
      const response = await API.fetchOrderCallNoById(order_id);

      return response.data.response;
    }
  },
};

export default orderMenu;
