import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'zh-tw',
  fallbackLocale: "en",
  messages: getMessages(getAllLangFiles())
});

export default i18n;

// 抓取 src/lang 底下所有的 json 檔案 (只抓取檔名為:大小寫英文/數字/橫線/底線 的JSON檔案)
function getAllLangFiles() {
  return require.context(
    "@/lang",
    true,
    /[A-Za-z0-9-_]+\.json$/i
  );
}

// 將所有檔案轉譯成 vue i18n messages 需要的格式 messages: { en: ..., ch: ..., jp: ... }
function getMessages(lang) {
  const messages = {};
  lang.keys().forEach(fileName => {
    const langName = fileName.split('.json')[0].split('./')[1]
    messages[langName] = lang(fileName);
  });
  return messages;
}

// 取得瀏覽器設定的語言
// function getBrowserLanguage() {
//   const userLanguage = window.navigator.language;
//   if (userLanguage.split('-')[0] === 'zh') return 'zh-tw';
//   return userLanguage.toLowerCase();
// }
