import {
  LOGGED_IN,
  LOGGED_OUT,
  UPDATED_USER,
  SET_LINE_PROFILE,
  VERIFY_CAPTCHA
} from './mutationTypes'
import store from '@/store/index';
import userApi from '@/api/user';
import { setToken, removeToken } from '@/utils/auth';
import { checkIsJko } from '@/utils/jko'
import { upDateUserLang } from '@/utils/upDateUserLang'
import i18n from "@/plugin/i18n";
import { setStorage, getStorage } from '@/utils/public/localStorage';

const $level = (routeName) => {
  if (store.getters.isCompanyLevel) {
    return `${routeName}.company`;
  }
  return `${routeName}.store`;
};

function setLoginToken(login_token) {
  const inThirtyMinutes = new Date(new Date().getTime() + 30 * 60 * 1000);
  checkIsJko() ? setToken(login_token, inThirtyMinutes) : setToken(login_token);
}

const initialState = {
  sex: '',
  name: '',
  email: '',
  account: '',
  phone_no: '',
  birthday: '',
  address: '',
  commonAddress: [],
  mobileBarcode: '',
  guiNumber: '',
  point: {
    total: 0,
    log: {
      data: []
    },
    description: ''
  },
  login_token: '',
  lineProfile: {
    displayName: '',
  },
  invoice: {},
  display: [
    "member_data",
    "my_wallet",
    "point",
    "coupon",
    "order_list",
    "order_menu_list",
  ],
  coupon_count: 0,
  point_count: 0,
  prepaid_deposit_count: 0,
  qrcode: "",
  is_register: false,
  lang: "",
};

const userModule = {
  state: {
    ...initialState,
  },
  mutations: {
    [LOGGED_IN](state, {
      account,
      address,
      common_address_info,
      default_carrier_id,
      default_b_identifier,
      birthday,
      email,
      login_token,
      phone_no,
      sex,
      name,
      invoice,
      display,
      coupon_count,
      point_count,
      prepaid_deposit_count,
      qrcode,
      lang,
      purchase_title,
    }) {
      state.account = account
      state.address = address
      state.commonAddress = common_address_info
      state.mobileBarcode = default_carrier_id
      state.guiNumber = default_b_identifier
      state.birthday = birthday
      state.email = email
      state.phone_no = phone_no
      state.sex = sex
      state.name = name
      state.login_token = login_token
      state.lang = lang
      state.purchase_title = purchase_title
      state.invoice = invoice,
        state.display = (display && [...display]) || [],
        state.coupon_count = coupon_count || 0,
        state.point_count = point_count || 0,
        state.prepaid_deposit_count = prepaid_deposit_count || 0,
        state.qrcode = qrcode || "",
        setLoginToken(login_token)
    },
    [LOGGED_OUT](state) {
      state.account = ''
      state.address = ''
      state.birthday = ''
      state.email = ''
      state.phone_no = ''
      state.sex = ''
      state.name = ''
      state.login_token = ''
      state.invoice = {}
      state.display = []
      state.coupon_count = 0
      state.point_count = 0
      state.prepaid_deposit_count = 0
      state.qrcode = ""
      removeToken();
    },
    [SET_LINE_PROFILE](state, payload) {
      state.lineProfile = { ...payload };
    },
    [SET_LINE_PROFILE](state, payload) {
      state.lineProfile = { ...payload };
    },
    [UPDATED_USER](state, payload) {
      state = { ...payload };
    },
    [VERIFY_CAPTCHA](state,{ login_token }) {
      state.login_token = login_token;
    }
  },
  actions: {
    async login(store, {
      account,
      password,
      companyId,
      storeId,
    }) {
      const response = await userApi.login({
        account,
        password,
        companyId,
        storeId,
      });
      let originalUserInfo = response.data.response;
      store.commit('LOGGED_IN', originalUserInfo);

      // 必須等 更新了 LOGGED_IN 的 store 的 login_token 才能在執行呼叫 更新使用者資料
      if (!originalUserInfo.lang) {
        await upDateUserLang(originalUserInfo);
        store.commit('LOGGED_IN', originalUserInfo);
      } else {
        let lang = (originalUserInfo.lang == 'zh_TW') ? 'zh-tw' : originalUserInfo.lang;
        i18n.locale = lang;
      }

      // 外送訂購單位 資料儲存到 localStorage 處理
      let addressNotes = `address_notes:${companyId}`;
      let userAddressData = response.data.response.common_address_info[0].purchase_title;
      if (!getStorage(addressNotes) && userAddressData) {
        setStorage(addressNotes, userAddressData);
      }

      store.dispatch('redirectToSpecifyRouteAfterLogin', { name: $level('index') });
    },
    async logout({ commit }) {
      try {
        await userApi.logout();
      } catch (error) {
        console.error(error);
      } finally {
        commit('LOGGED_OUT');
        store.dispatch('redirectTo', { name: $level('index') });
        window.location.reload();
      }
    },
    async getUserData({ commit }) {
      // const response = await userApi.fetchUser();
      // commit('LOGGED_IN', response.data.response);
    },
    async getUserDetail({ commit }, isQrOrder) {
      const response = await userApi.fetchUserDetail(isQrOrder);
      let originalUserInfo = response.data.response;
      
      commit('LOGGED_IN', originalUserInfo);

      // 如果 response 的 lang 未設定
      if (!originalUserInfo.lang) {
        // 執行 更新 user 資料
        // originalUserInfo 傳遞 response 過去 做資料更新 並且 在 originalUserInfo 中加入 lang 的設定
        await upDateUserLang(originalUserInfo);
      } else {
        let lang = (originalUserInfo.lang == 'zh_TW') ? 'zh-tw' : originalUserInfo.lang;
        i18n.locale = lang;
      }
    },
    async signUp({ commit }, {
      account,
      password,
      companyId,
      store_id,
    }) {
      const response = await userApi.signUp({
        account,
        password,
        companyId,
        store_id,
      });
      return account;
    },
    async thirdSignUp({ commit }, {
      company_id,
      store_id,
      platform_uuid,
      platform_token,
      phone,
      source
    }) {
      const response = await userApi.thirdPartySignUp({
        company_id,
        store_id,
        platform_uuid,
        platform_token,
        phone,
        source
      });
      return response;
    },
    async signUpWithNewMobileNumber({ commit }, {
      phone,
      platform_uuid,
      company_id,
      store_id,
      source,
    }) {
      const response = await userApi.signUpWithNewMobileNumber({
        phone,
        platform_uuid,
        company_id,
        store_id,
        source,
      });
      return response;
    },
    async verifyCaptcha({ commit }, {
      company_id,
      store_id,
      platform_uuid,
      captcha,
    }) {
      const response = await userApi.verifyCaptcha({
        company_id,
        store_id,
        platform_uuid,
        captcha,
      });
      if (response.data.response) {
        const { login_token } = response.data.response;
        setLoginToken(login_token);
        store.commit('VERIFY_CAPTCHA', response.data.response);
      }
      return response;
    },
    async checkCaptcha({ commit }, {
      company_id,
      platform_uuid,
      captcha,
    }) {
      const response = await userApi.checkCaptcha({
        company_id,
        platform_uuid,
        captcha,
      });
      return response;
    },
    async fetchCaptcha({ commit }, {
      company_id,
      store_id,
      platform_uuid,
    }) {
      const response = await userApi.fetchCaptcha({
        company_id,
        store_id,
        platform_uuid,
      });
      return response;
    },
    async forgetPassword({ commit }, {
      company_id,
      store_id,
      platform_uuid,
      new_token,
      captcha,
    }) {
      const response = await userApi.forgetPassword({
        company_id,
        store_id,
        platform_uuid,
        new_token,
        captcha,
      });
      return response;
    },
    async updateUser({ commit }, {
      name,
      email,
      birthday,
      sex,
      address,
      default_carrier_id,
      default_b_identifier,
      lang,
      is_register,
      purchase_title,
      // action,
    }) {
      const response = await userApi.updateUser({
        name,
        email,
        birthday,
        sex,
        address,
        default_carrier_id,
        default_b_identifier,
        lang,
        is_register,
        purchase_title,
        // action,
      });
      return response;
    },
    loggedInWithFB({ commit }, payload) {
      commit('LOGGED_IN', payload);
    },
    loggedInWithLine({ commit }, {
      userData,
      profile
    }) {
      commit('LOGGED_IN', userData);
      commit('SET_LINE_PROFILE', profile);
    },
    async soPowerThirdLogin({ commit }, {
      companyId,
      line_id,
      type,
    }) {
      try {
        const response = await userApi.soPowerThirdLogin({
          companyId,
          line_id,
          type,
        });
        return response;
      } catch (error) {
        console.error(error);
      }
    }
  },
};

export default userModule;
