import API from "@/api/reserve";
import { getNumberFromStr } from "@/utils/reserve/regex";
import {
  cloneDeep as _cloneDeep,
  flow as _flow,
  add as _add,
  isArray as _isArray,
  cond as _cond,
  forEach as _forEach,
  size as _size,
  forIn as _forIn,
  isPlainObject as _isPlainObject,
  range as _range,
  isEmpty as _isEmpty,
} from "lodash-es";

function addReservableData(cloneData) {
  const sizeNotEqualToZero = (i) => _size(i) !== 0;
  const checkArrayData = _cond([[_isArray, sizeNotEqualToZero]]);
  const checkObjectData = _cond([[_isPlainObject, sizeNotEqualToZero]]);

  _forEach(cloneData, (store) => {
    const reserveInfo = store.reserve_info;
    // 當日無可訂位時段
    if (!checkArrayData(reserveInfo)) return;

    // 每一家分店對應 1 - 10 人的可呈現訂位資料
    const reservableData = {};
    _forEach(_range(10), (i) => (reservableData[i + 1] = []));

    _forEach(reserveInfo, (info) => {
      const { times, show_date: showDate, date, message } = info;
      const maxReservableLength = 10;
      const isExceed = (day) => _size(reservableData[day]) >= maxReservableLength;

      // 該時段訂位已滿 or 公休
      if (!checkObjectData(times)) {
        Object.keys(reservableData).forEach((day) => {
          if (isExceed(day)) return;
          reservableData[day].push({ showDate, date, time: message });
        });
      }

      // 有可以訂位的時段
      _forIn(times, (days, time) => {
        _forEach(days, (day) => {
          if (isExceed(day)) return;
          reservableData[day].push({ showDate, date, time });
        });
      });
    });

    store.reservableData = reservableData;
  });

  return cloneData;
}

function addReservableTime(period) {
  const sizeNotEqualToZero = (i) => _size(i) !== 0;
  const checkObjectData = _cond([[_isPlainObject, sizeNotEqualToZero]]);
  const times = period.times;

  // 該時段訂位已滿
  if (!checkObjectData(times)) return;

  // 每一個訂位時段對應 1 - 10 人的可呈現訂位資料
  const reservableTime = {};
  _forEach(_range(10), (i) => (reservableTime[i + 1] = []));

  for (const [time, days] of Object.entries(times)) {
    days.forEach((i) => reservableTime[i].push(time));
  }
  period.reservableTime = reservableTime;

  return period;
}

function addDefaultPeriodNameAndId(data) {
  const period = {
    id: "1",
    periodName: "用餐時段",
    times: {},
    data
  };
  return period;
}

function mergeAllTimesObject(period) {
  _forEach(period.data, (i) => Object.assign(period.times, i.times));
  return period;
}

function addPeriodNameAndId(period, idx) {
  const { start, end, name } = period;
  const startStr = start.slice(0, 5);
  const endStr = end.slice(0, 5);
  const periodName = `${name}(${startStr}~${endStr})`;
  period.id = `${idx + 1}`;
  period.periodName = periodName;
  return period;
}

function uiCompanyData(data) {
  return {
    id: data.id,
    name: data.name,
    logo: data.logo,
    bannerInfo: data.banner_info,
    announcement: data.announcement,
    // 是否開啟「開放訪客訂位」設定
    isReservedByVisitor: data.is_reserved_by_visitor,
    earliestReserveDay: data.earliest_reserve_day,
    latestReserveDay: data.latest_reserve_day,
    startDate: data.earliest_reserve_date,
    endDate: data.latest_reserve_date,
  };
}
function uiStoreData(data) {
  return {
    id: data.id,
    name: data.name,
    address: data.address,
    tel: data.phone,
    announcement: data.announcement,
    logo: data.logo,
    bannerInfo: data.banner_info,
    startDate: data.earliest_reserve_date,
    endDate: data.latest_reserve_date,
    reservePeriods: data.reserve_periods,
    specialInfo: data.special_info,
    cancelLimit: data.cancel_limit,
    // 是否開放訂位
    isReserveOpen: data.is_reserve_open,
    // 是否允許訪客訂位
    isReservedByVisitor: data.is_reserved_by_visitor,
    // 是否顯示時段名稱
    isShowPeriodName: data.show_period_name,
    businessHours: businessHoursAdapter(data.business_hours),
    storeReserveTime: {},
    isShowWaitQueueLink: data.is_show_wait_queue_link,
    // 後台店家是否開啟訂位toggle
    isEnable: data.is_enable,
    //用餐目的
    targets: data.targets
  };
}

function getReserveInfoData(vuexData, apiData) {
  return {
    status: vuexData.status ?? false,
    adult: vuexData.adult ?? "大人2位",
    child: vuexData.child ?? "小孩0位",
    date: vuexData.date ?? apiData.earliest_reserve_date,
    time: vuexData.time ?? "",
  };
}

function uiReserveOrder(data) {
  return {
    name: data.name,
    sex: data.sex,
    phone: data.phone,
    diningPurpose: data.diningPurpose,
    otherNeeds: data.otherNeeds,
    remark: data.remark,
    privacy: data.privacy,
  };
}
function uiReserveOrderDetail(data) {
  return {
    status: data.status,
    link: data.link,
    name: data.name,
    sex: data.sex,
    adult: data.adult,
    child: data.child,
    time: data.time,
    date: data.date,
    phone: data.phone,
    sendKeep: data.send_keep,
  };
}
function businessHoursAdapter(obj) {
  const businessHourMap = new Map();
  Object.keys(obj).forEach((k) => {
    const key = Number(k);
    const weekday = obj[k];

    weekday.forEach((item) => {
      const startTime = `${item.start_time.split(":")[0]}:${
        item.start_time.split(":")[1]
      }`;
      const endTime = `${item.end_time.split(":")[0]}:${
        item.end_time.split(":")[1]
      }`;

      if (!businessHourMap.get(key)) businessHourMap.set(key, []);
      businessHourMap.get(key).push(`${startTime}~${endTime}`);
    });
  });

  return businessHourMap;
}

const reserve = {
  state: {
    companyData: {},
    storeData: {},
    reserveInfoData: {},
    reserveOrderData: {},
    reserveOrderDetail: {},
  },
  mutations: {
    // --- API ---
    SAVE_COMPANY_DATA: (state, payload) => {
      const companyData = uiCompanyData(payload);
      const reserveInfoData = getReserveInfoData(
        state.reserveInfoData,
        payload
      );
      state.companyData = { ...companyData };
      state.reserveInfoData = { ...reserveInfoData };
    },
    SAVE_COMPANY_RESERVE_TIME: (state, payload) => {
      const originData = payload;
      const cloneData = _cloneDeep(originData);
      const data = addReservableData(cloneData);
      state.companyData = { ...state.companyData, branchesReserveTime: data }
    },
    SAVE_STORE_DATA: (state, payload) => {
      const storeData = uiStoreData(payload);
      const reserveInfoData = getReserveInfoData(
        state.reserveInfoData,
        payload
      );
      state.storeData = { ...storeData };
      state.reserveInfoData = { ...reserveInfoData };
    },
    SAVE_STORE_RESERVE_TIME: (state, payload) => {
      const originData = payload;
      const isShowPeriodName = state.storeData.isShowPeriodName;
      const cloneData = _cloneDeep({ ...originData, isShowPeriodName });
      const toArray = (data) => [data];
      const showPeriodNamePipe = _flow([addPeriodNameAndId, addReservableTime]);
      const hiddenPeriodNamePipe = _flow([
        addDefaultPeriodNameAndId,
        mergeAllTimesObject,
        addReservableTime,
        toArray,
      ]);

      const handleCloneData = _cond([
        [(i) => _isEmpty(i.data), (i) => i.data],
        [(i) => i.isShowPeriodName, (i) => i.data.map((period, idx) => showPeriodNamePipe(period, idx))],
        [(i) => !i.isShowPeriodName, (i) => hiddenPeriodNamePipe(i.data)],
      ]);

      const data = handleCloneData(cloneData);
      const reserveTime = { ...cloneData, data };
      state.storeData.storeReserveTime = { ...reserveTime };
    },
    SAVE_RESERVE_ORDER_DETAIL: (state, payload) => {
      state.reserveOrderDetail = uiReserveOrderDetail(payload);
    },
    // --- UI ---
    SAVE_RESERVE_INFO_DATA: (state, payload) => {
      const { type, data } = payload;
      switch (type) {
        case "all":
          state.reserveInfoData = data;
          break;
        case "peopleAndDate":
          state.reserveInfoData.adult = data.adult || "";
          state.reserveInfoData.child = data.child || "";
          state.reserveInfoData.date = data.date || "";
          state.reserveInfoData.time = "";
          break;
        case "timeAndDate":
          state.reserveInfoData.date = data.date;
          state.reserveInfoData.time = data.time;
          break;
        case "time":
          state.reserveInfoData.time = data;
          break;
        case "status":
          state.reserveInfoData.status = data;
          break;
        default:
          break;
      }
    },
    SAVE_RESERVE_FORM_DATA: (state, payload) => {
      const formData = uiReserveOrder(payload);
      state.reserveOrderData = { ...formData };
    },
    RESET_RESERVE_VUEX_ALL_DATA: (state, payload) => {
      state.companyData = {};
      state.storeData = {};
      state.reserveInfoData = {};
      state.reserveOrderData = {};
      state.reserveOrderDetail = {};
    },
  },
  actions: {
    // --- API ---
    async GET_RESERVE_COMPANY_DATA({ commit }, payload) {
      const res = await API.GetCompanyData(payload);
      commit("SAVE_COMPANY_DATA", res.data.response);
    },
    async GET_COMPANY_RESERVE_TIME({ commit }, payload) {
      const res = await API.GetCompanyReserveTime(payload);
      commit("SAVE_COMPANY_RESERVE_TIME", res.data.response);
    },
    async GET_RESERVE_STORE_DATA({ commit }, payload) {
      const res = await API.GetStoreData(payload);
      commit("SAVE_STORE_DATA", res.data.response);
    },
    async GET_STORE_RESERVE_TIME({ commit }, payload) {
      const res = await API.GetStoreReserveTime(payload);
      commit("SAVE_STORE_RESERVE_TIME", res.data.response);
    },
    async POST_NEW_RESERVE_ORDER({ commit }, payload) {
      const res = await API.PostNewReserveOrder(payload);
      return res.data;
    },
    async GET_RESERVE_ORDER_DETAIL({ commit }, payload) {
      const res = await API.GetReserveOrderDetail(payload);
      commit("SAVE_RESERVE_ORDER_DETAIL", res.data.response);
    },
    async PUT_UPDATE_RESERVE_ORDER_STATUS({ commit }, payload) {
      const res = await API.PutUpdateReserveOrderStatus(payload);
      return res.data;
    },
    // --- UI ---
    SAVE_RESERVE_INFO_DATA({ commit }, payload) {
      commit("SAVE_RESERVE_INFO_DATA", payload);
    },
    SAVE_RESERVE_FORM_DATA({ commit }, payload) {
      commit("SAVE_RESERVE_FORM_DATA", payload);
    },
    VERIFY_RESERVE_INFO({ state, dispatch }) {
      let isPass = false;
      const { adult, child, date, time } = state.reserveInfoData;
      const adultNum = getNumberFromStr(adult);
      const childNum = getNumberFromStr(child);

      const people = adultNum === 0 && childNum === 0;
      if (date && time && !people) isPass = true;
      dispatch("SAVE_RESERVE_INFO_DATA", {
        type: "status",
        data: isPass,
      });
    },
    RESET_RESERVE_VUEX_ALL_DATA({ commit }) {
      commit("RESET_RESERVE_VUEX_ALL_DATA");
    },
  },
};

export default reserve;
