//* MODAL */
export const OPENED_MODAL = 'OPENED_MODAL'
export const CLOSED_MODAL = 'CLOSED_MODAL'
export const CLOSED_ALL_MODAL = 'CLOSED_ALL_MODAL'
export const SET_SHARE_MODE = 'SET_SHARE_MODE'

//* ROUTING */
export const REDIRECTED = 'REDIRECTED'
export const REPLACEDROUTE = 'REPLACEDROUTE'
export const REDIRECTED_TO_SPECIFY_ROUTE_AFTER_LOGIN = 'REDIRECTED_TO_SPECIFY_ROUTE_AFTER_LOGIN'

//* CONFIG */
export const FETCHED_CONFIG = 'FETCHED_CONFIG'
export const UPDATED_CURRENT_STORE = 'UPDATED_CURRENT_STORE'
export const SET_BACK_URL = 'SET_BACK_URL'

//* TABLE SETTINGS */
export const SET_TABLE = 'SET_TABLE'
export const RESET_TABLE = 'RESET_TABLE'

// * USER */
export const LOGGED_IN = 'LOGGED_IN'
export const LOGGED_IN_WITH_FB = 'LOGGED_IN_WITH_FB'
export const LOGGED_IN_WITH_LINE = 'LOGGED_IN_WITH_LINE'
export const LOGGED_OUT = 'LOGGED_OUT'
export const VERIFY_CAPTCHA = 'VERIFY_CAPTCHA'

export const UPDATED_USER = 'UPDATED_USER'
export const SET_LINE_PROFILE = 'SET_LINE_PROFILE'

// * MENU */
export const FETCHED_MENU = 'FETCHED_MENU'
export const RESET_MENU = 'RESET_MENU'

// * BRANCH INFO */
export const FETCHED_BRANCH_LIST = 'FETCHED_BRANCH_LIST'
export const FETCHED_BRANCH_INFO = 'FETCHED_BRANCH_INFO'

// * ORDER MENU */
export const RESET_ORDER_LIST = 'RESET_ORDER_LIST'
export const RESET_ORDER_MENU = 'RESET_ORDER_MENU'
export const FETCHED_ORDER_MENU_LIST = 'FETCHED_ORDER_MENU_LIST'
export const FETCHED_NEWEST_ORDER_MENU = 'FETCHED_NEWEST_ORDER_MENU'
export const FETCHED_ORDER_MENU_DETAIL = 'FETCHED_ORDER_MENU_DETAIL'
export const UPDATE_ORDER_MENU_DETAIL_IS_EDITED = 'UPDATE_ORDER_MENU_DETAIL_IS_EDITED'
export const UPDATE_ORDER_MENU_DELETED_IDS = 'UPDATE_ORDER_MENU_DELETED_IDS'
export const GET_ORDER_MENU_SERVICE_CHARGE = 'GET_ORDER_MENU_SERVICE_CHARGE'
export const DELETED_ORDER_MENU = 'DELETED_ORDER_MENU'
export const CREATED_ORDER_MENU = 'CREATED_ORDER_MENU'
export const FETCHED_ORDER_LIST = 'FETCHED_ORDER_LIST'
export const FETCHED_ORDER_BY_ID = 'FETCHED_ORDER_BY_ID'
export const FETCHED_JKO_ORDER_BY_SN = 'FETCHED_JKO_ORDER_BY_SN'
export const REPURCHASED_ORDER = 'REPURCHASED_ORDER'

// * CHECKOUT */
export const CALL_API_CHECKOUT_SUCCESS = 'CALL_API_CHECKOUT_SUCCESS'

// * MARKETING */
export const SET_SELECTED_EXCHANGE_ITEMS = 'SET_SELECTED_EXCHANGE_ITEMS'
export const SET_EXCHANGE_COST = 'SET_EXCHANGE_COST'

// * CREDIT CARD */
export const SELECTED_CARD = 'SELECTED_CARD'
