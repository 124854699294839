import generateShareRouter from './shareRouters/shareRouter';

const companyStoreRouter = {
  path: '/:company/:store',
  component: () => import('@/views/AppMain/AppMain.vue'),
  children: [
    {
      path: '',
      name: 'index.store',
      redirect: 'menu',
    },
    {
      path: 'menu',
      name: 'menu.store',
      meta: { level: 'store', shouldRedirectAfterLogin: true, },
      component: () => import('@/views/menu/index.vue'),
    },
    {
      path: 'checkout',
      component: () => import('@/views/checkout/Checkout.vue'),
      children: [
        {
          path: '',
          redirect: 'step1',
          name: 'checkout.store',
        },
        {
          path: 'step1',
          name: 'checkoutstep1.store',
          meta: { level: 'store' },
          component: () => import('@/views/checkout/step1/CheckoutStep1.vue'),
        },
        {
          path: 'step2',
          name: 'checkoutstep2.store',
          meta: { level: 'store' },
          component: () => import('@/views/checkout/step2/CheckoutStep2.vue'),
        },
        {
          path: 'autoconfirm',
          name: 'checkoutautoconfirm.store',
          meta: { level: 'store' },
          component: () => import('@/views/checkout/autoconfirm/AutoConfirm.vue'),
        },
      ],
    },
    // 內用後結 餐點紀錄
    {
      path: 'postCheckout/detail',
      name: `PostCheckoutOrderDetail.store`,
      meta: { level: 'store', shouldRedirectAfterLogin: false, },
      component: () => import('@/views/OrderDetail/PostCheckoutOrderDetail.vue')
    },
    ...generateShareRouter('store'),
  ],
};

export default companyStoreRouter;
