
import { setTakeMethodLS, setAddressLS } from "@/utils/localStorageHandler";
import isAddressValid from "@/utils/address";
import { isNumber, isObject } from "lodash-es";

const moment = require('moment'); // require

// 取餐方式mock
const mockTakeMethods = [
  {
    "id": 1,
    "key": "delivery",
    "value": "外送"
  },
  {
    "id": 2,
    "key": "take_out",
    "value": "自取"
  },
  {
    "id": 3,
    "key": "for_here",
    "value": "內用"
  }
]

const isPassToday = (timeObj) => {
  // 今日售完的判斷是以到明天 5am
  const time = `${timeObj.date.date_index} ${timeObj.time.index}`
  const selectedTime = moment(time, "YYYY-MM-DD hh-mm:ss");
  const tomorrowFiveAm =
    moment()
      .add(1, 'days')
      .hours(5)
      .minutes(0)
      .seconds(0)
      .milliseconds(0);
  const isSameOrAfter = selectedTime.isSameOrAfter(tomorrowFiveAm)

  return isSameOrAfter;
};

const orderSettings = {
  state: {
    takeMethodInUse: {},
    addressInUse: "",
    pickUp: {
      date: {},
      time: {},
    },
    isToday: true,
  },
  mutations: {
    SET_TAKE_METHOD_IN_USE(state, payload) {
      state.takeMethodInUse = payload;
    },
    SET_ADDRESS_IN_USE(state, payload) {
      state.addressInUse = payload;
    },
    CLEAR_ADDRESS_IN_USE(state) {
      state.addressInUse = "";
    },
    SET_PICK_UP_DATE_IN_USE(state, payload) {
      state.pickUp.date = { ...payload };
      state.isToday = !isPassToday(state.pickUp)
    },
    SET_PICK_UP_TIME_IN_USE(state, payload) {
      state.pickUp.time = { ...payload };
      state.isToday = !isPassToday(state.pickUp)
    },
    CLEAR_PICK_UP_DATE_IN_USE(state, payload) {
      state.pickUp.date = {};
    },
    CLEAR_PICK_UP_TIME_IN_USE(state, payload) {
      state.pickUp.time = {};
    },
  },
  actions: {
    setTakeMethodInUse({ commit, getters }, takeMethod) {
      let storeTakeMethod;
      if (isNumber(takeMethod)) {
        // 輸入id
        // !!結帳頁專用
        storeTakeMethod = Object.values(getters.pickup).find(method => method.id === takeMethod)
      } else if (isObject(takeMethod)) {
        // 輸入完整取餐方式
        storeTakeMethod = takeMethod;
      } else {
        // force return
        return;
      }
      commit('SET_TAKE_METHOD_IN_USE', storeTakeMethod);
      // BUG JKO APP 走揪團時，就會在這邊噴錯。(造成無法走到 拿取菜單資料的Function)
      setTakeMethodLS({ companyId: getters.companyId, takeMethodString: storeTakeMethod.key })
    },
    setAddressInUse({ commit, getters }, address) {
      // 不能用的地址，則不寫入vuex 及 LS
      if (!isAddressValid(address)) return;
      commit('SET_ADDRESS_IN_USE', address);
      setAddressLS({ companyId: getters.companyId, address })
    },
    clearAddressInUse({ commit, getters }) {
      commit('CLEAR_ADDRESS_IN_USE');
      setAddressLS({ companyId: getters.companyId, address: "" });
    },
    setPickUpDateInUse({ commit }, date) {
      commit('SET_PICK_UP_DATE_IN_USE', date);
    },
    setPickUpTimeInUse({ commit }, time) {
      commit('SET_PICK_UP_TIME_IN_USE', time);
    },
    clearPickUpDateInUse({ commit }) {
      commit('CLEAR_PICK_UP_DATE_IN_USE');
    },
    clearPickUpTimeInUse({ commit }) {
      commit('CLEAR_PICK_UP_TIME_IN_USE');
    },
  },
};

export default orderSettings;
