import domain from './domain';
import http from '@/utils/http';
import { appToken, getToken } from '@/utils/auth';
import store from '@/store/index';
import getI18nKey from './getLangValue';

export default {
  fetchOrderMenuList() {
    const t = Date.now()
    return http.get(`${domain.new}/order_menu?t=${t}`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: getToken(),
      },
    });
  },
  fetchNewestOrderMenu(store_id) {
    const t = Date.now()
    return http.get(`${domain.new}/store/${store_id}/order_menu/latest?t=${t}`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: getToken(),
      },
    });
  },
  fetchOrderMenuDetail({
    order_menu_id
  }) {
    const t = Date.now()
    return http.get(`${domain.new}/order_menu/${order_menu_id}?t=${t}`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
  deleteOrderMenu(order_menu_id) {
    const token = store.getters.isLoggedIn ? getToken() : appToken;

    return http.delete(`${domain.new}/order_menu/${order_menu_id}`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: token,
      },
    });
  },
  createOrderMenu(store_id) {
    const token = store.getters.isLoggedIn ? getToken() : appToken;
    let params = {
      lang: getI18nKey('api_lang')
    };
    const { isPostCheckout, postCheckoutOrderSN } = store.getters;
    // 後結帳 isPostCheckout 要多帶
    if (isPostCheckout) {
      params['order_sn'] = postCheckoutOrderSN
    }
    return http.post(`${domain.new}/store/${store_id}/order_menu`, params, {
      headers: {
        Authorization: token,
      },
    });
  },
  bindOrderMenu(order_menu_id) {
    return http.post(`${domain.new}/order_menu/${order_menu_id}/binding`, {}, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: getToken(),
      },
    });
  },
  updateOrderMenuShareSetting({
    store_id,
    order_menu_id,
    data,
  }) {
    return http.put(`${domain.new}/store/${store_id}/order_menu/${order_menu_id}/share`, data, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: getToken(),
      },
    });
  },
  checkOrderMenuStatus({
    store_id,
    order_menu_id,
    prefer_datetime = "",
    from,
    pickup_type
  }) {
    
    let payload = `prefer_datetime=${prefer_datetime}&pickup_type=${pickup_type}&from=${from}`;
    if (!pickup_type) {
      payload = `prefer_datetime=${prefer_datetime}&from=${from}`;
    }
    
    return http.get(`${domain.new}/store/${store_id}/order_menu/${order_menu_id}/check?${payload}`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
  addItemToOrderMenu({
    store_id,
    order_menu_id,
    data,
  }) {
    const token = store.getters.isLoggedIn ? getToken() : appToken;

    return http.post(`${domain.new}/store/${store_id}/order_menu/${order_menu_id}/item`, data, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: token,
      },
    });
  },
  editItemInOrderMenu({
    store_id,
    order_menu_id,
    order_menu_detail_id,
    data,
  }) {
    const token = store.getters.isLoggedIn ? getToken() : appToken;

    return http.put(`${domain.new}/store/${store_id}/order_menu/${order_menu_id}/item/${order_menu_detail_id}`, data, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: token,
      },
    });
  },
  // 以下訂單紀錄
  fetchOrderList(page) {
    const t = Date.now()
    return http.get(`${domain.new}/order?page=${page}&t=${t}`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: getToken(),
      },
    });
  },
  fetchOrderById({ order_id, from }) {
    const t = Date.now()
    const token = store.getters.isLoggedIn ? getToken() : appToken;

    return http.get(`${domain.new}/order/${order_id}?from=${from}&t=${t}`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: token,
      },
    });
  },
  // JKO Pay 取得單筆訂單
  fetchJkoOrderBySn(data) {
    const { platform, order_sn } = data;
    return http.get(`${domain.new}/platform/${platform}/order/${order_sn}`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
  // 重新訂購
  repurchaseOrder({
    storeId,
    orderId,
  }) {
    return http.post(`${domain.new}/store/${storeId}/order/${orderId}/repurchase`, {}, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: getToken(),
      },
    });
  },
  // 被揪人點餐前 preview
  previewOrderContent({
    store_id,
    order_menu_id,
    data
  }) {
    return http.post(`${domain.new}/store/${store_id}/order_menu/${order_menu_id}/item/preview`, data, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
  fetchOrderCallNoById(order_id) {
    const t = Date.now()

    return http.get(`${domain.new}/order/${order_id}/call_no?t=${t}`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  }
}
