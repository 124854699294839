import domain from './domain';
import http from '@/utils/http';
import { appToken } from '@/utils/auth';
import getI18nKey from './getLangValue';

export default {
  fetchBranchInfo(data) {
    const reqObj = { ...data };
    delete reqObj.company_id;
    if (data.store_id < 1) {
      delete reqObj.store_id;
    }
    if (reqObj.from === 'QRORDER' && !reqObj.store_id) return
    return http.get(`${domain.new}/company/${data.company_id}/config`, {
      params: {
        lang: getI18nKey('api_lang'),
        ...reqObj,
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
  fetchBranchList(data) {
    const reqObj = { ...data };
    delete reqObj.company_id;
    if (data.store_id < 1) {
      delete reqObj.store_id;
    }
    return http.get(`${domain.new}/company/${data.company_id}/branches`, {
      params: {
        lang: getI18nKey('api_lang'),
        ...reqObj,
      },
      headers: {
        Authorization: appToken,
      },
      cache: {
        maxAge: 1000 * 60 * 5, // 5 min cache
        exclude: { query: false } // set to false to make request with query can use cache
      },
    });
  },
}
