import Vue from 'vue';
import Vuex from 'vuex';
import app from './modules/app';
import branch from './modules/branch';
import menu from './modules/menu';
import orderMenu from './modules/orderMenu';
import checkout from './modules/checkout';
import marketing from './modules/marketing';
import geolocation from './modules/geolocation';
import config from './modules/config';
import creditCards from './modules/creditCards';
import deliveryConditions from './modules/deliveryConditions';
import user from './modules/user';
import addressModal from './modules/addressModal';
import line from './modules/line';
import routing from './modules/routing';
import modal from './modules/modal';
import tableSettings from './modules/tableSettings';
import postCheckout from './modules/postCheckout';
import orderSettings from './modules/orderSettings';
import pickUpDateAndTime from './modules/pickUpDateAndTime';
import reserve from './modules/reserve';
import pointExchange from './modules/pointExchange';
import jko from './modules/jko';
import wallet from './modules/wallet';
import waiting from './modules/waiting';

import getters from './getters';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    branch,
    menu,
    orderMenu,
    checkout,
    marketing,
    geolocation,
    config,
    creditCards,
    deliveryConditions,
    user,
    addressModal,
    line,
    routing,
    modal,
    tableSettings,
    postCheckout,
    orderSettings,
    pickUpDateAndTime,
    reserve,
    pointExchange,
    jko,
    wallet,
    waiting
  },
  getters,
});

export default store;
