const deliveryConditions = {
  state: {
    match: {},
  },
  mutations: {
    CLEAR_MATCH_CONDITION: (state) => {
      state.match = {};
    },
    SET_MATCH_CONDITION: (state, payload) => {
      state.match = { ...payload };
    },
    SET_NO_MATCH_CONDITION: (state) => {
      state.match = null;
    },
  },
  actions: {
    clearMatchCondition({ commit }) {
      commit('CLEAR_MATCH_CONDITION');
    },
    setMatchCondition({ commit }, payload) {
      commit('SET_MATCH_CONDITION', payload);
    },
    setNoMatchCondition({ commit }) {
      commit('SET_NO_MATCH_CONDITION');
    },
  },
};

export default deliveryConditions;
