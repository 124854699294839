<template>
  <div v-if="configIsReady" id="app">
    <div class="app-wrapper">
      <el-container>
        <AppNavbar
          v-if="
            (!isInLiffEnv || isQrOrder) &&
            (branch.isImenuAvailable || branch.isQrOrderEnable) &&
            !isWemo &&
            !isJko
          "
        />
        <div
          class="app-main"
          :class="{
            follow: isFollow,
            noMarginTop: (isLIFF && !isQrOrder) || isWemo || isJko,
          }"
        >
          <AppMain />
        </div>
      </el-container>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "App",
  data() {
    return {
      isInLiffEnv: false,
      configIsReady: false,
    };
  },
  components: {
    AppMain: () => import("@/views/AppMain/AppMain"),
    AppNavbar: () => import("@/views/AppNavbar/AppNavbar"),
  },
  computed: {
    ...mapGetters([
      "isJko",
      "isLIFF",
      "isWemo",
      "isFollow",
      "isQrOrder",
      "branch",
      "config",
    ]),
  },
  methods: {
    // 使用官方語法確認當前運行環境是否在LIFF
    // VUEX裡的LIFF是對登入LINE與否進行判斷
    checkLiffEnv() {
      return (this.isInLiffEnv = liff.isInClient());
    },
  },
  mounted() {
    this.checkLiffEnv();
    let routerName = this.$route.name;
    let names = [
      "preview",
      "preview/pos",
      "404",
      "no-enable",
      "error",
      "timeout",
      "notice",
      "shutdown",
    ];
    this.configIsReady = this.config.id || names.includes(routerName);
    setTimeout(() => {
      this.configIsReady = true;
    }, 1000);
  },
  watch: {
    "config.is_birthday_require": {
      handler(newVal, old) {
        localStorage.setItem("is_birthday_require", newVal);
      },
      deep: true,
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.follow {
  margin-top: calc(64px + 40px) !important;
}
.app-main {
  margin-top: 64px;
  width: 100%;
}
.noMarginTop {
  margin-top: 0px !important;
}
</style>
