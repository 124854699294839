import store from '@/store/index';
import domain from './domain';
import axios from '@/utils/http';
import { getToken, appToken } from '@/utils/auth';
import getI18nKey from './getLangValue';

const checkout = {
  /**
   * [POST] 結帳
   * @param {Object Array} campaigns[] 使用者選取的優惠
   * @param {String} campaigns[].sn 優惠序號
   * @param {Number} campaigns[].type 優惠類型 (1:結帳活動 2:優惠券)
   * ...其餘結帳單資料
   */
  callApiCheckout({
    store_id,
    order_menu_id,
    data,
  }) {
    const token = store.getters.isLoggedIn ? getToken() : appToken;
    return axios.post(`${domain.new}/store/${store_id}/order_menu/${order_menu_id}/checkout`, data, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: token,
      },
    });
  },

  /** API 7.4.3 Close URL */
  close(orderId) {
    return axios.get(`${domain.new}/api/payment/close/${orderId}`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: `${getToken()}`,
      },
    });
  },
  searchDonateList(query = "") {
    return axios.get(`${domain.new}/invoice/donate_no?fuzzy_num_and_name=${query}`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
  shippingFee(data) {
    return axios.get(`${domain.new}/shipping_fee`, {
      params: {
        lang: getI18nKey('api_lang'),
        ...data
      },
      headers: {
        Authorization: `${getToken()}`,
      },
    });
  },
};

export default checkout;
