import { Base64 } from 'js-base64';
import domain from './domain';
import http from '@/utils/http';
import { getToken, appToken, generateApiToken } from '@/utils/auth';
import getI18nKey from './getLangValue';
import store from '@/store/index';

export default {
  login({
    account,
    password,
    companyId,
    storeId,
  }) {
    const data = {
      platform_uuid: account,
      platform_token: Base64.encode(password),
      company_id: companyId,
      store_id: storeId,
    }
    return http.post(`${domain.new}/member/login`, data, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
  logout() {
    return http.post(
      `${domain.new}/member/logout`, {},
      {
        headers: {
          Authorization: getToken(),
        },
      },
    );
  },
  thirdPartyLogin(data) {
    return http.post(`${domain.new}/member/thirdparty/login`, data, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
  thirdPartySignUp(data) {
    return http.post(`${domain.new}/member/thirdparty/register`, data, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
  signUp({
    account,
    password,
    companyId,
    store_id,
  }) {
    const data = {
      platform_uuid: account,
      platform_token: Base64.encode(password),
      company_id: companyId,
      store_id,
    }
    return http.post(`${domain.new}/member/register`, data, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
  signUpWithNewMobileNumber(data) {
    return http.post(`${domain.new}/member/phone`, data, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
  verifyCaptcha(data) {
    return http.post(`${domain.new}/member/captcha/verify`, data, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
  checkCaptcha(data) {
    return http.post(`${domain.new}/member/captcha/check`, data, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
  fetchCaptcha(data) {
    return http.post(`${domain.new}/member/captcha`, data, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
  modifyPassword(data) {
    data.password = Base64.encode(data.password);
    data.token = generateApiToken(data.account + data.password + data.company_id);
    return http.post(`${domain.new}/member/password/change`, data, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
  forgetPassword(data) {
    data.new_token = Base64.encode(data.new_token);
    return http.post(`${domain.new}/member/password/forget`, data, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
  fetchUser() {
    return http.get(`${domain.new}/member/info`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: getToken(),
      },
    });
  },
  fetchUserDetail(isQrOrder) {
    let url = `${domain.new}/member/info?type=detail`;
    if (isQrOrder) {
      url = `${domain.new}/member/info?type=detail&from=QRORDER`;
    }
    return http.get(url, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: getToken(),
      },
    });
  },
  updateUser(data) {
    if (data.hasOwnProperty('lang')) {
      store.getters.user.lang = data.lang;
      data.lang = (data.lang === 'zh-tw') ? 'zh_TW' : data.lang;
    }
    return http.post(`${domain.new}/member/info`, data, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: getToken(),
      },
    });
  },
  soPowerThirdLogin({
    companyId,
    line_id,
    type,
  }) {
    return http.get(`${domain.new}/company/${companyId}/member?line_id=${line_id}&type=${type}`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  }
}
