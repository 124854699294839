import vuexStore from '@/store/index';
import { setToken, getToken } from "@/utils/auth";
import { initLiff } from "@/utils/line";
import Cookies from 'js-cookie';
import { getSS } from "@/utils/sessionStorage";
import { useSetNavLeftButton, useSetNavTitle } from '@/utils/jko'

const setConfig = async (companyParam) => {
  if (process.env.NODE_ENV === "production") {
    const config = window.__imenu_config__;
    const company = window.__imenu_company_slug;

    vuexStore.dispatch('setConfig', {
      ...config,
      company,
    });
  } else {
    if (!vuexStore.getters.config.id) {
      await vuexStore.dispatch('fetchConfig', {
        company: companyParam
      });
    }
  }
}

/**
 * 初次載入時，若Cookie中的User Token存在，則換成information存入Vuex
 * @returns {undefined}
 */
const setLogIn = async (isLIFF, isWemo, isJko, to) => {
  let { isLoggedIn } = vuexStore.getters;
  if (isLoggedIn) return;

  if (isWemo && to.query.login_token) {
    setToken(to.query.login_token);
  }

  if (isJko && getToken()) {
    await vuexStore.dispatch('getUserDetail');
    return;
  }

  // do Different Things depend on isLIFF
  if (isLIFF) {
    const profile = await window.liff.getProfile();
    const accessToken = await window.liff.getAccessToken();
    await vuexStore.dispatch('thirdLoginWithLine', {
      profile,
      accessToken,
      disableAutoRedirect: true,
      redirectCookiePath: to.fullPath,
    });
  } else {
    getToken() && await vuexStore.dispatch('getUserDetail');
  }
}

/**
 * 將路由的店家參數 設定進vuex，
 * @param storeParam
 * @param storeSlugQuery
 * @returns {undefined} Did update the store
 */
const setCurrentBranch = async (storeParam, storeSlugQuery, isWemo, next) => {
  /**
   * {storeParam|storeSlugQuery}
   * storeParam: Nomal Route
   * storeSlugQuery: Line DirectBack to App
   */

  if (!storeParam && !storeSlugQuery) return;
  const currentBranch = vuexStore.getters.config.branches[storeParam] || vuexStore.getters.config.branches[storeSlugQuery];
  if (!currentBranch) return next({ path: `/${vuexStore.getters.config.slug}/branches`, replace: true });
  // storeId 不一樣代表換店家了
  if (vuexStore.getters.storeId !== currentBranch.id) {
    // 清空原店家的菜單資料
    vuexStore.dispatch('resetMenu');
    // 撈取當前店家的資料
    const branch = await vuexStore.dispatch('fetchBranchInfo', {
      company_id: vuexStore.getters.companyId,
      store_id: currentBranch.id,
      from: 'IMENU',
      isWemo,
    });
    // 更新到當前的店家資料
    vuexStore.dispatch('updateCurrentStore', {
      id: currentBranch.id,
      name: currentBranch.name,
      slug: storeParam || storeSlugQuery,
      qrcode: currentBranch.qrcode,
      google_api_key: currentBranch.google_api_key,
    });
    return branch;
  }
}

const checkIsComanySlugValid = (companyParam) => {
  const isConfigValid = window.__imenu_config__ && window.__imenu_config__.id;
  const isCompanySlugValid = window.__imenu_company_slug;

  let result = Boolean(isConfigValid && isCompanySlugValid);

  if (process.env.NODE_ENV !== 'production') {
    result = Boolean(companyParam);
  }
  return result;
}

const iMenuProcess = async (to, from, next) => {
  const { company: companyParam, store: storeParam } = to.params;
  const { store_slug: storeSlugQuery } = to.query;

  const isJko = to.query.from && to.query.from.toUpperCase() === 'JKO' || Cookies.get('IS_JKO');

  const isComanySlugValid = checkIsComanySlugValid(companyParam);
  const errorPage = !isJko ? '404' : 'jko_maintenance';
  if (!isComanySlugValid) return next({ name: errorPage });

  const isLIFF = await initLiff();
  vuexStore.dispatch('setIsLIFF', isLIFF);

  const isWemo = to.query.from && to.query.from.toUpperCase() === "WEMO" || Cookies.get('IS_WEMO');
  if (isWemo) vuexStore.dispatch('setIsWemo', true);

  await setConfig(companyParam);

  const isJkoAuthorized = getSS('JKO_AUTHORIZED');
  if (isJko) {
    vuexStore.dispatch('setIsJko', true);
    useSetNavLeftButton();
    useSetNavTitle();
  }
  if (isJko && !isJkoAuthorized || isJko && !getToken()) {
    /**
     * 需要跑JKO授權流程有兩個情境
     * isJkoAuthorized -> 消失
     * login_token -> 效期到 or 尚未獲取
     * 
     * 不直接清除login_token：原本的 login_token 是用來打API的 (改動幅度大，且如果噴403會直接跑到JKO維護頁面)
     * 使用SessionStorage：讓關閉頁面時自動清除，避免 onbeforeunload 在特定環境無法正常運作的狀況發生。
     */
    vuexStore.dispatch('SET_ORIGIN_URL_PATH', to.fullPath);
    return next({ name: 'jko_before_authorize', query: { storeParam, storeSlugQuery } });
  }

  const branch = await setCurrentBranch(storeParam, storeSlugQuery, isWemo, next);

  // 當有店家資料，但該店家卻無啟用時 （可能合約到期或是其他狀況）
  if (branch && !branch.isImenuAvailable) {
    return next({
      name: "shutdown",
      replace: true,
    });
  }

  await setLogIn(isLIFF, isWemo, isJko, to);
  return next();
}



export default iMenuProcess;