const followRouter = {
  path: '/follow/:followToken',
  component: () => import('@/views/AppMain/AppMain.vue'),
  children: [
    {
      path: '',
      redirect: { name: 'followMenu' },
      meta: { isFollow: true },
    },
    {
      path: 'menu',
      component: () => import('@/views/menu/index.vue'),
      name: 'followMenu',
      meta: { isFollow: true },
    },
    {
      path: '*',
      redirect: { name: 'followMenu' },
      meta: { isFollow: true },
    },
  ],
};

export default followRouter;
