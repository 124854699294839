function generateShareRouter(level) {
  return [
    // === User Terms & Privacy Policy ===
    {
      path: 'userterms',
      name: `userterms.${level}`,
      meta: { level, shouldRedirectAfterLogin: false, },
      component: () => import('@/views/UserTermsAndPrivacyPolicy/UserTerms'),
    },
    {
      path: 'privacypolicy',
      name: `privacypolicy.${level}`,
      meta: { level, shouldRedirectAfterLogin: false, },
      component: () => import('@/views/UserTermsAndPrivacyPolicy/PrivacyPolicy.vue'),
    },
    // === Login And Signup ===
    {
      path: 'login',
      name: `login.${level}`,
      meta: { level, shouldRedirectAfterLogin: false, },
      component: () => import('@/views/LoginAndSignup/Login.vue'),
    },
    {
      path: 'linelogin',
      name: `linelogin.${level}`,
      meta: { level, shouldRedirectAfterLogin: false, },
      component: () => import('@/views/LoginAndSignup/LineLogin.vue'),
    },
    {
      path: 'signup',
      name: `signup.${level}`,
      meta: { level, shouldRedirectAfterLogin: false, },
      component: () => import('@/views/LoginAndSignup/SignUp.vue'),
    },
    {
      path: 'signup/success',
      name: `signupSuccess.${level}`,
      meta: { level, shouldRedirectAfterLogin: false, },
      component: () => import('@/views/LoginAndSignup/SignUpSuccess.vue'),
    },
    {
      path: 'captcha',
      name: `captcha.${level}`,
      meta: { level, shouldRedirectAfterLogin: false, },
      component: () => import('@/views/LoginAndSignup/Captcha.vue'),
    },
    {
      path: 'thirdSignup',
      name: `thirdSignup.${level}`,
      meta: { level, shouldRedirectAfterLogin: false, },
      component: () => import('@/views/LoginAndSignup/ThirdSignUp.vue'),
    },
    {
      path: 'forgetpassword',
      name: `forgetpassword.${level}`,
      meta: { level, shouldRedirectAfterLogin: false, },
      component: () => import('@/views/LoginAndSignup/ForgetPassword.vue'),
    },
    {
      path: 'resetpassword',
      name: `resetpassword.${level}`,
      meta: { level, shouldRedirectAfterLogin: false, },
      component: () => import('@/views/LoginAndSignup/ResetPassword.vue'),
    },
    {
      path: 'resetpassword/success',
      name: `resetpasswordSuccess.${level}`,
      meta: { level, shouldRedirectAfterLogin: false, },
      component: () => import('@/views/LoginAndSignup/ResetPasswordSuccess.vue'),
    },
  ];
}

export default generateShareRouter;
