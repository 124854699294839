import Cookies from "js-cookie";

const app = {
  state: {
    showApiError: true,
    showLoading: true,
    loadingCounter: 0,
    isReserve: false,
    isQrOrder: false,
    isFollow: false,
    isLIFF: false,
    isWemo: false,
    isJko: false,
    autoReloadDetail: false,
    lineApiTimer: 0,
    apiLoadingStatus: false,
  },
  mutations: {
    SET_SHOW_API_ERROE: (state, flag) => {
      state.showApiError = flag;
    },
    SET_SHOW_LOADING: (state, flag) => {
      state.showLoading = flag;
      if (!flag) state.loadingCounter = 0;
    },
    SET_LOADING_COUNTER: (state, count) => {
      state.loadingCounter = count;
    },
    SET_IS_RESERVE: (state, flag) => {
      state.isReserve = flag;
    },
    SET_IS_QR_ORDER: (state, flag) => {
      state.isQrOrder = flag;
    },
    SET_IS_FOLLOW: (state, flag) => {
      state.isFollow = flag;
    },
    SET_IS_LIFF: (state, flag) => {
      state.isLIFF = flag;
    },
    SET_IS_WEMO: (state, flag) => {
      state.isWemo = flag;
    },
    SET_IS_JKO: (state, flag) => {
      state.isJko = flag;
    },
    SET_AUTO_RELOAD_DETAIL: (state, flag) => {
      state.autoReloadDetail = flag;
    },
    SET_LINE_API_READY_STATUS(state, value) {
      state.lineApiTimer = value;
    },
    SET_API_LOADING_STATUS(state, value) {
      state.apiLoadingStatus = value;
    }
  },
  actions: {
    setShowApiError({ commit }, flag) {
      commit("SET_SHOW_API_ERROE", flag);
    },
    setShowLoading({ commit }, flag) {
      commit("SET_SHOW_LOADING", flag);
    },
    setLoadingCounter({ commit }, count) {
      commit("SET_LOADING_COUNTER", count);
    },
    setIsReserve({ commit }, payload) {
      commit("SET_IS_RESERVE", payload);
    },
    setIsQrOrder({ commit }, payload) {
      commit("SET_IS_QR_ORDER", payload);
    },
    setIsFollow({ commit }, flag) {
      commit("SET_IS_FOLLOW", flag);
    },
    setIsLIFF({ commit }, flag) {
      commit("SET_IS_LIFF", flag);
    },
    setIsWemo({ commit }, flag) {
      const inTenMinutes = new Date(new Date().getTime() + 10 * 60 * 1000);
      Cookies.set("IS_WEMO", 1, { expires: inTenMinutes });
      commit("SET_IS_WEMO", flag);
    },
    // 預估 JKO APP User 只會停留最多 30分鐘
    setIsJko({ commit }, flag) {
      const inThirtyMinutes = new Date(new Date().getTime() + 30 * 60 * 1000);
      Cookies.set("IS_JKO", 1, { expires: inThirtyMinutes });
      commit("SET_IS_JKO", flag);
    },
    setAutoReloadDetail({ commit }, flag) {
      commit("SET_AUTO_RELOAD_DETAIL", flag);
    },
    async setLineApiReadyStatus({ commit }, value) {
      commit("SET_LINE_API_READY_STATUS", value);
    },
    setApiLoadingStatus({ commit }, value) {
      commit("SET_API_LOADING_STATUS", value);
    }
  }
};

export default app;
