import { useMessage } from "@/utils/reserve/elementUI";

/** 分享好友功能 */
export function shareMessageToFriends(obj) {
  const {
    storeName,
    storeAddress,
    storeTel,
    name,
    sex,
    phone,
    date,
    time,
    people
  } = obj;

  window.liff
    .shareTargetPicker([
      {
        type: "text",
        text: `嗨~ 我在 【${storeName}】 預訂了 「${date} ${time} ${people}」 的位子，與你分享訂位資訊如下:
      \n▪ 訂位人：${name}${sex}\n▪ 訂位人電話：${phone}\n▪ 店家名稱：${storeName}\n▪ 店家電話：${storeTel}\n▪ 店家地址：${storeAddress}`
      }
    ])
    .then(res => {
      if (res?.status === "success") {
        useMessage("success", "分享成功");
      } else {
        // 打開分享好友視窗後，直接關閉。
        useMessage("warning", "未知的錯誤");
      }
    })
    .catch(error => {
      useMessage("error", error);
      console.error(error);
    });
}

/** 獲取LIFF使用者相關資料 */
export async function getLiffUserData(isLIFF) {
  console.log("[LIFF] 當前是否為LIFF環境", isLIFF);
  if (!isLIFF) liff.login();
  console.log("[LIFF] Data", liff);
  console.log("[LIFF] SDK版本", liff.getVersion());
  console.log("[LIFF] 是否登入 LINE 帳號", liff.isLoggedIn());
  console.log("[LIFF] 回傳使用者作業系統", liff.getOS());
  console.log("[LIFF] 使用者LINE版本", liff.getLineVersion());
  console.log("[LIFF] 使用者類型", liff.getContext());
  if (liff.isLoggedIn()) {
    const userProfile = await liff.getProfile();
    console.log("[LIFF] 使用者公開資料", await liff.getProfile());
    console.log("[LIFF] 使用者Line userID", userProfile.userId);
  }
}
