// 候位流程專用 API
import domain from "./domain";
import axios from "@/utils/http";
import { getToken, appToken } from "@/utils/auth";
import getI18nKey from './getLangValue';

export default {
  // GET 取得候位設定
  async GetWaitingInfo({ storeId }) {
    const res = await axios.get(`${domain.new}/wait_queue/store/${storeId}/info`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
    const { response } = res.data;
    return response
  },
  // POST 新增候位
  PostWaitingCreate({ storeId, data }) {
    return axios.post(`${domain.new}/wait_queue/store/${storeId}/create`, data, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
  // GET 根據link取得候位資料
  async GetWaitingSearch({ storeId, link }) {
    const res = await axios.get(`${domain.new}/wait_queue/store/${storeId}/search`, {
      params: {
        lang: getI18nKey('api_lang'),
        link
      },
      headers: {
        Authorization: appToken,
      },
    });
    const { response } = res.data;
    return response;
  },
  // GET 取得候位狀況
  async GetWaitingCheck({ storeId, waitQueueLogId }) {
    const res = await axios.get(`${domain.new}/wait_queue/store/${storeId}/${waitQueueLogId}/check`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
    const { response } = res.data;
    return response;
  },
  // PUT 更新候位
  async PutWaitingUpdate({ storeId, waitQueueLogId, data }) {
    const res = await axios.put(`${domain.new}/wait_queue/store/${storeId}/${waitQueueLogId}/update`, data, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: getToken() || appToken,
      },
    });
    const { response } = res.data;
    return response;
  },
};
