// 此檔案為 內用先結的獨立 API

import domain from './domain';
import http from '@/utils/http';
import { appToken } from '@/utils/auth';
import getI18nKey from './getLangValue';

export default {
  /**
   * 取得結單頁面的服務費
   * @param {*} storeId (商店id)
   * @param {*} orderId (訂單id)
   * @param {*} discountPrice (當前優惠券的優惠價格)
   */
  getPreCheckoutServiceCharge(data) {
    const { storeId, orderId, discount } = data;
    // order_menu_id === 0 -> avoid calling api (沒有任何點餐單id === 0)
    if (orderId === 0) return;

    return http.get(`${domain.new}/store/${storeId}/order_menu/${orderId}/service_charge`, {
      params: {
        lang: getI18nKey('api_lang'),
        discount
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
}
