import followRouter from "@/router/modules/follow";
import companyLevelRouter from "@/router/modules/companyLevel";
import storeLevelRouter from "@/router/modules/storeLevel";
import reserveCompanyRouter from "@/router/modules/reserve/companyLevel";
import reserveStoreRouter from "@/router/modules/reserve/storeLevel";
import jkoRoutes from "@/router/modules/jko/jkoRoutes";

export const useRoutes = (isReserve) => {
  return !isReserve ? [
    {
      path: "/404",
      component: () => import("@/views/errorPage/404.vue"),
      name: "404",
      meta: { level: "pass" }
    },
    {
      path: "/no-enable",
      name: "no-enable",
      component: () => import("@/views/errorPage/NoEnable.vue")
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/views/errorPage/LinkError.vue")
    },
    {
      path: "/timeout",
      name: "timeout",
      component: () => import("@/views/errorPage/Timeout.vue")
    },
    {
      path: "/notice",
      name: "notice",
      component: () => import("@/views/errorPage/Notice.vue")
    },
    {
      path: "/shutdown",
      name: "shutdown",
      component: () => import("@/views/errorPage/ShutDown.vue")
    },
    {
      path: "/preview/:companyId/:storeId",
      name: "preview",
      component: () => import("@/views/menu/MenuPreview.vue")
    },
    {
      path: "/preview/pos/:companyId/:storeId",
      name: "preview_pos",
      component: () => import("@/views/menu/POSMenuPreview.vue")
    },
    ...jkoRoutes,
    followRouter,
    companyLevelRouter,
    storeLevelRouter,
    {
      path: "/*",
      redirect: "/404"
    }
  ] : [
    {
      path: "/404",
      name: "404",
      component: () => import("@/views/Reserve/OtherPages/404.vue"),
    },
    reserveCompanyRouter,
    reserveStoreRouter,
    {
      path: "/*",
      redirect: "/404"
    }
  ]
};