export class Checkout {
  constructor() {
    /** @type {Number} 已選擇取餐方式ID */
    this.selectedTakeMethodId = 0;

    /** @type {Number} 運費 */
    this.shippingFee = null;
  }

  resetShippingFee() {
    this.shippingFee = null;
  }
}