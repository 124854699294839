import domain from './domain';
import http from '@/utils/http';
import { appToken } from '@/utils/auth';
import getI18nKey from './getLangValue';

export default {
  fetchPickUpDate(storeId) {
    const t = Date.now()
    return http.get(`${domain.new}/store/${storeId}/pickup_date?t=${t}`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
  fetchPickUpTime({
    storeId,
    pickUpDate,
    pickUpType,
  }) {
    const t = Date.now()
    return http.get(`${domain.new}/store/${storeId}/pickup_time/${pickUpType}?date=${pickUpDate}&t=${t}`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
}
