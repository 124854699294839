// 訂位流程專用 API
import domain from "./domain";
import axios from "@/utils/http";
import { getToken, appToken } from "@/utils/auth";
import getI18nKey from './getLangValue';

export default {
  // GET 品牌訂位資訊
  GetCompanyData({ companyId }) {
    return axios.get(`${domain.new}/reserve/company/${companyId}/info`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
  // GET 品牌訂位時段資料
  GetCompanyReserveTime(obj) {
    const { companyId, date } = obj;
    return axios.get(`${domain.new}/reserve/company/${companyId}/reserve_time`, {
      headers: {
        Authorization: appToken,
      },
      params: {
        lang: getI18nKey('api_lang'),
        date
      },
    });
  },
  // GET 店家訂位資訊
  GetStoreData(obj) {
    const { companyId, storeId } = obj;
    return axios.get(`${domain.new}/reserve/company/${companyId}/store/${storeId}/info`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
  // GET 店家訂位時段資料
  GetStoreReserveTime(obj) {
    const { companyId, storeId, date } = obj;
    return axios.get(`${domain.new}/reserve/company/${companyId}/store/${storeId}/reserve_time`, {
      headers: {
        Authorization: appToken,
      },
      params: {
        lang: getI18nKey('api_lang'),
        date
      },
    });
  },
  // POST 新增訂位資料
  PostNewReserveOrder(obj) {
    const { companyId, storeId, data } = obj;
    return axios.post(`${domain.new}/reserve/company/${companyId}/store/${storeId}/create`, data, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
  // GET 單一訂位資訊 (Web/liff)
  GetReserveOrderDetail(obj) {
    const { storeId, reserveOrderId } = obj;
    return axios.get(`${domain.new}/reserve/store/${storeId}/order/${reserveOrderId}`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
  // PUT 更新訂位資訊（取消/保留/到位）
  PutUpdateReserveOrderStatus(obj) {
    const { storeId, reserveOrderId, status } = obj;
    return axios.put(`${domain.new}/reserve/store/${storeId}/order/${reserveOrderId}/status`, { status }, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
};
