const jko = {
  state: {
    /**
     * 'Loading' -> Loading頁面
     * 'AuthorizeAgain' -> 再次授權頁面
     */
    jkoView: '',
    jkoOriginPath: "",
    jkoStoreId: 0,
  },
  mutations: {
    SET_JKO_VIEW: (state, payload) => {
      state.jkoView = payload;
    },
    CLEAR_JKO_VIEW: (state) => {
      state.jkoView = "";
    },
    SET_ORIGIN_URL_PATH: (state, payload) => {
      state.jkoOriginPath = payload;
    },
    SAVE_STORE_ID: (state, payload) => {
      state.jkoStoreId = payload;
    },
  },
  actions: {
    SET_JKO_VIEW({ commit }, payload) {
      commit("SET_JKO_VIEW", payload);
    },
    CLEAR_JKO_VIEW({ commit }) {
      commit("CLEAR_JKO_VIEW");
    },
    SET_ORIGIN_URL_PATH({ commit }, payload) {
      commit("SET_ORIGIN_URL_PATH", payload);
    },
    SAVE_STORE_ID({ commit }, payload) {
      commit("SAVE_STORE_ID", payload);
    },
  }
};

export default jko;
