import vuexStore from '@/store/index';
import { initLiff } from "@/utils/line";

let finished = false;

const setConfig = async (companyParam) => {
  // 處理 Config
  if (process.env.NODE_ENV === "production") {
    const config = window.__imenu_config__;
    const company = window.__imenu_company_slug;
    vuexStore.dispatch('setConfig', {
      ...config,
      company,
    });
  } else {
    if (!vuexStore.getters.config.id) {
      await vuexStore.dispatch('fetchConfig', {
        company: companyParam
      });
    }
  }
}

const checkIsComanySlugValid = (companyParam) => {
  const isConfigValid = window.__imenu_config__ && window.__imenu_config__.id;
  const isCompanySlugValid = window.__imenu_company_slug;
  let result = Boolean(isConfigValid && isCompanySlugValid);

  if (process.env.NODE_ENV !== 'production') {
    result = Boolean(companyParam);
  }
  return result;
}

const sopowerProcess = async (to, from, next) => {
  // 已完成就直接導向預設頁面
  if (finished) return next();
  // 從 Vue Router Guards 取出品牌名稱
  const { company: companyParam } = to.params;
  // 確認該品牌是否為大麥客戶
  const isComanySlugValid = checkIsComanySlugValid(companyParam);
  if (!isComanySlugValid) return next({ name: '404' });
  // 確認用戶是否為 Liff 環境
  const isLIFF = await initLiff();
  // 設定 Liff 狀態（保存）
  vuexStore.dispatch('setIsLIFF', isLIFF);
  // 拿取 商家 config 資料
  await setConfig(companyParam);

  finished = true;

  return next({
    name: 'sopower.company',
    // 設定路徑參數
    params: { ...to.params }
  });
}



export default sopowerProcess;