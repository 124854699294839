function generateShareRouter(level) {
  return [
    // === Login And Signup ===
    {
      path: 'login',
      name: `login.${level}`,
      meta: { level, shouldRedirectAfterLogin: false, },
      component: () => import('@/views/LoginAndSignup/Login.vue'),
    },
    {
      path: 'linelogin',
      name: `linelogin.${level}`,
      meta: { level, shouldRedirectAfterLogin: false, },
      component: () => import('@/views/LoginAndSignup/LineLogin.vue'),
    },
    {
      path: 'signup',
      name: `signup.${level}`,
      meta: { level, shouldRedirectAfterLogin: false, },
      component: () => import('@/views/LoginAndSignup/SignUp.vue'),
    },
    {
      path: 'signup/success',
      name: `signupSuccess.${level}`,
      meta: { level, shouldRedirectAfterLogin: false, },
      component: () => import('@/views/LoginAndSignup/SignUpSuccess.vue'),
    },
    {
      path: 'captcha',
      name: `captcha.${level}`,
      meta: { level, shouldRedirectAfterLogin: false, },
      component: () => import('@/views/LoginAndSignup/Captcha.vue'),
    },
    {
      path: 'thirdSignup',
      name: `thirdSignup.${level}`,
      meta: { level, shouldRedirectAfterLogin: false, },
      component: () => import('@/views/LoginAndSignup/ThirdSignUp.vue'),
    },
    {
      path: 'forgetpassword',
      name: `forgetpassword.${level}`,
      meta: { level, shouldRedirectAfterLogin: false, },
      component: () => import('@/views/LoginAndSignup/ForgetPassword.vue'),
    },
    {
      path: 'resetpassword',
      name: `resetpassword.${level}`,
      meta: { level, shouldRedirectAfterLogin: false, },
      component: () => import('@/views/LoginAndSignup/ResetPassword.vue'),
    },
    {
      path: 'resetpassword/success',
      name: `resetpasswordSuccess.${level}`,
      meta: { level, shouldRedirectAfterLogin: false, },
      component: () => import('@/views/LoginAndSignup/ResetPasswordSuccess.vue'),
    },
    // === Order List ===
    {
      path: 'order/list',
      name: `orderlist.${level}`,
      meta: { level, shouldRedirectAfterLogin: true, },
      component: () => import('@/views/orderList/OrderList.vue'),
    },
    {
      path: 'order/detail',
      name: `orderdetail.${level}`,
      meta: { level, shouldRedirectAfterLogin: true, },
      component: () => import('@/views/OrderDetail/OrderDetail.vue')
    },
    // === Order Paper ===
    {
      path: 'orderpaper',
      name: `orderpaper.${level}`,
      meta: { level, shouldRedirectAfterLogin: true, },
      component: () => import('@/views/orderPaper/OrderPaper.vue'),
    },
    // === Coupon List ===
    {
      path: 'couponlist',
      name: `couponlist.${level}`,
      meta: { level, shouldRedirectAfterLogin: true, name: "couponlist" },
      component: () => import('@/views/couponList/CouponList.vue'),
    },
    // === Coupon List enter from PromoteHint ===
    {
      path: 'couponlistMenu',
      meta: { level, shouldRedirectAfterLogin: true, name: "couponlistMenu" },
      component: () => import('@/views/couponList/CouponList.vue'),
    },
    // Point Card List ---> (跨店)品牌集點直接進入「我的點數卡」
    {
      path: 'points',
      name: `points.${level}`,
      meta: { level, shouldRedirectAfterLogin: true, },
      component: () => import('@/views/LoyaltyCard/LoyaltyCardList.vue'),
    },
    // 我的點數卡 ---> storeId === 0 (品牌)跨店集點 / storeId !== 0 店家集點
    {
      path: 'pointCard/:storeId',
      name: `pointCard.${level}`,
      meta: { level, shouldRedirectAfterLogin: true, },
      component: () => import('@/views/LoyaltyCard/LoyaltyCard.vue'),
    },
    // ----------------- 我的錢包 -----------------
    {
      path: 'wallet',
      component: () => import('@/views/Wallet/Wallet.vue'),
      children: [
        {
          path: '',
          name: `wallet.${level}`,
          redirect: 'mywallet',
        },
        // 我的錢包
        {
          path: 'mywallet',
          name: `mywallet.${level}`,
          meta: { level, shouldRedirectAfterLogin: true, },
          component: () => import('@/views/Wallet/pages/MyWallet.vue')
        },
        // 儲值
        {
          path: 'addmoney',
          component: () => import('@/views/Wallet/AddMoney.vue'),
          children: [
            {
              path: '',
              name: `addmoney.${level}`,
              redirect: 'plan',
            },
            // 儲值方案
            {
              path: 'plan',
              name: `addmoneyplan.${level}`,
              meta: { level, shouldRedirectAfterLogin: true, },
              component: () => import('@/views/Wallet/pages/AddMoneyPlan.vue')
            },
            // 儲值付款
            {
              path: 'payment/:planId',
              name: `addmoneypayment.${level}`,
              meta: { level, shouldRedirectAfterLogin: true, },
              component: () => import('@/views/Wallet/pages/AddMoneyPayment.vue')
            },
            // 儲值結果
            {
              path: 'result',
              name: `addmoneyresult.${level}`,
              meta: { level, shouldRedirectAfterLogin: true, },
              component: () => import('@/views/Wallet/pages/AddMoneyResult.vue')
            },
            // 儲值紀錄
            {
              path: 'log',
              name: `addmoneylog.${level}`,
              meta: { level, shouldRedirectAfterLogin: true, },
              component: () => import('@/views/Wallet/pages/AddMoneyLog.vue')
            },
            // 退款
            {
              path: 'refund/:orderId',
              name: `addmoneyrefund.${level}`,
              meta: { level, shouldRedirectAfterLogin: true, },
              component: () => import('@/views/Wallet/pages/AddMoneyRefund.vue')
            },
          ]
        },
        // 付款
        {
          path: 'payment',
          name: `payment.${level}`,
          meta: { level, shouldRedirectAfterLogin: true, },
          component: () => import('@/views/Wallet/pages/Payment.vue')
        },
        // 交易紀錄
        {
          path: 'transactionrecord',
          name: `transactionRecord.${level}`,
          meta: { level, shouldRedirectAfterLogin: true, },
          component: () => import('@/views/Wallet/pages/TransactionRecord.vue')
        },
        // 發票設定
        {
          path: 'invoicesetting',
          name: `invoiceSetting.${level}`,
          meta: { level, shouldRedirectAfterLogin: true, },
          component: () => import('@/views/Wallet/pages/InvoiceSetting.vue')
        },
        // Q&A
        {
          path: 'questions',
          name: `questionsandanswers.${level}`,
          meta: { level, shouldRedirectAfterLogin: true, },
          component: () => import('@/views/Wallet/pages/QuestionsAndAnswers.vue')
        },
      ],
    },
    // === Member ===
    {
      path: 'member',
      name: `member.${level}`,
      meta: { level, shouldRedirectAfterLogin: true, },
      component: () => import('@/views/Member/Member.vue'),
    },
    {
      path: 'member/detail',
      name: `memberDetail.${level}`,
      meta: { level, shouldRedirectAfterLogin: true, },
      component: () => import('@/views/Member/MemberDetail.vue'),
    },
    // === User Terms & Privacy Policy ===
    {
      path: 'userterms',
      name: `userterms.${level}`,
      meta: { level, shouldRedirectAfterLogin: false, },
      component: () => import('@/views/UserTermsAndPrivacyPolicy/UserTerms'),
    },
    {
      path: 'privacypolicy',
      name: `privacypolicy.${level}`,
      meta: { level, shouldRedirectAfterLogin: false, },
      component: () => import('@/views/UserTermsAndPrivacyPolicy/PrivacyPolicy.vue'),
    },
  ];
}

export default generateShareRouter;
