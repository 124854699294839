import API from '@/api/pickUpDateAndTime'

const FETCHED_PICK_UP_DATE = "FETCHED_PICK_UP_DATE";
const FETCHED_PICK_UP_TIME = "FETCHED_PICK_UP_TIME";

const initialState = {
  delivery: [],
  take_out: [],
  for_here: [],
};

const PickUpDate = d => {
  return {
    date: d.date,
    date_index: d.date_index,
  };
};

const PickUpTime = t => {
  return {
    index: t.index,
    status: t.status,
    text: t.text,
  };
};

const pickUpDateAndTime = {
  state: {
    ...initialState,
  },
  mutations: {
    [FETCHED_PICK_UP_DATE](state, payload) {
      state.delivery = payload.delivery.map(PickUpDate);
      state.take_out = payload.take_out.map(PickUpDate);
      state.for_here = payload.for_here.map(PickUpDate);
    },
    [FETCHED_PICK_UP_TIME](state, payload) {
      const { pickUpType, pickUpTimeData } = payload;
      state[pickUpType] = state[pickUpType].map(pickUpDate => {
        if (pickUpDate.date_index === pickUpTimeData.date_index) {
          return {
            date: pickUpDate.date,
            date_index: pickUpDate.date_index,
            time_list: pickUpTimeData.list.map(PickUpTime)
          };
        } else {
          return {
            date: pickUpDate.date,
            date_index: pickUpDate.date_index,
          };
        }
      })
    },
  },
  actions: {
    async fetchPickUpDate({ commit }, storeId) {
      const response = await API.fetchPickUpDate(storeId);
      commit(FETCHED_PICK_UP_DATE, response.data.response);
      return response.data.response;
    },
    async fetchPickUpTime({ commit }, {
      storeId,
      pickUpDate,
      pickUpType,
    }) {
      const response = await API.fetchPickUpTime({
        storeId,
        pickUpDate,
        pickUpType,
      });
      commit(FETCHED_PICK_UP_TIME, {
        pickUpType,
        pickUpTimeData: response.data.response
      });
      return response.data.response;
    },
  },
};

export default pickUpDateAndTime;
