import creditCard from '@/utils/creditCard';

export default {
  state: {
    isModalVisible: false,
    selectedCard: {
      id: 0,
      first6Num: '000000',
      last4Num: '0000',
      numberString: '0000-00**-****-0000',
      company: 'visa',
      cardIconName: 'ic_creditcard_default',
    },
    savedCards: [],
  },
  mutations: {
    OPEN_CREDIT_CARD_MODAL: state => {
      state.isModalVisible = true;
    },
    CLOSE_CREDIT_CARD_MODAL: state => {
      state.isModalVisible = false;
    },
    SET_SAVED_CARDS: (state, payload) => {
      state.savedCards = payload.map(data => {
        const company = creditCard.getCreditCardCompany(data.card_right_6);

        return {
          id: data.id,
          first6Num: data.card_right_6,
          last4Num: data.card_left_4,
          numberString: creditCard.getCreditCardString(data.card_right_6, data.card_left_4),
          company,
          cardIconName: creditCard.getCardIconName(company),
        };
      });
      state.selectedCard = {
        ...state.savedCards[0],
      };
    },
    SET_SELECTED_CARD: (state, payload) => {
      state.selectedCard = { ...payload };
    },
    SET_SELECTED_CARD_BY_ID: (state, id) => {
      state.selectedCard = state.savedCards.find(data => data.id === id) || {
        id: 0,
        first6Num: '000000',
        last4Num: '0000',
        numberString: '其他信用卡',
        company: 'visa',
        cardIconName: 'ic_creditcard_default',
      };
    },
    DELETE_CARD: (state, id) => {
      state.savedCards = state.savedCards.filter(data => data.id !== id);
      state.selectedCard = state.savedCards.lenth > 0 ? state.savedCards[0] : {
        id: 0,
        first6Num: '000000',
        last4Num: '0000',
        numberString: '其他信用卡',
        company: 'visa',
        cardIconName: 'ic_creditcard_default',
      };
    },
  },
  actions: {
    openCreditCardModal({ commit }) {
      commit('OPEN_CREDIT_CARD_MODAL');
    },
    closeCreditCardModal({ commit }) {
      commit('CLOSE_CREDIT_CARD_MODAL');
    },
    setSavedCards({ commit }, payload) {
      if (!payload.length) return;
      commit('SET_SAVED_CARDS', payload);
    },
    setSelectedCard({ commit }, payload) {
      commit('SET_SELECTED_CARD', payload);
    },
    setSelectedCardById({ commit }, id) {
      commit('SET_SELECTED_CARD_BY_ID', id);
    },
    deleteCard({ commit }, id) {
      commit('DELETE_CARD', id);
    },
  },
};
