
import {
  FETCHED_BRANCH_LIST,
  FETCHED_BRANCH_INFO,
} from './mutationTypes'
import API from '@/api/branch';
import { cloneDeep } from 'lodash-es';

// 順序： 自取 外送 內用
const currentOrder = ["take_out", "delivery", "for_here"];

function generateOrderPickup(originPickupObject) {
  let result = {};
  // pick up 裡面多增加 key，等同 take_out、delivery
  for (const key in originPickupObject) {
    originPickupObject[key]['key'] = key;
  }
  currentOrder.forEach(pickup => {
    let hasThisPickup = originPickupObject[pickup] !== undefined;
    hasThisPickup && (result[pickup] = originPickupObject[pickup])
  })
  return result;
}

function generateBranchData(data) {
  let orderedPick = generateOrderPickup(data.pickup);
  return {
    id: data.id,
    // 營登顯示控制
    is_registration_display: data.is_registration_display,
    name: data.name,
    // 營業名稱
    business_registration: data.business_registration,
    // 營業人統編
    uniform_numbers: data.uniform_numbers,
    slug: data.slug,
    // 店家圖片
    imgUrl: data.img_url,
    // 店家 logo
    logoUrl: data.logo_url,
    // 店家地址
    address: data.address,
    display_address: data.display_address,
    // 店家距離（有定位時帶入經緯度會給離現在距離）
    distance: data.distance,
    // 外送準備時間
    deliveryTime: orderedPick.delivery ? orderedPick.delivery.prepare_time : -1,
    // 自取準備時間
    takeoutTime: orderedPick.take_out ? orderedPick.take_out.prepare_time : -1,
    // 內用準備時間
    forHereTime: orderedPick.for_here ? orderedPick.for_here.prepare_time : -1,
    pickup: orderedPick,
    // 店家資訊列表顯示 service 時間
    serviceHoursText: data.service_hours_text,
    // 店家 phone
    phone: data.phone,
    // 店家資訊列表顯示 order 時間
    orderHoursText: data.order_hours_text,
    // 是否開啟會員功能（掃碼用，imenu 固定會 true，掃碼後結帳 固定會 false）
    isMemberEnable: data.is_member_enable,
    // 是否開啟線上訂餐
    isAvailableForOrder: data.is_available_for_order,
    // 是否開啟掃碼點餐
    isQrOrderEnable: data.is_qrorder_enable,
    // 是否開啟服務費
    isServiceChargeEnable: data.is_service_charge_enable,
    // 是否開啟儲值金
    isStoreWalletEnable: data.is_wallet_enable,
    // 是否開啟自動接單
    isAutoConfirm: data.is_auto_confirm,
    // 是否開啟提示會員登入 (限用：掃碼點餐先結)
    isQrOrderNotifyMemberLogin: data.is_qrorder_notify_member_login,
    // 是否開啟備註
    isOrderCommentEnable: data.is_order_comment_enable,
    // 是否開啟imenu
    isImenuAvailable: data.is_imenu_available,
    // 預購天數
    preOrderDays: data.pre_order_days,
    // 外送準備時間
    deliveryOrderReady: data.delivery_order_ready,
    // 桌號設定
    tables: data.tables && data.tables.map(t => {
      return {
        id: t.id,
        name: t.name,
        table_minimum_charge: t.table_minimum_charge,
      }
    }) || [],
    // 是否為 掃碼內用後結
    isPostCheckout: data.is_post_checkout,
    // 行政區：ex 台北市文山區
    districtName: data.district_name || "",
    // 店家狀態：1:正常,2:暫停接單,3:非接單時間
    onlineOrderStatus: data.online_order_status,
    // 公告 announcement.content_info
    announcement: data.announcement,
    // 振興券開關
    stimulus_voucher_notice: data.stimulus_voucher_notice,
  };
}

function generateBranchList(data) {
  return {
    isAsiaMilesEnable:data.is_asia_miles_enable,
    branches: data.list.map(generateBranchData),
    pickup_info: generateOrderPickup(data.pickup_info || {})
  };
}

const branch = {
  state: {
    list: [],
    ing: {
      tables: [],
      pickup: {},
      isMemberEnable: true,
      isImenuAvailable: true,
      isAsiaMilesEnable: false,
      announcement: {
        content_info: "",
      },
    },

  },
  mutations: {
    [FETCHED_BRANCH_LIST]: (state, payload) => {
      state.list = [{ ...payload.branch }];
    },
    [FETCHED_BRANCH_INFO]: (state, payload) => {
      state.ing = { ...payload };
    },
  },
  actions: {
    async fetchBranchList({ commit }, data) {
      const response = await API.fetchBranchList(data);
      const list = response.data.response;
      const result = generateBranchList(list);

      commit(FETCHED_BRANCH_LIST, result.branches)

      return result;
    },
    async fetchBranchInfo({ commit }, data) {
      const response = await API.fetchBranchInfo(data);
      const list = response.data.response;
      const theData = generateBranchList(list);
      const result = {...theData.branches[0], isAsiaMilesEnable: theData.isAsiaMilesEnable};
      if (data.isWemo) {
        const clone = cloneDeep(result.pickup.take_out)
        // eslint-disable-next-line eqeqeq
        clone.payments = clone.payments.filter(p => p.method_code != "901");
        result.pickup = {
          take_out: clone,
        }
      }

      commit(FETCHED_BRANCH_INFO, result);
      return result;
    },
    setPostCheckout({ commit }, payload) {
      commit('SET_POST_CHECKOUT', payload);
    }
  },
};

export default branch;
