// JKO APP 獨立頁面
const jkoRoutes = [
  // 交易明細
  {
    path: "/order/:sn",
    name: "jko_order_detail",
    component: () => import('@/views/OrderDetail/OrderDetail.vue')
  },
  // Loading / 再次授權
  {
    path: "/jko/beforeAuthorize",
    name: "jko_before_authorize",
    component: () => import('@/views/Jko/JkoBeforeAuthorize.vue')
  },
  // 維護頁面
  {
    path: "/jko/maintenance",
    name: "jko_maintenance",
    component: () => import('@/views/Jko/JkoMaintenance.vue')
  },
];

export default jkoRoutes;
