import {
  SET_TABLE,
  RESET_TABLE
} from './mutationTypes'

const initialState = {
  selectedTable: {
    id: 0,
    name: ''
  },
};

const tableSettings = {
  state: {
    ...initialState,
  },
  mutations: {
    [SET_TABLE](state, payload) {
      state.selectedTable.id = payload.id;
      state.selectedTable.name = payload.name;
    },
    [RESET_TABLE](state) {
      state.selectedTable = { ...initialState.selectedTable };
    }
  },
  actions: {
    setTable({ commit }, payload) {
      commit(SET_TABLE, payload);
    },
    resetTable({ commit }) {
      commit(RESET_TABLE);
    }
  },
};

export default tableSettings;
