import domain from './domain';
import http from '@/utils/http';
import { appToken } from '@/utils/auth';
import getI18nKey from './getLangValue';

export default {
  // 檢查桌號能不能用
  checkPostCheckoutTable({ storeId, storeTableId, orderMenuId }) {
    return http.get(`${domain.new}/store/${storeId}/table/${storeTableId}/check`, {
      // orderMenuId 在當前沒有沒有點餐單時，不帶入(orderMenuId = undefined)
      // then it will be ignored by axios
      params: {
        lang: getI18nKey('api_lang'),
        order_menu_id: orderMenuId
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
  // postCheckoutDetail頁 顯示內容
  getPostCheckoutDetail(data) {
    let { storeId, orderId } = data;
    return http.get(`${domain.new}/store/${storeId}/order/${orderId}/postorder`, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
    });
  },
  // 送出 postCheckout 的 order_menu
  sendPostCheckout(data) {
    let { storeId, orderMenuId, storeTableId, amount, from, menu_version } = data;
    return http.post(`${domain.new}/store/${storeId}/order_menu/${orderMenuId}/postorder`, { store_table_id: storeTableId, amount, from, menu_version }, {
      params: {
        lang: getI18nKey('api_lang')
      },
      headers: {
        Authorization: appToken,
      },
      transformResponse: http.defaults.transformResponse.concat((data) => {
        data = { ...data, byPass400Default: true };
        return data;
      })
    })
  },
}
