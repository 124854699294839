function imgLazyLoadingCallback(entries, observer) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      let imgEl = entry.target;
      imgEl.decoding = 'async';
      imgEl.src = imgEl.dataset.src;
      // prevent calling api twice
      observer.unobserve(imgEl)
    }
  });
}

export const imgLazyLoadingDirective = (threshold, rootMargin) => {
  return {
    inserted(el) {
      const options = { threshold, rootMargin };
      const intersectionObserver = new IntersectionObserver(imgLazyLoadingCallback, options);
      intersectionObserver.observe(el);
    },
    componentUpdated(el) {
      const options = { threshold, rootMargin };
      const intersectionObserver = new IntersectionObserver(imgLazyLoadingCallback, options);
      intersectionObserver.observe(el);
    },
  }
}