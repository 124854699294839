const domain = {};

if (process.env.NODE_ENV === 'development') {
  domain.new = '/proxy/new';
  domain.s3 = '/proxy/s3';
  domain.admin = '/proxy/admin';
} else {
  domain.new = process.env.VUE_APP_DOMAIN_NEW;
  domain.s3 = process.env.VUE_APP_DOMAIN_S3;
  domain.admin = process.env.VUE_APP_ADMIN_URL;
}

export default domain;
