import axios from 'axios';
import { Loading, MessageBox } from 'element-ui';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { removeToken } from '@/utils/auth';
import router from '@/router';
import store from '@/store';
import Cookies from 'js-cookie';
import { setupCache } from 'axios-cache-adapter'
import { checkIsJko, jkoErrorHandle } from '@/utils/jko'
import i18n from "@/plugin/i18n"

/**
 * alert
 */
const tip = (msg) => {
  const box = document.querySelector("#announceUpdatedAt");
  if (box) {
    box.remove();
  }

  if (store.getters.showApiError) {
    MessageBox({
      title: i18n.t('Lang.System_error.utils.http.reminder'),
      message: msg,
      confirmButtonText: i18n.t('Lang.Category.button.submit'),
    }).then(() => {}).catch(() => {});
  }
};

function level(routeName) {
  if (store.getters.isCompanyLevel) {
    return `${routeName}.company`;
  }
  return `${routeName}.store`;
}


let timer;
/**
 * 跳轉登入
 */
const reLogin = () => {
  removeToken();
  router.push({ name: level('login') });
  if (store.getters.isLIFF) {
    window.liff.closeWindow();
  }
};

const openMail = (response) => {
  const subjectString = encodeURIComponent(i18n.t('Lang.System_error.utils.http.abnormal_account'));
  const responseString = JSON.stringify(response.data);
  const contenString = encodeURIComponent(`${responseString}\n`+i18n.t('Lang.System_error.utils.http.please_keep_the_information_above'));
  window.open(`mailto:cs@damaiapp.com.tw?subject=${subjectString}&body=${contenString}`);
}

/**
 * 錯誤統一處理
 * @param {Number} status
 */
const errorHandle = (status, response, message) => {
  switch (status) {
    // 以下 3 種登入&註冊相關錯誤代碼 (102~104)
    // 皆移在元件及 action 做錯誤處理，這邊不做處理
    // 帳號未通過手機驗證
    case 102:
    // 查無帳號
    case 103:
    // 帳號已存在
    case 104:
      break;
    case 400:
      const configURL = response.config.url;
      if(response.data.code === 70023 && configURL.includes("checkout")) {
        // 70023 品項異動
        console.log('中斷攔截70023')
        return;
      }
      if((response.data.code === 20005) && configURL.includes("checkout")) {
        // 20005 庫存不足
        console.log('中斷攔截20005')
        return;
      }

      if((response.data.code === 10001) && configURL.includes("update")) {
        console.log('中斷攔截 候位 update 10001')
      }

      if (configURL.includes("wait_queue/store/")) {
        console.log('中斷候位報錯')
        return;
      }

      if (response.data.byPass400Default) {
        // do nothing
        // show confirm inside component
      } else {
        Cookies.remove(`order_${store.getters.storeId}`);

        const box = document.querySelector("#announceUpdatedAt");
        if (box) {
          box.remove();
        }

        MessageBox({
          title: i18n.t('Lang.System_error.utils.http.something_went_wrong'),
          message,
          showCancelButton: false,
          confirmButtonText: i18n.t('Lang.Category.button.submit'),
        }).then(() => {
          window.location.reload();
        }).catch(() => {
          window.location.reload();
        });
      }
      break;
    case 403:
      // 異常先移除 cookie 內的 login token
      removeToken();
      const box = document.querySelector("#announceUpdatedAt");
      if (box) {
        box.remove();
      }
      MessageBox({
        title: i18n.t('Lang.System_error.utils.http.login_error_title'),
        message: i18n.t('Lang.System_error.utils.http.login_error_message'),
        showCancelButton: true,
        cancelButtonText: i18n.t('Lang.Category.button.cancel'),
        confirmButtonText: i18n.t('Lang.System_error.utils.http.contact_customer_service'),
      }).then(() => {
        openMail(response);
        if (store.getters.isLIFF) {
          window.liff.closeWindow();
        } else {
          window.location.reload();
        }
      }).catch(() => {
        if (store.getters.isLIFF) {
          window.liff.closeWindow();
        } else {
          window.location.reload();
        }
      });
      break;
    // 搶登
    case 901:
      tip(i18n.t('Lang.System_error.utils.http.please_log_in_again'));
      setTimeout(() => reLogin(), 1000);
      break;
    default:
      const apiURL = response.config.url;
      
      const excludeApiURLAndCode = {
        'shipping_fee': [40108]
      };
      if ( apiURL.includes('shipping_fee') && excludeApiURLAndCode.shipping_fee.includes(status)) return console.log(`中斷攔截，[API]${apiURL} [code]${status}`);
      if ( apiURL.includes('wait_queue/store')) return console.log(`中斷候位報錯`);
      if (message) {
        tip(`(${status})${message}`);
      } else {
        tip(`(${status})`+i18n.t('Lang.System_error.utils.http.server_wrong_message'));
      }
  }
};
let loading;
const cache = setupCache({
  maxAge: 0, // default disable cache
  debug: false // open it if you want to see cache info
})
const instance = axios.create({
  adapter: cache.adapter
});

instance.defaults.headers.post['Content-Type'] = 'application/json;charset=utf8';
instance.defaults.withCredentials = false;

// 資安調整
instance.defaults.headers['X-Content-Type-Options'] = 'nosniff'
instance.defaults.headers['X-XSS-Protection'] = '1; mode=block';
instance.defaults.headers['Strict-Transport-Security'] = 'max-age=31536000;includeSubDomain'
instance.defaults.headers['Cache-Control'] = 'no-store'

if (window.__request_id__) {
  instance.defaults.headers['Request-Id'] = window.__request_id__
}

instance.interceptors.request.use(
  (config) => {
    clearTimeout(timer);
    if (store.getters.showLoading) {
      store.dispatch('setLoadingCounter', store.getters.loadingCounter + 1);
      loading = Loading.service({
        lock: true,
      });
      store.dispatch('setApiLoadingStatus', true);
      disableBodyScroll(loading.$el);
    }
    return config;
  },
  error => Promise.error(error),
);

instance.interceptors.response.use(
  (response) => {
    // enableBodyScroll(loading.$el);
    clearAllBodyScrollLocks();
    store.dispatch('setLoadingCounter', store.getters.loadingCounter - 1);
    if (store.getters.loadingCounter <= 0) {
      timer = setTimeout(() => {
        loading.close();
        // api loading close
        store.dispatch('setApiLoadingStatus', false);
      }, 50);
    }
    if (
      response.status === 200
      && response.data
      && (response.data.code === undefined || response.data.code === 200)
    ) {
      return Promise.resolve(response);
    }
    // 如果data.code不是200或者是未定義，那麼這個請求會被視為失敗，所以try..catch會進入catch
    if (
      response.data
    ) {
      checkIsJko()
        ? jkoErrorHandle(response.data.code, response, response.data.message)
        : errorHandle(response.data.code, response, response.data.message);
    } else {
      const msg = "[frontend]response message not found";
      checkIsJko()
        ? jkoErrorHandle(response.status, response, msg)
        : errorHandle(response.status, response, msg);
    }
    return Promise.reject(response);
  },
  (error) => {
    // 不是 2xx status
    // enableBodyScroll(loading.$el);
    clearAllBodyScrollLocks();
    store.dispatch('setLoadingCounter', store.getters.loadingCounter - 1);
    if (store.getters.loadingCounter <= 0) {
      timer = setTimeout(() => {
        loading.close();
      }, 10);
    }
    if (error && error.response) {
      let errMsg = "";
      const { response } = error;

      if (response.data && response.data.message) {
        errMsg = response.data.message;
      }
      checkIsJko()
        ? jkoErrorHandle(response.status, response, errMsg)
        : errorHandle(response.status, response, errMsg);

      return Promise.reject(response);
    }
    const errorMsg = "no response error"
    checkIsJko()
      ? jkoErrorHandle(errorMsg, error, "")
      : errorHandle(errorMsg, error, "");
    return Promise.reject(error);
  },
);

export default instance;
