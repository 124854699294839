// 這邊要跟 @/lang 所有的 JSON 檔名對應
export default [
  {
    value: "zh-tw",
    label: "繁體中文",
    api_lang: "zh_TW",
    google_map_lang: "zh-TW",
    line_share_btn_lang: "zh_Hant"
  },
  {
    value: "en",
    label: "English",
    api_lang: "en",
    google_map_lang: "en",
    line_share_btn_lang: "en_Hant"
  },
]
